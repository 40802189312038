import React from "react";
import style from "./MachineCard.module.scss";
import PrimaryButton from "Components/base/Button/PrimaryButton";
import AvironLogoPath from "./avironLogo.png";
import { IMachine } from "Screens/Memberships/MachineManage/formatData";

interface IMachineCard {
    data: IMachine;
    key: number;
    onSwitchMachine?: any;
    handleDeleteMachine?: any;
    isHideDelete?: boolean;
    isHideSwitch?: boolean;
}
const MachineCard = ({
    key,
    data,
    onSwitchMachine,
    handleDeleteMachine,
    isHideDelete,
    isHideSwitch,
}: IMachineCard) => {
    const { machineId, groupName, machineName } = data;
    return (
        <div className={style["MachineCard"]} key={key}>
            <div className={style["machineInfoBlock"]}>
                <div className={style["logo"]}>
                    <img src={AvironLogoPath} />
                </div>
                <div className={style["machineInfo"]}>
                    <div className={style["info1"]}>{groupName}</div>
                    <div className={style["info2"]}>{machineName}</div>
                    <div className={style["info3"]}>{`ID: ${machineId}`}</div>
                </div>
            </div>
            <div className={style["buttonGroup"]}>
                {!isHideDelete && (
                    <PrimaryButton
                        text="Delete"
                        className={style["deleteButton"]}
                        onClick={handleDeleteMachine}
                    />
                )}
                {!isHideSwitch && (
                    <PrimaryButton
                        text="Switch"
                        className={style["switcheButton"]}
                        onClick={onSwitchMachine}
                    />
                )}
            </div>
        </div>
    );
};

export default MachineCard;
