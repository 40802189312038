import React from 'react'
import * as yup from "yup";

interface ISwitchMachineForm {
    oldMachineId: string
}

export const formSchema = ({oldMachineId}: ISwitchMachineForm) => {
    return yup.object().shape({
        appId: yup.number().required('Required field'),
        machineId: yup.string().required('Machine ID is a required field')
        .test("validator-machineId", function (machineId: string) {
            const isDuplicate = machineId === oldMachineId;
            if (isDuplicate) {
                // @ts-ignore
                return this.createError({
                    // @ts-ignore
                    path: this.path,
                    message: 'This machine ID is already connected.',
                });
            } else {
                return true;
            }
        })
    }); 
}