import {
    Backdrop,
    Fade,
    Modal,
    Typography,
    Box,
    Paper,
    Button,
    Grid,
    Stack,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import CurrencyNumber from "Components/CurrencyNumber";
import React from "react";
import "./style.scss";

const useStyles: any = makeStyles({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
});

const planIntervalPriority: any = {
    monthly: 1,
    yearly: 2,
    lifetime: 3,
};

const mappingDescription: any = {
    1: "$29/month, billed monthly",
    2: "$24/month, billed annually",
    49: "$29/month + $9/month, billed monthly",
    50: "$24/month + $9/month, billed annually ",
    76: "$36/month + $12/month, billed monthly",
    77: "$32/month + $12/month, billed annually"
}

const FitPlanChooseMembershipModal: React.FunctionComponent<{
    open: boolean;
    onClose: any;
    state: any;
    onSubmit: (planId: number) => void;
    plans: any[];
    membership?: any;
}> = ({ open, onClose, onSubmit, state, plans, membership = {} }) => {
    const classes = useStyles();
    const {
        open_change_plan,
        change_plan_invoice_url,
        processing_change_plan,
    } = state;
    const [avaiblePlans, setAvaiablePlans] = React.useState<any[]>([]);
    const [selectedPlanId, setSelectedPlanId] = React.useState<number>(
        membership?.planId
    );
    const [itemPlan, setItemPlan] = React.useState<any>({});

    React.useMemo(() => {
        if (plans.length) {
            setSelectedPlanId(membership?.planId);
            setItemPlan(membership?.plan);
            const avPlans = plans.filter((plan) => {
                return (
                    plan.id !== membership?.plan.id &&
                    plan.customerType === membership?.plan?.customerType &&
                    planIntervalPriority[plan.interval] >=
                    planIntervalPriority[membership?.plan?.interval] &&
                    plan.type === "stripe" &&
                    plan.enable &&
                    plan.currency === membership?.plan?.currency
                    && (plan.productId === "membership")
                    // && Number(plan.amount) > Number(membership.plan.amount)
                    && plan.intervalCount < 2
                    && plan.appIds.length && plan.appIds.includes(1)
                );
            });
            setAvaiablePlans(avPlans);
        }
    }, [membership, plans]);

    const handleSubmit = () => {
        onSubmit(selectedPlanId);
    };

    const description = React.useMemo(() => {
        if(membership?.plan?.currency === 'usd'){
            return "Enjoy peace of mind with a lifetime warranty on limited parts (screen, belt, frame), advanced replacement, coverage for lost parts or stolen machine, and free shipping, all for $9/month, billed monthly or annually as per your membership."
        } else {
            return "Enjoy peace of mind with a lifetime warranty on limited parts (screen, belt, frame), advanced replacement, coverage for lost parts or stolen machine, and free shipping, all for $12/month, billed monthly or annually as per your membership."
        }
    },[membership?.plan?.currency])

    return (
        <Modal
            open={open}
            onClose={onClose}
            className={classes.modal}
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <div className="enroll-lifetime-modal-component">
                    <Box overflow={"hidden"}>
                        <Box>
                            <Typography component="h3" variant="h3" mb="24px">
                                CHOOSE MEMBERSHIP FOR FITPLAN
                            </Typography>
                            <Typography variant="body1" fontSize={"16px"} mb="56px">
                                {" "}
                                {description}
                            </Typography>
                        </Box> 
                        <Box>
                        <Paper sx={{ padding: "32px 18px", margin: "32px 0", backgroundColor: "#F4F4F4", boxShadow: "none" }} className="select-box select-box--current" >
                            <Box className="select-box--current__main">
                                <Grid
                                    textAlign="start"
                                    sx={{ fontFamily: "Work Sans" }}
                                    mb="8px"
                                >
                                    <Box style={{ background: "#FF4106", width: "fit-content", padding: "4px 8px", borderRadius: "100px", color: "#fff", fontWeight: 600, fontSize: "12px", fontFamily: "Work Sans" }}>
                                        CURRENT PLAN
                                    </Box>
                                </Grid>
                                <Grid
                                    container
                                    display="flex"
                                    justifyContent="space-between"
                                >
                                    <Grid item>
                                        <Typography
                                            component="h3"
                                            variant="h4"
                                            fontSize={"20px"}
                                            fontFamily={"Work Sans"}
                                            textTransform={"uppercase"}
                                        >
                                            {itemPlan?.name}
                                        </Typography>
                                        <Typography
                                            component="p"
                                            variant="body1"
                                            fontSize={"16px"}
                                        >
                                            {mappingDescription[itemPlan?.id]}
                                        </Typography>
                                    </Grid>
                                    <Grid item display={"flex"} alignContent={"center"}>
                                        <Typography
                                            component="h3"
                                            variant="h5"
                                            fontSize={"24px"}
                                            fontFamily={"MonoSpec"}
                                            fontWeight={500}
                                        >
                                            <CurrencyNumber value={itemPlan?.amount} />
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Paper>
                        {avaiblePlans.map((plan) => {
                            return (
                                <Paper
                                    key={plan.id}
                                    onClick={setSelectedPlanId.bind(null, plan.id)}
                                    className={`select-box ${plan.id === selectedPlanId
                                        ? "select-box__active"
                                        : null
                                        }`}
                                >
                                    <Grid
                                        container
                                        display="flex"
                                        justifyContent="space-between"
                                    >
                                        <Grid item>
                                            <Grid
                                                container
                                                display="flex"
                                                gap={"24px"}

                                            >
                                                <Grid item display={"flex"} alignItems={"center"} className="select-circle">
                                                    {plan.id === selectedPlanId ? (
                                                        <div className={'selectedCircle'}>
                                                            <div className={'circleItem'}></div>
                                                        </div>
                                                    ) : (
                                                        <div className={'unSelectCircle'}></div>
                                                    )}
                                                </Grid>
                                                <Grid item>
                                                    <h3>
                                                        {plan.name}
                                                    </h3>
                                                    <p>
                                                        {mappingDescription[plan.id]}
                                                    </p>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item display={"flex"} alignItems={"center"}>
                                            <Typography
                                                component="h3"
                                                variant="h5"
                                                fontSize={"24px"}
                                                fontFamily={"MonoSpec"}
                                                fontWeight={500}
                                            >
                                                <CurrencyNumber value={plan?.amount} />
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            );
                        })}
                        </Box>
                        {change_plan_invoice_url ? (
                            <p>
                                <br />
                                <br />
                                Can't charge your card. We will open a new tab
                                automatically to retry payment for membership.
                                Please reload page after payment successful. Please{" "}
                                <a href={change_plan_invoice_url} target="_blank">
                                    CLICK HERE
                                </a>{" "}
                                if nothing happens.
                            </p>
                        ) : null}
                        <Stack
                            spacing={2}
                            direction={{ xs: "column-reverse", md: "row" }}
                            alignItems="end"
                            justifyContent="flex-end"
                            width={{
                                xs: "100%",
                                md: "60%",
                            }}
                            sx={{
                                float: "right",
                            }}
                        >
                            <Button
                                variant="outlined"
                                onClick={onClose}
                                size="medium"
                            >
                                EXIT
                            </Button>
                            <Button
                                disabled={selectedPlanId === itemPlan?.id}
                                variant="contained"
                                color="secondary"
                                size="medium"
                                onClick={handleSubmit}
                                sx={{
                                    width: {
                                        md: "240px",
                                        xs: "100%"
                                    }
                                }}
                            >
                                {processing_change_plan ? (
                                    <i className="fa fa-circle-o-notch fa-spin"></i>
                                ) : (
                                    "ENROLL NOW"
                                )}
                            </Button>
                        </Stack>
                    </Box>
                </div>
            </Fade>
        </Modal>
    );
};

export default FitPlanChooseMembershipModal;
