import React, { useContext, useEffect } from "react";
import { UserAppContext, Action } from "contexts";
import { Route, RouteProps, useLocation } from "react-router-dom";
import {
    useGetCrmSessionQuery,
    useGetGameSessionQuery,
    useGetIdTokenQuery,
    useSharedQuery,
} from "services/webServer";
import { Session } from "@heroiclabs/nakama-js";
import { useConnectMutation } from "services/crmServer";
import { gameServerApi } from "services/gameServerV2";
import {
    getTokenFromLocalStorage,
    saveTokenToLocalStorage,
} from "services/gameServerV2/base";
import useTimezoneHook from "Hooks/useTimezoneHook";
import { handleGetTimezone } from "contexts/timezone/handleAction";
import moment from "moment";
import get from "lodash/get";
import { AvironLoading } from "Components/Icons/AvironLoading";
import { CONSTANTS } from "consts";
import { useCheckAppHook } from "Hooks/useCheckApp.hook";
import { selectWorkoutUnit } from "contexts/profileWorkout";

interface ProtectedRouteProps extends RouteProps {
    component: React.ComponentType<any>;
}

const ProtectedRoute = ({
    component: Component,
    ...rest
}: ProtectedRouteProps) => {
    const {
        authDispatch,
        timezone: timeZone,
        timezoneDispatch,
        // apolloClient,
    } = React.useContext(UserAppContext);
    const { data, loading, refetch: refetchUserShared } = useSharedQuery();
    // {
    //     fetchPolicy: "no-cache",
    // }
    const [connectCrmSerser] = useConnectMutation();
    const getCrmSessionRes = useGetCrmSessionQuery({
        fetchPolicy: "no-cache",
    });
    const getGameSessionRes = useGetGameSessionQuery({
        fetchPolicy: "no-cache",
    });

    const [isFinish, setIsFinish] = React.useState(false);

    const { data: dataTokenId } = useGetIdTokenQuery();

    const handleGetDeviceToken = (id: string) => {
        gameServerApi.app.getDeviceToken(id, "").then((res) => {
            saveTokenToLocalStorage(res as string);
            setTimeout(() => {
                setIsFinish(true);
            }, 2000);
        });
    };

    const idToken = dataTokenId?.getIdToken?.idToken || "";

    const { timeZoneHook, refetchTimezone } = useTimezoneHook();
    React.useEffect(() => {
        if (data && data.currentUser && getCrmSessionRes && getGameSessionRes) {
            if (timeZoneHook) {
                handleGetTimezone(timeZoneHook, timezoneDispatch);
                localStorage.setItem("timezone", timeZoneHook);
            }
        }
    }, [timeZoneHook, data, getCrmSessionRes, getGameSessionRes]);

    //Refetch timezone when change url
    const location = useLocation();
    React.useEffect(() => {
        if (location?.pathname === CONSTANTS.NAVIGATION_URL.WORKOUT_HISTORY) {
            refetchTimezone();
        }
    }, [location]);

    React.useEffect(() => {
        if (data && data.currentUser) {
            processAuth(data);
        }
    }, [data]);

    React.useEffect(() => {
        if (idToken) {
            const userIdFromIdToken = Session.restore(idToken)?.user_id;
            const userIdFromUserShareData = data?.currentUser?.id;
            if (userIdFromIdToken !== userIdFromUserShareData) {
                window.location.reload();
            }
        }
    }, [data?.currentUser?.id]);

    React.useEffect(() => {
        if (idToken) {
            const decodeIdToken = Session.restore(idToken);
            const userIdFromIdToken = decodeIdToken?.user_id;
            const token = getTokenFromLocalStorage();

            if (token && token !== "undefined") {
                const userIdFromLocalStorage = (
                    Session.restore(token)?.vars as any
                ).custom_id;
                if (userIdFromIdToken !== userIdFromLocalStorage) {
                    handleGetDeviceToken(idToken);
                } else {
                    setIsFinish(true);
                }
            } else {
                handleGetDeviceToken(idToken);
            }
        }
    }, [idToken]);

    React.useEffect(() => {
        if (!getCrmSessionRes.loading) {
            if (
                getCrmSessionRes &&
                getCrmSessionRes.data &&
                getCrmSessionRes.data?.getCrmSession?.accessToken
            ) {
                if (
                    moment
                        .unix(
                            Number(
                                getCrmSessionRes.data?.getCrmSession?.expires
                            )
                        )
                        .isBefore(moment())
                ) {
                    redirectPage();
                } else {
                    authDispatch(
                        Action.auth.setCrmSession({
                            token: getCrmSessionRes.data?.getCrmSession
                                ?.accessToken,
                            expires: moment.unix(
                                Number(
                                    getCrmSessionRes.data?.getCrmSession
                                        ?.expires
                                )
                            ),
                        })
                    );
                    localStorage.setItem(
                        "crmToken",
                        getCrmSessionRes.data?.getCrmSession?.accessToken
                    );
                }
            } else if (getCrmSessionRes && getCrmSessionRes.error) {
                redirectPage();
            } else {
                // apolloClient.resetStore();
            }
        }
    }, [getCrmSessionRes]);

    React.useEffect(() => {
        if (!getGameSessionRes.loading) {
            if (
                getGameSessionRes &&
                getGameSessionRes.data &&
                getGameSessionRes.data?.getGameSession?.accessToken
            ) {
                if (
                    moment
                        .unix(
                            Number(
                                getGameSessionRes.data?.getGameSession?.expires
                            )
                        )
                        .isBefore(moment())
                ) {
                    redirectPage();
                } else {
                }
            } else if (getGameSessionRes && getGameSessionRes.error) {
                redirectPage();
            } else {
                // apolloClient.resetStore();
            }
        }
    }, [getGameSessionRes]);

    const processAuth = async (data: any) => {
        sessionStorage.setItem("uid", data?.currentUser?.id);
        const crmUserId = await connectCRMServer(data?.currentUser?.id);
        console.log('currentUser', data?.currentUser);
        authDispatch(
            Action.auth.loginSuccess({
                id: get(data, "currentUser.id"),
                email: get(data, "currentUser.emails.edges[0].node.email"),
                username: get(data, "currentUser.username"),
                // crmToken: get(getCrmSessionData,"getCrmSession.accessToken"),
                crmUserId,
                stripe: data?.currentUser?.profile?.stripe
            })
        );
    };

    const connectCRMServer = async (webUserId: string) => {
        try {
            const { data } = await connectCrmSerser({
                variables: {
                    webUserId,
                },
                context: {
                    clientName: "crm-server",
                },
            });
            return data?.connect.id;
        } catch (err) {
            return "";
        }
    };

    const redirectPage = async () => {
        localStorage.clear();
        sessionStorage.clear();
        window.location.href = `/login?redirect=${window.location.href}`;
    };

    React.useEffect(() => {
        console.log("useSharedQuery in ProtectedRoute", data, loading);
        if (data && !data.currentUser) {
            redirectPage();
        }
    }, [data]);

    React.useEffect(() => {
        refetchUserShared();
    }, [location]);

    const isPassed =
        data &&
        data?.currentUser &&
        // timeZone?.timezone &&
        getCrmSessionRes &&
        idToken &&
        getGameSessionRes &&
        isFinish &&
        !loading;
        console.log("======== START =========")

    console.log("data", data)
    console.log("timeZone", timeZone)
    console.log("getCrmSessionRes", getCrmSessionRes)
    console.log("idToken", idToken)
    console.log("getGameSessionRes", getGameSessionRes)
    console.log("isFinish", isFinish)
    console.log("loading", loading)
    console.log("======== END =========")
    
    // Process measurement unit
    const { workoutState, workoutDispatch } = useContext(UserAppContext);
    const { appId, measureUnit } = useCheckAppHook();
    useEffect(() => {
        if (appId === CONSTANTS.APP.AVIRON_BIKE) {
            workoutDispatch(selectWorkoutUnit(measureUnit));
            localStorage.setItem("measureUnit", measureUnit);
        } else {
            workoutDispatch(selectWorkoutUnit(""));
            localStorage.setItem("measureUnit", "");
        }
    }, [appId, measureUnit]);
    console.log("======== START =========");


    return (
        <Route
            {...rest}
            render={(childProps) => {
                if (isPassed) {
                    return <Component {...childProps} />;
                }
                return <AvironLoading />;
            }}
        />
    );
};
export default ProtectedRoute;
