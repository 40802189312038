import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A floating point number that requires more precision than IEEE 754 binary 64 */
  BigFloat: any;
  /** A location in a connection that can be used for resuming pagination. */
  Cursor: any;
  /** The day, does not include a time. */
  Date: any;
  /**
   * A point in time as described by the [ISO
   * 8601](https://en.wikipedia.org/wiki/ISO_8601) standard. May or may not include a timezone.
   */
  Datetime: string;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { [key: string]: any };
  Object: any;
  /** A universally unique identifier as defined by [RFC 4122](https://tools.ietf.org/html/rfc4122). */
  UUID: any;
};

/** All input for the `acceptInvitationToOrganization` mutation. */
export type AcceptInvitationToOrganizationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  invitationId: Scalars['UUID'];
};

/** The output of our `acceptInvitationToOrganization` mutation. */
export type AcceptInvitationToOrganizationPayload = {
  __typename?: 'AcceptInvitationToOrganizationPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `activateUserSubscription` mutation. */
export type ActivateUserSubscriptionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  machineId: Scalars['String'];
  subscriptionToken: Scalars['String'];
};

/** The output of our `activateUserSubscription` mutation. */
export type ActivateUserSubscriptionPayload = {
  __typename?: 'ActivateUserSubscriptionPayload';
  activeSubResult?: Maybe<ActiveSubResult>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

export type ActiveSubResult = {
  __typename?: 'ActiveSubResult';
  customerType?: Maybe<Scalars['String']>;
  planId?: Maybe<Scalars['Int']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type ActiveUserSubscriptionInput = {
  machineId: Scalars['String'];
  source?: Maybe<Scalars['String']>;
  token: Scalars['String'];
};

export type AddParticipantInput = {
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  referredBy?: Maybe<Scalars['String']>;
};

export type AddParticipantPayload = {
  __typename?: 'AddParticipantPayload';
  code?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export type AddressFields = {
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  line1: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  postal_code?: Maybe<Scalars['Int']>;
  state?: Maybe<Scalars['String']>;
};

/** All input for the `adminAuthorization` mutation. */
export type AdminAuthorizationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** The output of our `adminAuthorization` mutation. */
export type AdminAuthorizationPayload = {
  __typename?: 'AdminAuthorizationPayload';
  boolean?: Maybe<Scalars['Boolean']>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

export type AdminCancelUserSubscriptionInput = {
  behavior?: Maybe<Scalars['String']>;
  cancelAt?: Maybe<Scalars['Datetime']>;
  id: Scalars['String'];
  reason: Scalars['String'];
  reasonDetail?: Maybe<Scalars['String']>;
};

/** All input for the `adminChangeUserPassword` mutation. */
export type AdminChangeUserPasswordInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  newPassword: Scalars['String'];
  userId: Scalars['UUID'];
};

/** The output of our `adminChangeUserPassword` mutation. */
export type AdminChangeUserPasswordPayload = {
  __typename?: 'AdminChangeUserPasswordPayload';
  boolean?: Maybe<Scalars['Boolean']>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

export type AdminGetUserPayload = {
  __typename?: 'AdminGetUserPayload';
  avatarUrl?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  profile?: Maybe<AdminGetUserProfile>;
  timezone?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  wallet?: Maybe<Scalars['JSON']>;
};

export type AdminGetUserProfile = {
  __typename?: 'AdminGetUserProfile';
  birthday?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Float']>;
  heightUnit?: Maybe<Scalars['Int']>;
  stripe?: Maybe<Scalars['JSON']>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['Int']>;
};

export type AdminGetUserProfileInput = {
  id?: Maybe<Scalars['String']>;
};

/** A connection to a list of `AdminHistory` values. */
export type AdminHistoriesConnection = {
  __typename?: 'AdminHistoriesConnection';
  /** A list of edges which contains the `AdminHistory` and cursor to aid in pagination. */
  edges: Array<AdminHistoriesEdge>;
  /** A list of `AdminHistory` objects. */
  nodes: Array<AdminHistory>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `AdminHistory` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `AdminHistory` edge in the connection. */
export type AdminHistoriesEdge = {
  __typename?: 'AdminHistoriesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `AdminHistory` at the end of the edge. */
  node: AdminHistory;
};

/** Methods to use when ordering `AdminHistory`. */
export enum AdminHistoriesOrderBy {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  TimestampAsc = 'TIMESTAMP_ASC',
  TimestampDesc = 'TIMESTAMP_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC'
}

export type AdminHistory = {
  __typename?: 'AdminHistory';
  action?: Maybe<Scalars['String']>;
  dataAfter?: Maybe<Scalars['JSON']>;
  dataBefore?: Maybe<Scalars['JSON']>;
  id: Scalars['UUID'];
  rowIdentify?: Maybe<Scalars['String']>;
  tableName?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['Datetime']>;
  /** Reads a single `User` that is related to this `AdminHistory`. */
  user?: Maybe<User>;
  userId?: Maybe<Scalars['UUID']>;
};

/**
 * A condition to be used against `AdminHistory` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type AdminHistoryCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `timestamp` field. */
  timestamp?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: Maybe<Scalars['UUID']>;
};

/** A filter to be used against `AdminHistory` object types. All fields are combined with a logical ‘and.’ */
export type AdminHistoryFilter = {
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<AdminHistoryFilter>>;
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Negates the expression. */
  not?: Maybe<AdminHistoryFilter>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<AdminHistoryFilter>>;
  /** Filter by the object’s `timestamp` field. */
  timestamp?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: Maybe<UuidFilter>;
};

/** An input for mutations affecting `AdminHistory` */
export type AdminHistoryInput = {
  action?: Maybe<Scalars['String']>;
  dataAfter?: Maybe<Scalars['JSON']>;
  dataBefore?: Maybe<Scalars['JSON']>;
  id?: Maybe<Scalars['UUID']>;
  rowIdentify?: Maybe<Scalars['String']>;
  tableName?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['Datetime']>;
  userId?: Maybe<Scalars['UUID']>;
};

export type AdminPrivilege = {
  __typename?: 'AdminPrivilege';
  id: Scalars['UUID'];
  privilegeType?: Maybe<Scalars['String']>;
  tableName?: Maybe<Scalars['String']>;
  tableSchema?: Maybe<Scalars['String']>;
  /** Reads a single `User` that is related to this `AdminPrivilege`. */
  user?: Maybe<User>;
  userId?: Maybe<Scalars['UUID']>;
};

/**
 * A condition to be used against `AdminPrivilege` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type AdminPrivilegeCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: Maybe<Scalars['UUID']>;
};

/** A filter to be used against `AdminPrivilege` object types. All fields are combined with a logical ‘and.’ */
export type AdminPrivilegeFilter = {
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<AdminPrivilegeFilter>>;
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Negates the expression. */
  not?: Maybe<AdminPrivilegeFilter>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<AdminPrivilegeFilter>>;
  /** Filter by the object’s `userId` field. */
  userId?: Maybe<UuidFilter>;
};

/** A connection to a list of `AdminPrivilege` values. */
export type AdminPrivilegesConnection = {
  __typename?: 'AdminPrivilegesConnection';
  /** A list of edges which contains the `AdminPrivilege` and cursor to aid in pagination. */
  edges: Array<AdminPrivilegesEdge>;
  /** A list of `AdminPrivilege` objects. */
  nodes: Array<AdminPrivilege>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `AdminPrivilege` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `AdminPrivilege` edge in the connection. */
export type AdminPrivilegesEdge = {
  __typename?: 'AdminPrivilegesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `AdminPrivilege` at the end of the edge. */
  node: AdminPrivilege;
};

/** Methods to use when ordering `AdminPrivilege`. */
export enum AdminPrivilegesOrderBy {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC'
}

export type AdminRole = {
  __typename?: 'AdminRole';
  role: Scalars['String'];
  /** Reads a single `User` that is related to this `AdminRole`. */
  user?: Maybe<User>;
  userId: Scalars['UUID'];
};

/**
 * A condition to be used against `AdminRole` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type AdminRoleCondition = {
  /** Checks for equality with the object’s `userId` field. */
  userId?: Maybe<Scalars['UUID']>;
};

/** A filter to be used against `AdminRole` object types. All fields are combined with a logical ‘and.’ */
export type AdminRoleFilter = {
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<AdminRoleFilter>>;
  /** Negates the expression. */
  not?: Maybe<AdminRoleFilter>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<AdminRoleFilter>>;
  /** Filter by the object’s `userId` field. */
  userId?: Maybe<UuidFilter>;
};

export type AdminRoleValue = {
  __typename?: 'AdminRoleValue';
  priority: Scalars['Int'];
  role: Scalars['String'];
};

/**
 * A condition to be used against `AdminRoleValue` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type AdminRoleValueCondition = {
  /** Checks for equality with the object’s `role` field. */
  role?: Maybe<Scalars['String']>;
};

/** A filter to be used against `AdminRoleValue` object types. All fields are combined with a logical ‘and.’ */
export type AdminRoleValueFilter = {
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<AdminRoleValueFilter>>;
  /** Negates the expression. */
  not?: Maybe<AdminRoleValueFilter>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<AdminRoleValueFilter>>;
  /** Filter by the object’s `role` field. */
  role?: Maybe<StringFilter>;
};

/** A connection to a list of `AdminRoleValue` values. */
export type AdminRoleValuesConnection = {
  __typename?: 'AdminRoleValuesConnection';
  /** A list of edges which contains the `AdminRoleValue` and cursor to aid in pagination. */
  edges: Array<AdminRoleValuesEdge>;
  /** A list of `AdminRoleValue` objects. */
  nodes: Array<AdminRoleValue>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `AdminRoleValue` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `AdminRoleValue` edge in the connection. */
export type AdminRoleValuesEdge = {
  __typename?: 'AdminRoleValuesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `AdminRoleValue` at the end of the edge. */
  node: AdminRoleValue;
};

/** Methods to use when ordering `AdminRoleValue`. */
export enum AdminRoleValuesOrderBy {
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  RoleAsc = 'ROLE_ASC',
  RoleDesc = 'ROLE_DESC'
}

/** A connection to a list of `AdminRole` values. */
export type AdminRolesConnection = {
  __typename?: 'AdminRolesConnection';
  /** A list of edges which contains the `AdminRole` and cursor to aid in pagination. */
  edges: Array<AdminRolesEdge>;
  /** A list of `AdminRole` objects. */
  nodes: Array<AdminRole>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `AdminRole` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `AdminRole` edge in the connection. */
export type AdminRolesEdge = {
  __typename?: 'AdminRolesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `AdminRole` at the end of the edge. */
  node: AdminRole;
};

/** Methods to use when ordering `AdminRole`. */
export enum AdminRolesOrderBy {
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC'
}

export type AdminUpdateUserAccount = {
  avatarUrl?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['String']>;
  firstname?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Float']>;
  height_unit?: Maybe<Scalars['Int']>;
  id: Scalars['UUID'];
  lastname?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  wallet?: Maybe<Scalars['JSON']>;
  weight?: Maybe<Scalars['Float']>;
  weight_unit?: Maybe<Scalars['Int']>;
};

export type AttachUserPaymentMethodInput = {
  currency?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  paymentMethodId?: Maybe<Scalars['String']>;
};

export type AttachUserPaymentMethodPayload = {
  __typename?: 'AttachUserPaymentMethodPayload';
  code?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['String']>;
};

export type AvironSubscription = {
  __typename?: 'AvironSubscription';
  cancelDay?: Maybe<Scalars['Datetime']>;
  checkoutItemSubscriptionId?: Maybe<Scalars['UUID']>;
  createdAt: Scalars['Datetime'];
  currentPeriodEnd?: Maybe<Scalars['Datetime']>;
  currentPeriodStart?: Maybe<Scalars['Datetime']>;
  /** Reads a single `User` that is related to this `AvironSubscription`. */
  customer?: Maybe<User>;
  customerId: Scalars['UUID'];
  endedAt?: Maybe<Scalars['Datetime']>;
  id: Scalars['UUID'];
  isPauseCollection?: Maybe<Scalars['Boolean']>;
  /** Reads a single `Machine` that is related to this `AvironSubscription`. */
  machine?: Maybe<Machine>;
  machineId?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  /** Reads a single `Plan` that is related to this `AvironSubscription`. */
  plan?: Maybe<Plan>;
  planId: Scalars['Int'];
  remark: Scalars['JSON'];
  resumeAt?: Maybe<Scalars['Datetime']>;
  startDay?: Maybe<Scalars['Datetime']>;
  state: Scalars['String'];
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  /** Reads and enables pagination through a set of `SubscriptionCancel`. */
  subscriptionCancelsBySubscriptionId: SubscriptionCancelsConnection;
  /** Reads and enables pagination through a set of `SubscriptionNote`. */
  subscriptionNotesBySubscriptionId: SubscriptionNotesConnection;
  /** Reads and enables pagination through a set of `SubscriptionPause`. */
  subscriptionPausesBySubscriptionId: SubscriptionPausesConnection;
  /** Reads and enables pagination through a set of `SubscriptionReceipt`. */
  subscriptionReceipts: SubscriptionReceiptsConnection;
  subscriptionToken: Scalars['String'];
  trialEnd?: Maybe<Scalars['Datetime']>;
  updatedAt: Scalars['Datetime'];
};


export type AvironSubscriptionSubscriptionCancelsBySubscriptionIdArgs = {
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  condition?: Maybe<SubscriptionCancelCondition>;
  filter?: Maybe<SubscriptionCancelFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<SubscriptionCancelsOrderBy>>;
};


export type AvironSubscriptionSubscriptionNotesBySubscriptionIdArgs = {
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  condition?: Maybe<SubscriptionNoteCondition>;
  filter?: Maybe<SubscriptionNoteFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<SubscriptionNotesOrderBy>>;
};


export type AvironSubscriptionSubscriptionPausesBySubscriptionIdArgs = {
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  condition?: Maybe<SubscriptionPauseCondition>;
  filter?: Maybe<SubscriptionPauseFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<SubscriptionPausesOrderBy>>;
};


export type AvironSubscriptionSubscriptionReceiptsArgs = {
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  condition?: Maybe<SubscriptionReceiptCondition>;
  filter?: Maybe<SubscriptionReceiptFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<SubscriptionReceiptsOrderBy>>;
};

/**
 * A condition to be used against `AvironSubscription` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type AvironSubscriptionCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `customerId` field. */
  customerId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `machineId` field. */
  machineId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `planId` field. */
  planId?: Maybe<Scalars['Int']>;
};

/** A filter to be used against `AvironSubscription` object types. All fields are combined with a logical ‘and.’ */
export type AvironSubscriptionFilter = {
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<AvironSubscriptionFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `customerId` field. */
  customerId?: Maybe<UuidFilter>;
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `machineId` field. */
  machineId?: Maybe<StringFilter>;
  /** Negates the expression. */
  not?: Maybe<AvironSubscriptionFilter>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<AvironSubscriptionFilter>>;
  /** Filter by the object’s `planId` field. */
  planId?: Maybe<IntFilter>;
};

/** An input for mutations affecting `AvironSubscription` */
export type AvironSubscriptionInput = {
  planId: Scalars['Int'];
};

/** Represents an update to a `AvironSubscription`. Fields that are set will be updated. */
export type AvironSubscriptionPatch = {
  cancelDay?: Maybe<Scalars['Datetime']>;
  currentPeriodEnd?: Maybe<Scalars['Datetime']>;
  currentPeriodStart?: Maybe<Scalars['Datetime']>;
  customerId?: Maybe<Scalars['UUID']>;
  machineId?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  planId?: Maybe<Scalars['Int']>;
  startDay?: Maybe<Scalars['Datetime']>;
  state?: Maybe<Scalars['String']>;
};

/** A connection to a list of `AvironSubscription` values. */
export type AvironSubscriptionsConnection = {
  __typename?: 'AvironSubscriptionsConnection';
  /** A list of edges which contains the `AvironSubscription` and cursor to aid in pagination. */
  edges: Array<AvironSubscriptionsEdge>;
  /** A list of `AvironSubscription` objects. */
  nodes: Array<AvironSubscription>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `AvironSubscription` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `AvironSubscription` edge in the connection. */
export type AvironSubscriptionsEdge = {
  __typename?: 'AvironSubscriptionsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `AvironSubscription` at the end of the edge. */
  node: AvironSubscription;
};

export type AvironSubscriptionsInput = {
  planId: Scalars['Int'];
};

/** Methods to use when ordering `AvironSubscription`. */
export enum AvironSubscriptionsOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CustomerIdAsc = 'CUSTOMER_ID_ASC',
  CustomerIdDesc = 'CUSTOMER_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  MachineIdAsc = 'MACHINE_ID_ASC',
  MachineIdDesc = 'MACHINE_ID_DESC',
  Natural = 'NATURAL',
  PlanIdAsc = 'PLAN_ID_ASC',
  PlanIdDesc = 'PLAN_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type AvironSubscriptionsPayload = {
  __typename?: 'AvironSubscriptionsPayload';
  subscription: AvironSubscription;
};

export type BetaRequest = {
  __typename?: 'BetaRequest';
  canceledAt?: Maybe<Scalars['Datetime']>;
  cancelReason?: Maybe<Scalars['String']>;
  createdAt: Scalars['Datetime'];
  discord: Scalars['String'];
  id: Scalars['UUID'];
  machineSerial: Scalars['String'];
  status?: Maybe<Scalars['String']>;
  updatedAt: Scalars['Datetime'];
  /** Reads a single `User` that is related to this `BetaRequest`. */
  user?: Maybe<User>;
  userId: Scalars['UUID'];
  workoutPerWeek: Scalars['String'];
};

/**
 * A condition to be used against `BetaRequest` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type BetaRequestCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `status` field. */
  status?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: Maybe<Scalars['UUID']>;
};

/** A filter to be used against `BetaRequest` object types. All fields are combined with a logical ‘and.’ */
export type BetaRequestFilter = {
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<BetaRequestFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Negates the expression. */
  not?: Maybe<BetaRequestFilter>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<BetaRequestFilter>>;
  /** Filter by the object’s `status` field. */
  status?: Maybe<StringFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: Maybe<UuidFilter>;
};

/** An input for mutations affecting `BetaRequest` */
export type BetaRequestInput = {
  canceledAt?: Maybe<Scalars['Datetime']>;
  cancelReason?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  discord: Scalars['String'];
  id?: Maybe<Scalars['UUID']>;
  machineSerial: Scalars['String'];
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  userId: Scalars['UUID'];
  workoutPerWeek: Scalars['String'];
};

/** Represents an update to a `BetaRequest`. Fields that are set will be updated. */
export type BetaRequestPatch = {
  canceledAt?: Maybe<Scalars['Datetime']>;
  cancelReason?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  discord?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  machineSerial?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  userId?: Maybe<Scalars['UUID']>;
  workoutPerWeek?: Maybe<Scalars['String']>;
};

/** A connection to a list of `BetaRequest` values. */
export type BetaRequestsConnection = {
  __typename?: 'BetaRequestsConnection';
  /** A list of edges which contains the `BetaRequest` and cursor to aid in pagination. */
  edges: Array<BetaRequestsEdge>;
  /** A list of `BetaRequest` objects. */
  nodes: Array<BetaRequest>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `BetaRequest` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `BetaRequest` edge in the connection. */
export type BetaRequestsEdge = {
  __typename?: 'BetaRequestsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `BetaRequest` at the end of the edge. */
  node: BetaRequest;
};

/** Methods to use when ordering `BetaRequest`. */
export enum BetaRequestsOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC'
}


/** A connection to a list of `BlogCategory` values. */
export type BlogCategoriesConnection = {
  __typename?: 'BlogCategoriesConnection';
  /** A list of edges which contains the `BlogCategory` and cursor to aid in pagination. */
  edges: Array<BlogCategoriesEdge>;
  /** A list of `BlogCategory` objects. */
  nodes: Array<BlogCategory>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `BlogCategory` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `BlogCategory` edge in the connection. */
export type BlogCategoriesEdge = {
  __typename?: 'BlogCategoriesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `BlogCategory` at the end of the edge. */
  node: BlogCategory;
};

/** Methods to use when ordering `BlogCategory`. */
export enum BlogCategoriesOrderBy {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  SlugAsc = 'SLUG_ASC',
  SlugDesc = 'SLUG_DESC'
}

export type BlogCategory = {
  __typename?: 'BlogCategory';
  createdAt?: Maybe<Scalars['Datetime']>;
  description: Scalars['String'];
  id: Scalars['UUID'];
  name: Scalars['String'];
  /** Reads and enables pagination through a set of `Post`. */
  posts: PostsConnection;
  slug: Scalars['String'];
  updatedAt?: Maybe<Scalars['Datetime']>;
  userId: Scalars['UUID'];
};


export type BlogCategoryPostsArgs = {
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  condition?: Maybe<PostCondition>;
  filter?: Maybe<PostFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<PostsOrderBy>>;
};

/**
 * A condition to be used against `BlogCategory` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type BlogCategoryCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `slug` field. */
  slug?: Maybe<Scalars['String']>;
};

/** A filter to be used against `BlogCategory` object types. All fields are combined with a logical ‘and.’ */
export type BlogCategoryFilter = {
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<BlogCategoryFilter>>;
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Negates the expression. */
  not?: Maybe<BlogCategoryFilter>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<BlogCategoryFilter>>;
  /** Filter by the object’s `slug` field. */
  slug?: Maybe<StringFilter>;
};

/** An input for mutations affecting `BlogCategory` */
export type BlogCategoryInput = {
  createdAt?: Maybe<Scalars['Datetime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  name: Scalars['String'];
  slug: Scalars['String'];
  updatedAt?: Maybe<Scalars['Datetime']>;
  userId: Scalars['UUID'];
};

/** Represents an update to a `BlogCategory`. Fields that are set will be updated. */
export type BlogCategoryPatch = {
  createdAt?: Maybe<Scalars['Datetime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  userId?: Maybe<Scalars['UUID']>;
};

/** A filter to be used against Boolean fields. All fields are combined with a logical ‘and.’ */
export type BooleanFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<Scalars['Boolean']>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<Scalars['Boolean']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<Scalars['Boolean']>;
  /** Included in the specified list. */
  in?: Maybe<Array<Scalars['Boolean']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: Maybe<Scalars['Boolean']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<Scalars['Boolean']>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<Scalars['Boolean']>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<Scalars['Boolean']>>;
};

export type CancelUserSubscriptionInput = {
  id: Scalars['String'];
  reason: Scalars['String'];
  reasonDetail?: Maybe<Scalars['String']>;
};

export type Challenge = {
  __typename?: 'Challenge';
  createdAt?: Maybe<Scalars['Datetime']>;
  description: Scalars['String'];
  endDate: Scalars['Datetime'];
  id: Scalars['UUID'];
  isPublic?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  startDate: Scalars['Datetime'];
  targetType?: Maybe<Scalars['String']>;
  targetValue: Scalars['Int'];
  thumb?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
};

/**
 * A condition to be used against `Challenge` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type ChallengeCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
};

/** A filter to be used against `Challenge` object types. All fields are combined with a logical ‘and.’ */
export type ChallengeFilter = {
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<ChallengeFilter>>;
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Negates the expression. */
  not?: Maybe<ChallengeFilter>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<ChallengeFilter>>;
};

/** An input for mutations affecting `Challenge` */
export type ChallengeInput = {
  createdAt?: Maybe<Scalars['Datetime']>;
  description: Scalars['String'];
  endDate: Scalars['Datetime'];
  id?: Maybe<Scalars['UUID']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  startDate: Scalars['Datetime'];
  targetType?: Maybe<Scalars['String']>;
  targetValue: Scalars['Int'];
  thumb?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
};

/** Represents an update to a `Challenge`. Fields that are set will be updated. */
export type ChallengePatch = {
  createdAt?: Maybe<Scalars['Datetime']>;
  description?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['Datetime']>;
  id?: Maybe<Scalars['UUID']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Datetime']>;
  targetType?: Maybe<Scalars['String']>;
  targetValue?: Maybe<Scalars['Int']>;
  thumb?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
};

/** A connection to a list of `Challenge` values. */
export type ChallengesConnection = {
  __typename?: 'ChallengesConnection';
  /** A list of edges which contains the `Challenge` and cursor to aid in pagination. */
  edges: Array<ChallengesEdge>;
  /** A list of `Challenge` objects. */
  nodes: Array<Challenge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Challenge` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Challenge` edge in the connection. */
export type ChallengesEdge = {
  __typename?: 'ChallengesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Challenge` at the end of the edge. */
  node: Challenge;
};

/** Methods to use when ordering `Challenge`. */
export enum ChallengesOrderBy {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** All input for the `changePassword` mutation. */
export type ChangePasswordInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  newPassword: Scalars['String'];
  oldPassword: Scalars['String'];
};

/** The output of our `changePassword` mutation. */
export type ChangePasswordPayload = {
  __typename?: 'ChangePasswordPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  success?: Maybe<Scalars['Boolean']>;
};

/** All input for the `changePwdlessPassword` mutation. */
export type ChangePwdlessPasswordInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  newPassword: Scalars['String'];
};

/** The output of our `changePwdlessPassword` mutation. */
export type ChangePwdlessPasswordPayload = {
  __typename?: 'ChangePwdlessPasswordPayload';
  boolean?: Maybe<Scalars['Boolean']>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

export type ChangeUserEmailInput = {
  newEmail: Scalars['String'];
  userId: Scalars['String'];
};

export type ChangeUserEmailPayload = {
  __typename?: 'ChangeUserEmailPayload';
  code?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['String']>;
};

export type ChangeUserSubscriptionPlanInput = {
  id: Scalars['String'];
  planId: Scalars['Int'];
};

export type CheckBetaIsLivePayload = {
  __typename?: 'CheckBetaIsLivePayload';
  endDate?: Maybe<Scalars['Date']>;
  isLive?: Maybe<Scalars['Boolean']>;
  startDate?: Maybe<Scalars['Date']>;
};

/** All input for the `checkEmailExists` mutation. */
export type CheckEmailExistsInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
};

/** The output of our `checkEmailExists` mutation. */
export type CheckEmailExistsPayload = {
  __typename?: 'CheckEmailExistsPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  exists?: Maybe<Scalars['Boolean']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

export type CheckGuestUserInput = {
  email: Scalars['String'];
};

export type CheckGuestUserPayload = {
  __typename?: 'CheckGuestUserPayload';
  isRequireLogin: Scalars['Boolean'];
};

/** All input for the `checkMachinesActivated` mutation. */
export type CheckMachinesActivatedInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  machineIds: Array<Maybe<Scalars['String']>>;
};

/** The output of our `checkMachinesActivated` mutation. */
export type CheckMachinesActivatedPayload = {
  __typename?: 'CheckMachinesActivatedPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  machineIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `checkPasswordlessAccount` mutation. */
export type CheckPasswordlessAccountInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
};

/** The output of our `checkPasswordlessAccount` mutation. */
export type CheckPasswordlessAccountPayload = {
  __typename?: 'CheckPasswordlessAccountPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  passwordless?: Maybe<Scalars['Boolean']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `checkStripeSubscriptionExists` mutation. */
export type CheckStripeSubscriptionExistsInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  machineId: Scalars['String'];
  subscriptionToken: Scalars['String'];
};

/** The output of our `checkStripeSubscriptionExists` mutation. */
export type CheckStripeSubscriptionExistsPayload = {
  __typename?: 'CheckStripeSubscriptionExistsPayload';
  boolean?: Maybe<Scalars['Boolean']>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

export type CheckUpgradeSubscriptionInput = {
  id: Scalars['String'];
};

export type CheckUserJoinedBetaProgramInput = {
  appId: Scalars['Int'];
  userId: Scalars['String'];
};

/** All input for the `checkUserVerified` mutation. */
export type CheckUserVerifiedInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
};

/** The output of our `checkUserVerified` mutation. */
export type CheckUserVerifiedPayload = {
  __typename?: 'CheckUserVerifiedPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

export type CheckoutItem = {
  __typename?: 'CheckoutItem';
  checkoutId: Scalars['UUID'];
  createdAt?: Maybe<Scalars['Datetime']>;
  id: Scalars['UUID'];
  name: Scalars['String'];
  price: Scalars['Int'];
  quantity: Scalars['Int'];
  saleorCategoryId?: Maybe<Scalars['String']>;
  saleorId: Scalars['String'];
  saleorVariantId: Scalars['String'];
  thumb?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  updatedAt?: Maybe<Scalars['Datetime']>;
};

/**
 * A condition to be used against `CheckoutItem` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type CheckoutItemCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
};

/** A filter to be used against `CheckoutItem` object types. All fields are combined with a logical ‘and.’ */
export type CheckoutItemFilter = {
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<CheckoutItemFilter>>;
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Negates the expression. */
  not?: Maybe<CheckoutItemFilter>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<CheckoutItemFilter>>;
};

export type CheckoutItemMachine = {
  __typename?: 'CheckoutItemMachine';
  affirmChargeId?: Maybe<Scalars['String']>;
  checkoutId: Scalars['UUID'];
  checkoutItemId: Scalars['UUID'];
  id: Scalars['UUID'];
  paymentMethod: Scalars['String'];
  status: Scalars['String'];
  stripePaymentIntentId?: Maybe<Scalars['String']>;
};

/**
 * A condition to be used against `CheckoutItemMachine` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type CheckoutItemMachineCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
};

/** A filter to be used against `CheckoutItemMachine` object types. All fields are combined with a logical ‘and.’ */
export type CheckoutItemMachineFilter = {
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<CheckoutItemMachineFilter>>;
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Negates the expression. */
  not?: Maybe<CheckoutItemMachineFilter>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<CheckoutItemMachineFilter>>;
};

/** A connection to a list of `CheckoutItemMachine` values. */
export type CheckoutItemMachinesConnection = {
  __typename?: 'CheckoutItemMachinesConnection';
  /** A list of edges which contains the `CheckoutItemMachine` and cursor to aid in pagination. */
  edges: Array<CheckoutItemMachinesEdge>;
  /** A list of `CheckoutItemMachine` objects. */
  nodes: Array<CheckoutItemMachine>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CheckoutItemMachine` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `CheckoutItemMachine` edge in the connection. */
export type CheckoutItemMachinesEdge = {
  __typename?: 'CheckoutItemMachinesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CheckoutItemMachine` at the end of the edge. */
  node: CheckoutItemMachine;
};

/** Methods to use when ordering `CheckoutItemMachine`. */
export enum CheckoutItemMachinesOrderBy {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type CheckoutItemSubscription = {
  __typename?: 'CheckoutItemSubscription';
  checkoutId: Scalars['UUID'];
  checkoutItemId: Scalars['UUID'];
  id: Scalars['UUID'];
  planId?: Maybe<Scalars['Int']>;
  status: Scalars['String'];
  stripePriceId: Scalars['String'];
};

/**
 * A condition to be used against `CheckoutItemSubscription` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type CheckoutItemSubscriptionCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
};

/** A filter to be used against `CheckoutItemSubscription` object types. All fields are combined with a logical ‘and.’ */
export type CheckoutItemSubscriptionFilter = {
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<CheckoutItemSubscriptionFilter>>;
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Negates the expression. */
  not?: Maybe<CheckoutItemSubscriptionFilter>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<CheckoutItemSubscriptionFilter>>;
};

/** A connection to a list of `CheckoutItemSubscription` values. */
export type CheckoutItemSubscriptionsConnection = {
  __typename?: 'CheckoutItemSubscriptionsConnection';
  /** A list of edges which contains the `CheckoutItemSubscription` and cursor to aid in pagination. */
  edges: Array<CheckoutItemSubscriptionsEdge>;
  /** A list of `CheckoutItemSubscription` objects. */
  nodes: Array<CheckoutItemSubscription>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CheckoutItemSubscription` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `CheckoutItemSubscription` edge in the connection. */
export type CheckoutItemSubscriptionsEdge = {
  __typename?: 'CheckoutItemSubscriptionsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CheckoutItemSubscription` at the end of the edge. */
  node: CheckoutItemSubscription;
};

/** Methods to use when ordering `CheckoutItemSubscription`. */
export enum CheckoutItemSubscriptionsOrderBy {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** A connection to a list of `CheckoutItem` values. */
export type CheckoutItemsConnection = {
  __typename?: 'CheckoutItemsConnection';
  /** A list of edges which contains the `CheckoutItem` and cursor to aid in pagination. */
  edges: Array<CheckoutItemsEdge>;
  /** A list of `CheckoutItem` objects. */
  nodes: Array<CheckoutItem>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CheckoutItem` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `CheckoutItem` edge in the connection. */
export type CheckoutItemsEdge = {
  __typename?: 'CheckoutItemsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CheckoutItem` at the end of the edge. */
  node: CheckoutItem;
};

/** Methods to use when ordering `CheckoutItem`. */
export enum CheckoutItemsOrderBy {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** All input for the `compareUserPriority` mutation. */
export type CompareUserPriorityInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  compareUserId: Scalars['UUID'];
};

/** The output of our `compareUserPriority` mutation. */
export type CompareUserPriorityPayload = {
  __typename?: 'CompareUserPriorityPayload';
  boolean?: Maybe<Scalars['Boolean']>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `confirmAccountDeletion` mutation. */
export type ConfirmAccountDeletionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  token: Scalars['String'];
};

/** The output of our `confirmAccountDeletion` mutation. */
export type ConfirmAccountDeletionPayload = {
  __typename?: 'ConfirmAccountDeletionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  success?: Maybe<Scalars['Boolean']>;
};

/** All input for the `countMachinesActivated` mutation. */
export type CountMachinesActivatedInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  machineIds: Array<Maybe<Scalars['String']>>;
};

/** The output of our `countMachinesActivated` mutation. */
export type CountMachinesActivatedPayload = {
  __typename?: 'CountMachinesActivatedPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  results?: Maybe<Array<Maybe<CountMachinesActivatedRecord>>>;
};

/** The return type of our `countMachinesActivated` mutation. */
export type CountMachinesActivatedRecord = {
  __typename?: 'CountMachinesActivatedRecord';
  machineId?: Maybe<Scalars['String']>;
  subscriptionActivated?: Maybe<Scalars['Int']>;
};

/** All input for the `createAdminAccount` mutation. */
export type CreateAdminAccountInput = {
  adminPrivileges: Scalars['JSON'];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  role: Scalars['String'];
};

/** The output of our `createAdminAccount` mutation. */
export type CreateAdminAccountPayload = {
  __typename?: 'CreateAdminAccountPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  user?: Maybe<User>;
  /** An edge for our `User`. May be used by Relay 1. */
  userEdge?: Maybe<UsersEdge>;
};


/** The output of our `createAdminAccount` mutation. */
export type CreateAdminAccountPayloadUserEdgeArgs = {
  orderBy?: Maybe<Array<UsersOrderBy>>;
};

/** All input for the create `AdminHistory` mutation. */
export type CreateAdminHistoryInput = {
  /** The `AdminHistory` to be created by this mutation. */
  adminHistory: AdminHistoryInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** The output of our create `AdminHistory` mutation. */
export type CreateAdminHistoryPayload = {
  __typename?: 'CreateAdminHistoryPayload';
  /** The `AdminHistory` that was created by this mutation. */
  adminHistory?: Maybe<AdminHistory>;
  /** An edge for our `AdminHistory`. May be used by Relay 1. */
  adminHistoryEdge?: Maybe<AdminHistoriesEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `AdminHistory`. */
  user?: Maybe<User>;
};


/** The output of our create `AdminHistory` mutation. */
export type CreateAdminHistoryPayloadAdminHistoryEdgeArgs = {
  orderBy?: Maybe<Array<AdminHistoriesOrderBy>>;
};

/** All input for the create `AvironSubscription` mutation. */
export type CreateAvironSubscriptionInput = {
  /** The `AvironSubscription` to be created by this mutation. */
  avironSubscription: AvironSubscriptionInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** The output of our create `AvironSubscription` mutation. */
export type CreateAvironSubscriptionPayload = {
  __typename?: 'CreateAvironSubscriptionPayload';
  /** The `AvironSubscription` that was created by this mutation. */
  avironSubscription?: Maybe<AvironSubscription>;
  /** An edge for our `AvironSubscription`. May be used by Relay 1. */
  avironSubscriptionEdge?: Maybe<AvironSubscriptionsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `User` that is related to this `AvironSubscription`. */
  customer?: Maybe<User>;
  /** Reads a single `Machine` that is related to this `AvironSubscription`. */
  machine?: Maybe<Machine>;
  /** Reads a single `Plan` that is related to this `AvironSubscription`. */
  plan?: Maybe<Plan>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our create `AvironSubscription` mutation. */
export type CreateAvironSubscriptionPayloadAvironSubscriptionEdgeArgs = {
  orderBy?: Maybe<Array<AvironSubscriptionsOrderBy>>;
};

/** All input for the create `BetaRequest` mutation. */
export type CreateBetaRequestInput = {
  /** The `BetaRequest` to be created by this mutation. */
  betaRequest: BetaRequestInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** The output of our create `BetaRequest` mutation. */
export type CreateBetaRequestPayload = {
  __typename?: 'CreateBetaRequestPayload';
  /** The `BetaRequest` that was created by this mutation. */
  betaRequest?: Maybe<BetaRequest>;
  /** An edge for our `BetaRequest`. May be used by Relay 1. */
  betaRequestEdge?: Maybe<BetaRequestsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `BetaRequest`. */
  user?: Maybe<User>;
};


/** The output of our create `BetaRequest` mutation. */
export type CreateBetaRequestPayloadBetaRequestEdgeArgs = {
  orderBy?: Maybe<Array<BetaRequestsOrderBy>>;
};

/** All input for the create `BlogCategory` mutation. */
export type CreateBlogCategoryInput = {
  /** The `BlogCategory` to be created by this mutation. */
  blogCategory: BlogCategoryInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** The output of our create `BlogCategory` mutation. */
export type CreateBlogCategoryPayload = {
  __typename?: 'CreateBlogCategoryPayload';
  /** The `BlogCategory` that was created by this mutation. */
  blogCategory?: Maybe<BlogCategory>;
  /** An edge for our `BlogCategory`. May be used by Relay 1. */
  blogCategoryEdge?: Maybe<BlogCategoriesEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our create `BlogCategory` mutation. */
export type CreateBlogCategoryPayloadBlogCategoryEdgeArgs = {
  orderBy?: Maybe<Array<BlogCategoriesOrderBy>>;
};

/** All input for the create `Challenge` mutation. */
export type CreateChallengeInput = {
  /** The `Challenge` to be created by this mutation. */
  challenge: ChallengeInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** The output of our create `Challenge` mutation. */
export type CreateChallengePayload = {
  __typename?: 'CreateChallengePayload';
  /** The `Challenge` that was created by this mutation. */
  challenge?: Maybe<Challenge>;
  /** An edge for our `Challenge`. May be used by Relay 1. */
  challengeEdge?: Maybe<ChallengesEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our create `Challenge` mutation. */
export type CreateChallengePayloadChallengeEdgeArgs = {
  orderBy?: Maybe<Array<ChallengesOrderBy>>;
};

/** All input for the create `DealerLocation` mutation. */
export type CreateDealerLocationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `DealerLocation` to be created by this mutation. */
  dealerLocation: DealerLocationInput;
};

/** The output of our create `DealerLocation` mutation. */
export type CreateDealerLocationPayload = {
  __typename?: 'CreateDealerLocationPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `DealerLocation` that was created by this mutation. */
  dealerLocation?: Maybe<DealerLocation>;
  /** An edge for our `DealerLocation`. May be used by Relay 1. */
  dealerLocationEdge?: Maybe<DealerLocationsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our create `DealerLocation` mutation. */
export type CreateDealerLocationPayloadDealerLocationEdgeArgs = {
  orderBy?: Maybe<Array<DealerLocationsOrderBy>>;
};

/** All input for the create `ExperienceCategory` mutation. */
export type CreateExperienceCategoryInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `ExperienceCategory` to be created by this mutation. */
  experienceCategory: ExperienceCategoryInput;
};

/** The output of our create `ExperienceCategory` mutation. */
export type CreateExperienceCategoryPayload = {
  __typename?: 'CreateExperienceCategoryPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `ExperienceCategory` that was created by this mutation. */
  experienceCategory?: Maybe<ExperienceCategory>;
  /** An edge for our `ExperienceCategory`. May be used by Relay 1. */
  experienceCategoryEdge?: Maybe<ExperienceCategoriesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our create `ExperienceCategory` mutation. */
export type CreateExperienceCategoryPayloadExperienceCategoryEdgeArgs = {
  orderBy?: Maybe<Array<ExperienceCategoriesOrderBy>>;
};

/** All input for the create `ExperienceDifficulty` mutation. */
export type CreateExperienceDifficultyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `ExperienceDifficulty` to be created by this mutation. */
  experienceDifficulty: ExperienceDifficultyInput;
};

/** The output of our create `ExperienceDifficulty` mutation. */
export type CreateExperienceDifficultyPayload = {
  __typename?: 'CreateExperienceDifficultyPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `ExperienceDifficulty` that was created by this mutation. */
  experienceDifficulty?: Maybe<ExperienceDifficulty>;
  /** An edge for our `ExperienceDifficulty`. May be used by Relay 1. */
  experienceDifficultyEdge?: Maybe<ExperienceDifficultiesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our create `ExperienceDifficulty` mutation. */
export type CreateExperienceDifficultyPayloadExperienceDifficultyEdgeArgs = {
  orderBy?: Maybe<Array<ExperienceDifficultiesOrderBy>>;
};

/** All input for the create `Experience` mutation. */
export type CreateExperienceInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Experience` to be created by this mutation. */
  experience: ExperienceInput;
};

/** The output of our create `Experience` mutation. */
export type CreateExperiencePayload = {
  __typename?: 'CreateExperiencePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Experience` that was created by this mutation. */
  experience?: Maybe<Experience>;
  /** Reads a single `ExperienceCategory` that is related to this `Experience`. */
  experienceCategory?: Maybe<ExperienceCategory>;
  /** An edge for our `Experience`. May be used by Relay 1. */
  experienceEdge?: Maybe<ExperiencesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our create `Experience` mutation. */
export type CreateExperiencePayloadExperienceEdgeArgs = {
  orderBy?: Maybe<Array<ExperiencesOrderBy>>;
};

/** All input for the create `Machine` mutation. */
export type CreateMachineInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Machine` to be created by this mutation. */
  machine: MachineInput;
};

/** The output of our create `Machine` mutation. */
export type CreateMachinePayload = {
  __typename?: 'CreateMachinePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Machine` that was created by this mutation. */
  machine?: Maybe<Machine>;
  /** An edge for our `Machine`. May be used by Relay 1. */
  machineEdge?: Maybe<MachinesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our create `Machine` mutation. */
export type CreateMachinePayloadMachineEdgeArgs = {
  orderBy?: Maybe<Array<MachinesOrderBy>>;
};

/** All input for the `createManualSubscription` mutation. */
export type CreateManualSubscriptionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  isSendMail: Scalars['Boolean'];
  machineId?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  planId: Scalars['Int'];
};

/** The output of our `createManualSubscription` mutation. */
export type CreateManualSubscriptionPayload = {
  __typename?: 'CreateManualSubscriptionPayload';
  avironSubscription?: Maybe<AvironSubscription>;
  /** An edge for our `AvironSubscription`. May be used by Relay 1. */
  avironSubscriptionEdge?: Maybe<AvironSubscriptionsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `User` that is related to this `AvironSubscription`. */
  customer?: Maybe<User>;
  /** Reads a single `Machine` that is related to this `AvironSubscription`. */
  machine?: Maybe<Machine>;
  /** Reads a single `Plan` that is related to this `AvironSubscription`. */
  plan?: Maybe<Plan>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our `createManualSubscription` mutation. */
export type CreateManualSubscriptionPayloadAvironSubscriptionEdgeArgs = {
  orderBy?: Maybe<Array<AvironSubscriptionsOrderBy>>;
};

/** All input for the `createManualSubscriptions` mutation. */
export type CreateManualSubscriptionsInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  isSendMail: Scalars['Boolean'];
  machineId?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  planId: Scalars['Int'];
  quantity?: Maybe<Scalars['Int']>;
};

/** The output of our `createManualSubscriptions` mutation. */
export type CreateManualSubscriptionsPayload = {
  __typename?: 'CreateManualSubscriptionsPayload';
  avironSubscriptions?: Maybe<Array<Maybe<AvironSubscription>>>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `createOrganization` mutation. */
export type CreateOrganizationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  slug: Scalars['String'];
};

/** The output of our `createOrganization` mutation. */
export type CreateOrganizationPayload = {
  __typename?: 'CreateOrganizationPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
  /** An edge for our `Organization`. May be used by Relay 1. */
  organizationEdge?: Maybe<OrganizationsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our `createOrganization` mutation. */
export type CreateOrganizationPayloadOrganizationEdgeArgs = {
  orderBy?: Maybe<Array<OrganizationsOrderBy>>;
};

/** All input for the create `Plan` mutation. */
export type CreatePlanInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Plan` to be created by this mutation. */
  plan: PlanInput;
};

/** The output of our create `Plan` mutation. */
export type CreatePlanPayload = {
  __typename?: 'CreatePlanPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `CustomerType` that is related to this `Plan`. */
  customerTypeByCustomerType?: Maybe<CustomerType>;
  /** The `Plan` that was created by this mutation. */
  plan?: Maybe<Plan>;
  /** An edge for our `Plan`. May be used by Relay 1. */
  planEdge?: Maybe<PlansEdge>;
  /** Reads a single `PlanType` that is related to this `Plan`. */
  planTypeByType?: Maybe<PlanType>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our create `Plan` mutation. */
export type CreatePlanPayloadPlanEdgeArgs = {
  orderBy?: Maybe<Array<PlansOrderBy>>;
};

/** All input for the create `Post` mutation. */
export type CreatePostInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Post` to be created by this mutation. */
  post: PostInput;
};

/** The output of our create `Post` mutation. */
export type CreatePostPayload = {
  __typename?: 'CreatePostPayload';
  /** Reads a single `BlogCategory` that is related to this `Post`. */
  blogCategory?: Maybe<BlogCategory>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Post` that was created by this mutation. */
  post?: Maybe<Post>;
  /** An edge for our `Post`. May be used by Relay 1. */
  postEdge?: Maybe<PostsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our create `Post` mutation. */
export type CreatePostPayloadPostEdgeArgs = {
  orderBy?: Maybe<Array<PostsOrderBy>>;
};

/** All input for the create `Press` mutation. */
export type CreatePressInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Press` to be created by this mutation. */
  press: PressInput;
};

/** The output of our create `Press` mutation. */
export type CreatePressPayload = {
  __typename?: 'CreatePressPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Press` that was created by this mutation. */
  press?: Maybe<Press>;
  /** An edge for our `Press`. May be used by Relay 1. */
  pressEdge?: Maybe<PressesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our create `Press` mutation. */
export type CreatePressPayloadPressEdgeArgs = {
  orderBy?: Maybe<Array<PressesOrderBy>>;
};

export type CreateStripeSubscriptionsInput = {
  email?: Maybe<Scalars['String']>;
  items?: Maybe<Scalars['JSON']>;
  orderCode?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['String']>;
};

/** All input for the create `SupportCategory` mutation. */
export type CreateSupportCategoryInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `SupportCategory` to be created by this mutation. */
  supportCategory: SupportCategoryInput;
};

/** The output of our create `SupportCategory` mutation. */
export type CreateSupportCategoryPayload = {
  __typename?: 'CreateSupportCategoryPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `SupportCategory` that was created by this mutation. */
  supportCategory?: Maybe<SupportCategory>;
  /** An edge for our `SupportCategory`. May be used by Relay 1. */
  supportCategoryEdge?: Maybe<SupportCategoriesEdge>;
  /** Reads a single `User` that is related to this `SupportCategory`. */
  userByCreatedBy?: Maybe<User>;
};


/** The output of our create `SupportCategory` mutation. */
export type CreateSupportCategoryPayloadSupportCategoryEdgeArgs = {
  orderBy?: Maybe<Array<SupportCategoriesOrderBy>>;
};

/** All input for the create `Support` mutation. */
export type CreateSupportInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Support` to be created by this mutation. */
  support: SupportInput;
};

/** The output of our create `Support` mutation. */
export type CreateSupportPayload = {
  __typename?: 'CreateSupportPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `Support` that was created by this mutation. */
  support?: Maybe<Support>;
  /** Reads a single `SupportCategory` that is related to this `Support`. */
  supportCategory?: Maybe<SupportCategory>;
  /** An edge for our `Support`. May be used by Relay 1. */
  supportEdge?: Maybe<SupportsEdge>;
  /** Reads a single `User` that is related to this `Support`. */
  userByCreatedBy?: Maybe<User>;
};


/** The output of our create `Support` mutation. */
export type CreateSupportPayloadSupportEdgeArgs = {
  orderBy?: Maybe<Array<SupportsOrderBy>>;
};

export type CreateUploadPhotoUrlInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  contentType: Scalars['String'];
  name?: Maybe<Scalars['String']>;
};

export type CreateUploadUrlInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  contentType: Scalars['String'];
};

export type CreateUploadUrlPayload = {
  __typename?: 'CreateUploadUrlPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  fields: PolicyFields;
  publicUrl: Scalars['String'];
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  uploadUrl: Scalars['String'];
};

/** All input for the create `UserEmail` mutation. */
export type CreateUserEmailInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `UserEmail` to be created by this mutation. */
  userEmail: UserEmailInput;
};

/** The output of our create `UserEmail` mutation. */
export type CreateUserEmailPayload = {
  __typename?: 'CreateUserEmailPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `UserEmail`. */
  user?: Maybe<User>;
  /** The `UserEmail` that was created by this mutation. */
  userEmail?: Maybe<UserEmail>;
  /** An edge for our `UserEmail`. May be used by Relay 1. */
  userEmailEdge?: Maybe<UserEmailsEdge>;
};


/** The output of our create `UserEmail` mutation. */
export type CreateUserEmailPayloadUserEmailEdgeArgs = {
  orderBy?: Maybe<Array<UserEmailsOrderBy>>;
};

export type CrmServerSessionPayload = {
  __typename?: 'CrmServerSessionPayload';
  accessToken?: Maybe<Scalars['String']>;
  expires?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
};


export type CustomerAddressNorthbeamType = {
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country_code?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

export type CustomerType = {
  __typename?: 'CustomerType';
  /** Reads and enables pagination through a set of `Plan`. */
  plansByCustomerType: PlansConnection;
  value: Scalars['String'];
};


export type CustomerTypePlansByCustomerTypeArgs = {
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  condition?: Maybe<PlanCondition>;
  filter?: Maybe<PlanFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<PlansOrderBy>>;
};

/**
 * A condition to be used against `CustomerType` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type CustomerTypeCondition = {
  /** Checks for equality with the object’s `value` field. */
  value?: Maybe<Scalars['String']>;
};

/** A filter to be used against `CustomerType` object types. All fields are combined with a logical ‘and.’ */
export type CustomerTypeFilter = {
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<CustomerTypeFilter>>;
  /** Negates the expression. */
  not?: Maybe<CustomerTypeFilter>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<CustomerTypeFilter>>;
  /** Filter by the object’s `value` field. */
  value?: Maybe<StringFilter>;
};

/** A connection to a list of `CustomerType` values. */
export type CustomerTypesConnection = {
  __typename?: 'CustomerTypesConnection';
  /** A list of edges which contains the `CustomerType` and cursor to aid in pagination. */
  edges: Array<CustomerTypesEdge>;
  /** A list of `CustomerType` objects. */
  nodes: Array<CustomerType>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CustomerType` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `CustomerType` edge in the connection. */
export type CustomerTypesEdge = {
  __typename?: 'CustomerTypesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CustomerType` at the end of the edge. */
  node: CustomerType;
};

/** Methods to use when ordering `CustomerType`. */
export enum CustomerTypesOrderBy {
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ValueAsc = 'VALUE_ASC',
  ValueDesc = 'VALUE_DESC'
}



/** A filter to be used against Datetime fields. All fields are combined with a logical ‘and.’ */
export type DatetimeFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<Scalars['Datetime']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<Scalars['Datetime']>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<Scalars['Datetime']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<Scalars['Datetime']>;
  /** Included in the specified list. */
  in?: Maybe<Array<Scalars['Datetime']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: Maybe<Scalars['Datetime']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<Scalars['Datetime']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<Scalars['Datetime']>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<Scalars['Datetime']>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<Scalars['Datetime']>>;
};

export type DealerLocation = {
  __typename?: 'DealerLocation';
  address: Scalars['String'];
  createdAt?: Maybe<Scalars['Datetime']>;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  lat: Scalars['Float'];
  long: Scalars['Float'];
  name: Scalars['String'];
  phone: Scalars['String'];
  website?: Maybe<Scalars['String']>;
};

/**
 * A condition to be used against `DealerLocation` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type DealerLocationCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `name` field. */
  name?: Maybe<Scalars['String']>;
};

/** A filter to be used against `DealerLocation` object types. All fields are combined with a logical ‘and.’ */
export type DealerLocationFilter = {
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<DealerLocationFilter>>;
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `name` field. */
  name?: Maybe<StringFilter>;
  /** Negates the expression. */
  not?: Maybe<DealerLocationFilter>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<DealerLocationFilter>>;
};

/** An input for mutations affecting `DealerLocation` */
export type DealerLocationInput = {
  address: Scalars['String'];
  createdAt?: Maybe<Scalars['Datetime']>;
  icon?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  lat: Scalars['Float'];
  long: Scalars['Float'];
  name: Scalars['String'];
  phone: Scalars['String'];
  website?: Maybe<Scalars['String']>;
};

/** Represents an update to a `DealerLocation`. Fields that are set will be updated. */
export type DealerLocationPatch = {
  address?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  icon?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  lat?: Maybe<Scalars['Float']>;
  long?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};

/** A connection to a list of `DealerLocation` values. */
export type DealerLocationsConnection = {
  __typename?: 'DealerLocationsConnection';
  /** A list of edges which contains the `DealerLocation` and cursor to aid in pagination. */
  edges: Array<DealerLocationsEdge>;
  /** A list of `DealerLocation` objects. */
  nodes: Array<DealerLocation>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `DealerLocation` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `DealerLocation` edge in the connection. */
export type DealerLocationsEdge = {
  __typename?: 'DealerLocationsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `DealerLocation` at the end of the edge. */
  node: DealerLocation;
};

/** Methods to use when ordering `DealerLocation`. */
export enum DealerLocationsOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** All input for the `deleteAccountConfirm` mutation. */
export type DeleteAccountConfirmInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
};

/** The output of our `deleteAccountConfirm` mutation. */
export type DeleteAccountConfirmPayload = {
  __typename?: 'DeleteAccountConfirmPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `UserEmail`. */
  user?: Maybe<User>;
  userEmail?: Maybe<UserEmail>;
  /** An edge for our `UserEmail`. May be used by Relay 1. */
  userEmailEdge?: Maybe<UserEmailsEdge>;
};


/** The output of our `deleteAccountConfirm` mutation. */
export type DeleteAccountConfirmPayloadUserEmailEdgeArgs = {
  orderBy?: Maybe<Array<UserEmailsOrderBy>>;
};

/** All input for the `deleteAdminAccount` mutation. */
export type DeleteAdminAccountInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** The output of our `deleteAdminAccount` mutation. */
export type DeleteAdminAccountPayload = {
  __typename?: 'DeleteAdminAccountPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  user?: Maybe<User>;
  /** An edge for our `User`. May be used by Relay 1. */
  userEdge?: Maybe<UsersEdge>;
};


/** The output of our `deleteAdminAccount` mutation. */
export type DeleteAdminAccountPayloadUserEdgeArgs = {
  orderBy?: Maybe<Array<UsersOrderBy>>;
};

/** All input for the `deleteBlogCategory` mutation. */
export type DeleteBlogCategoryInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** The output of our delete `BlogCategory` mutation. */
export type DeleteBlogCategoryPayload = {
  __typename?: 'DeleteBlogCategoryPayload';
  /** The `BlogCategory` that was deleted by this mutation. */
  blogCategory?: Maybe<BlogCategory>;
  /** An edge for our `BlogCategory`. May be used by Relay 1. */
  blogCategoryEdge?: Maybe<BlogCategoriesEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedBlogCategoryNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our delete `BlogCategory` mutation. */
export type DeleteBlogCategoryPayloadBlogCategoryEdgeArgs = {
  orderBy?: Maybe<Array<BlogCategoriesOrderBy>>;
};

/** All input for the `deleteChallenge` mutation. */
export type DeleteChallengeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** The output of our delete `Challenge` mutation. */
export type DeleteChallengePayload = {
  __typename?: 'DeleteChallengePayload';
  /** The `Challenge` that was deleted by this mutation. */
  challenge?: Maybe<Challenge>;
  /** An edge for our `Challenge`. May be used by Relay 1. */
  challengeEdge?: Maybe<ChallengesEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedChallengeNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our delete `Challenge` mutation. */
export type DeleteChallengePayloadChallengeEdgeArgs = {
  orderBy?: Maybe<Array<ChallengesOrderBy>>;
};

/** All input for the `deleteDealerLocation` mutation. */
export type DeleteDealerLocationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** The output of our delete `DealerLocation` mutation. */
export type DeleteDealerLocationPayload = {
  __typename?: 'DeleteDealerLocationPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `DealerLocation` that was deleted by this mutation. */
  dealerLocation?: Maybe<DealerLocation>;
  /** An edge for our `DealerLocation`. May be used by Relay 1. */
  dealerLocationEdge?: Maybe<DealerLocationsEdge>;
  deletedDealerLocationNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our delete `DealerLocation` mutation. */
export type DeleteDealerLocationPayloadDealerLocationEdgeArgs = {
  orderBy?: Maybe<Array<DealerLocationsOrderBy>>;
};

/** All input for the `deleteExperienceCategory` mutation. */
export type DeleteExperienceCategoryInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** The output of our delete `ExperienceCategory` mutation. */
export type DeleteExperienceCategoryPayload = {
  __typename?: 'DeleteExperienceCategoryPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedExperienceCategoryNodeId?: Maybe<Scalars['ID']>;
  /** The `ExperienceCategory` that was deleted by this mutation. */
  experienceCategory?: Maybe<ExperienceCategory>;
  /** An edge for our `ExperienceCategory`. May be used by Relay 1. */
  experienceCategoryEdge?: Maybe<ExperienceCategoriesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our delete `ExperienceCategory` mutation. */
export type DeleteExperienceCategoryPayloadExperienceCategoryEdgeArgs = {
  orderBy?: Maybe<Array<ExperienceCategoriesOrderBy>>;
};

/** All input for the `deleteExperienceDifficulty` mutation. */
export type DeleteExperienceDifficultyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

/** The output of our delete `ExperienceDifficulty` mutation. */
export type DeleteExperienceDifficultyPayload = {
  __typename?: 'DeleteExperienceDifficultyPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedExperienceDifficultyNodeId?: Maybe<Scalars['ID']>;
  /** The `ExperienceDifficulty` that was deleted by this mutation. */
  experienceDifficulty?: Maybe<ExperienceDifficulty>;
  /** An edge for our `ExperienceDifficulty`. May be used by Relay 1. */
  experienceDifficultyEdge?: Maybe<ExperienceDifficultiesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our delete `ExperienceDifficulty` mutation. */
export type DeleteExperienceDifficultyPayloadExperienceDifficultyEdgeArgs = {
  orderBy?: Maybe<Array<ExperienceDifficultiesOrderBy>>;
};

/** All input for the `deleteExperience` mutation. */
export type DeleteExperienceInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** The output of our delete `Experience` mutation. */
export type DeleteExperiencePayload = {
  __typename?: 'DeleteExperiencePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedExperienceNodeId?: Maybe<Scalars['ID']>;
  /** The `Experience` that was deleted by this mutation. */
  experience?: Maybe<Experience>;
  /** Reads a single `ExperienceCategory` that is related to this `Experience`. */
  experienceCategory?: Maybe<ExperienceCategory>;
  /** An edge for our `Experience`. May be used by Relay 1. */
  experienceEdge?: Maybe<ExperiencesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our delete `Experience` mutation. */
export type DeleteExperiencePayloadExperienceEdgeArgs = {
  orderBy?: Maybe<Array<ExperiencesOrderBy>>;
};

/** All input for the `deleteMachine` mutation. */
export type DeleteMachineInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  isCancelSubscription: Scalars['Boolean'];
  machineId: Scalars['String'];
};

/** The output of our `deleteMachine` mutation. */
export type DeleteMachinePayload = {
  __typename?: 'DeleteMachinePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  machine?: Maybe<Machine>;
  /** An edge for our `Machine`. May be used by Relay 1. */
  machineEdge?: Maybe<MachinesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our `deleteMachine` mutation. */
export type DeleteMachinePayloadMachineEdgeArgs = {
  orderBy?: Maybe<Array<MachinesOrderBy>>;
};

/** All input for the `deleteOrganization` mutation. */
export type DeleteOrganizationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  organizationId: Scalars['UUID'];
};

/** The output of our `deleteOrganization` mutation. */
export type DeleteOrganizationPayload = {
  __typename?: 'DeleteOrganizationPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `deletePlan` mutation. */
export type DeletePlanInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `Plan` mutation. */
export type DeletePlanPayload = {
  __typename?: 'DeletePlanPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `CustomerType` that is related to this `Plan`. */
  customerTypeByCustomerType?: Maybe<CustomerType>;
  deletedPlanNodeId?: Maybe<Scalars['ID']>;
  /** The `Plan` that was deleted by this mutation. */
  plan?: Maybe<Plan>;
  /** An edge for our `Plan`. May be used by Relay 1. */
  planEdge?: Maybe<PlansEdge>;
  /** Reads a single `PlanType` that is related to this `Plan`. */
  planTypeByType?: Maybe<PlanType>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our delete `Plan` mutation. */
export type DeletePlanPayloadPlanEdgeArgs = {
  orderBy?: Maybe<Array<PlansOrderBy>>;
};

/** All input for the `deletePost` mutation. */
export type DeletePostInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** The output of our delete `Post` mutation. */
export type DeletePostPayload = {
  __typename?: 'DeletePostPayload';
  /** Reads a single `BlogCategory` that is related to this `Post`. */
  blogCategory?: Maybe<BlogCategory>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedPostNodeId?: Maybe<Scalars['ID']>;
  /** The `Post` that was deleted by this mutation. */
  post?: Maybe<Post>;
  /** An edge for our `Post`. May be used by Relay 1. */
  postEdge?: Maybe<PostsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our delete `Post` mutation. */
export type DeletePostPayloadPostEdgeArgs = {
  orderBy?: Maybe<Array<PostsOrderBy>>;
};

/** All input for the `deletePress` mutation. */
export type DeletePressInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** The output of our delete `Press` mutation. */
export type DeletePressPayload = {
  __typename?: 'DeletePressPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedPressNodeId?: Maybe<Scalars['ID']>;
  /** The `Press` that was deleted by this mutation. */
  press?: Maybe<Press>;
  /** An edge for our `Press`. May be used by Relay 1. */
  pressEdge?: Maybe<PressesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our delete `Press` mutation. */
export type DeletePressPayloadPressEdgeArgs = {
  orderBy?: Maybe<Array<PressesOrderBy>>;
};

/** All input for the `deleteSupportCategory` mutation. */
export type DeleteSupportCategoryInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** The output of our delete `SupportCategory` mutation. */
export type DeleteSupportCategoryPayload = {
  __typename?: 'DeleteSupportCategoryPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedSupportCategoryNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `SupportCategory` that was deleted by this mutation. */
  supportCategory?: Maybe<SupportCategory>;
  /** An edge for our `SupportCategory`. May be used by Relay 1. */
  supportCategoryEdge?: Maybe<SupportCategoriesEdge>;
  /** Reads a single `User` that is related to this `SupportCategory`. */
  userByCreatedBy?: Maybe<User>;
};


/** The output of our delete `SupportCategory` mutation. */
export type DeleteSupportCategoryPayloadSupportCategoryEdgeArgs = {
  orderBy?: Maybe<Array<SupportCategoriesOrderBy>>;
};

/** All input for the `deleteSupport` mutation. */
export type DeleteSupportInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** The output of our delete `Support` mutation. */
export type DeleteSupportPayload = {
  __typename?: 'DeleteSupportPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedSupportNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `Support` that was deleted by this mutation. */
  support?: Maybe<Support>;
  /** Reads a single `SupportCategory` that is related to this `Support`. */
  supportCategory?: Maybe<SupportCategory>;
  /** An edge for our `Support`. May be used by Relay 1. */
  supportEdge?: Maybe<SupportsEdge>;
  /** Reads a single `User` that is related to this `Support`. */
  userByCreatedBy?: Maybe<User>;
};


/** The output of our delete `Support` mutation. */
export type DeleteSupportPayloadSupportEdgeArgs = {
  orderBy?: Maybe<Array<SupportsOrderBy>>;
};

/** All input for the `deleteUserAuthentication` mutation. */
export type DeleteUserAuthenticationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** The output of our delete `UserAuthentication` mutation. */
export type DeleteUserAuthenticationPayload = {
  __typename?: 'DeleteUserAuthenticationPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedUserAuthenticationNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `UserAuthentication`. */
  user?: Maybe<User>;
  /** The `UserAuthentication` that was deleted by this mutation. */
  userAuthentication?: Maybe<UserAuthentication>;
};

/** All input for the `deleteUserEmail` mutation. */
export type DeleteUserEmailInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** The output of our delete `UserEmail` mutation. */
export type DeleteUserEmailPayload = {
  __typename?: 'DeleteUserEmailPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedUserEmailNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `UserEmail`. */
  user?: Maybe<User>;
  /** The `UserEmail` that was deleted by this mutation. */
  userEmail?: Maybe<UserEmail>;
  /** An edge for our `UserEmail`. May be used by Relay 1. */
  userEmailEdge?: Maybe<UserEmailsEdge>;
};


/** The output of our delete `UserEmail` mutation. */
export type DeleteUserEmailPayloadUserEmailEdgeArgs = {
  orderBy?: Maybe<Array<UserEmailsOrderBy>>;
};

export type Experience = {
  __typename?: 'Experience';
  createdAt?: Maybe<Scalars['Datetime']>;
  description: Scalars['String'];
  difficulty?: Maybe<Scalars['String']>;
  /** Reads a single `ExperienceCategory` that is related to this `Experience`. */
  experienceCategory?: Maybe<ExperienceCategory>;
  experienceCategoryId: Scalars['UUID'];
  id: Scalars['UUID'];
  isPublic: Scalars['Boolean'];
  name: Scalars['String'];
  person: Scalars['String'];
  priority?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  thumb: Scalars['String'];
  time: Scalars['String'];
  updatedAt?: Maybe<Scalars['Datetime']>;
  userId?: Maybe<Scalars['UUID']>;
};

/** A connection to a list of `ExperienceCategory` values. */
export type ExperienceCategoriesConnection = {
  __typename?: 'ExperienceCategoriesConnection';
  /** A list of edges which contains the `ExperienceCategory` and cursor to aid in pagination. */
  edges: Array<ExperienceCategoriesEdge>;
  /** A list of `ExperienceCategory` objects. */
  nodes: Array<ExperienceCategory>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ExperienceCategory` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ExperienceCategory` edge in the connection. */
export type ExperienceCategoriesEdge = {
  __typename?: 'ExperienceCategoriesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ExperienceCategory` at the end of the edge. */
  node: ExperienceCategory;
};

/** Methods to use when ordering `ExperienceCategory`. */
export enum ExperienceCategoriesOrderBy {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  SlugAsc = 'SLUG_ASC',
  SlugDesc = 'SLUG_DESC'
}

export type ExperienceCategory = {
  __typename?: 'ExperienceCategory';
  createdAt?: Maybe<Scalars['Datetime']>;
  description: Scalars['String'];
  /** Reads and enables pagination through a set of `Experience`. */
  experiences: ExperiencesConnection;
  id: Scalars['UUID'];
  name: Scalars['String'];
  priority?: Maybe<Scalars['Int']>;
  slug: Scalars['String'];
  updatedAt?: Maybe<Scalars['Datetime']>;
};


export type ExperienceCategoryExperiencesArgs = {
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  condition?: Maybe<ExperienceCondition>;
  filter?: Maybe<ExperienceFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ExperiencesOrderBy>>;
};

/**
 * A condition to be used against `ExperienceCategory` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ExperienceCategoryCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `slug` field. */
  slug?: Maybe<Scalars['String']>;
};

/** A filter to be used against `ExperienceCategory` object types. All fields are combined with a logical ‘and.’ */
export type ExperienceCategoryFilter = {
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<ExperienceCategoryFilter>>;
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Negates the expression. */
  not?: Maybe<ExperienceCategoryFilter>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<ExperienceCategoryFilter>>;
  /** Filter by the object’s `slug` field. */
  slug?: Maybe<StringFilter>;
};

/** An input for mutations affecting `ExperienceCategory` */
export type ExperienceCategoryInput = {
  createdAt?: Maybe<Scalars['Datetime']>;
  description: Scalars['String'];
  id?: Maybe<Scalars['UUID']>;
  name: Scalars['String'];
  priority?: Maybe<Scalars['Int']>;
  slug: Scalars['String'];
  updatedAt?: Maybe<Scalars['Datetime']>;
};

/** Represents an update to a `ExperienceCategory`. Fields that are set will be updated. */
export type ExperienceCategoryPatch = {
  createdAt?: Maybe<Scalars['Datetime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
};

/**
 * A condition to be used against `Experience` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type ExperienceCondition = {
  /** Checks for equality with the object’s `experienceCategoryId` field. */
  experienceCategoryId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `isPublic` field. */
  isPublic?: Maybe<Scalars['Boolean']>;
};

/** A connection to a list of `ExperienceDifficulty` values. */
export type ExperienceDifficultiesConnection = {
  __typename?: 'ExperienceDifficultiesConnection';
  /** A list of edges which contains the `ExperienceDifficulty` and cursor to aid in pagination. */
  edges: Array<ExperienceDifficultiesEdge>;
  /** A list of `ExperienceDifficulty` objects. */
  nodes: Array<ExperienceDifficulty>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ExperienceDifficulty` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ExperienceDifficulty` edge in the connection. */
export type ExperienceDifficultiesEdge = {
  __typename?: 'ExperienceDifficultiesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ExperienceDifficulty` at the end of the edge. */
  node: ExperienceDifficulty;
};

/** Methods to use when ordering `ExperienceDifficulty`. */
export enum ExperienceDifficultiesOrderBy {
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ValueAsc = 'VALUE_ASC',
  ValueDesc = 'VALUE_DESC'
}

export type ExperienceDifficulty = {
  __typename?: 'ExperienceDifficulty';
  value: Scalars['String'];
};

/**
 * A condition to be used against `ExperienceDifficulty` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type ExperienceDifficultyCondition = {
  /** Checks for equality with the object’s `value` field. */
  value?: Maybe<Scalars['String']>;
};

/** A filter to be used against `ExperienceDifficulty` object types. All fields are combined with a logical ‘and.’ */
export type ExperienceDifficultyFilter = {
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<ExperienceDifficultyFilter>>;
  /** Negates the expression. */
  not?: Maybe<ExperienceDifficultyFilter>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<ExperienceDifficultyFilter>>;
  /** Filter by the object’s `value` field. */
  value?: Maybe<StringFilter>;
};

/** An input for mutations affecting `ExperienceDifficulty` */
export type ExperienceDifficultyInput = {
  value: Scalars['String'];
};

/** Represents an update to a `ExperienceDifficulty`. Fields that are set will be updated. */
export type ExperienceDifficultyPatch = {
  value?: Maybe<Scalars['String']>;
};

/** A filter to be used against `Experience` object types. All fields are combined with a logical ‘and.’ */
export type ExperienceFilter = {
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<ExperienceFilter>>;
  /** Filter by the object’s `experienceCategoryId` field. */
  experienceCategoryId?: Maybe<UuidFilter>;
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `isPublic` field. */
  isPublic?: Maybe<BooleanFilter>;
  /** Negates the expression. */
  not?: Maybe<ExperienceFilter>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<ExperienceFilter>>;
};

/** An input for mutations affecting `Experience` */
export type ExperienceInput = {
  createdAt?: Maybe<Scalars['Datetime']>;
  description: Scalars['String'];
  difficulty?: Maybe<Scalars['String']>;
  experienceCategoryId: Scalars['UUID'];
  id?: Maybe<Scalars['UUID']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  person: Scalars['String'];
  priority?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  thumb: Scalars['String'];
  time: Scalars['String'];
  updatedAt?: Maybe<Scalars['Datetime']>;
  userId?: Maybe<Scalars['UUID']>;
};

/** Represents an update to a `Experience`. Fields that are set will be updated. */
export type ExperiencePatch = {
  createdAt?: Maybe<Scalars['Datetime']>;
  description?: Maybe<Scalars['String']>;
  difficulty?: Maybe<Scalars['String']>;
  experienceCategoryId?: Maybe<Scalars['UUID']>;
  id?: Maybe<Scalars['UUID']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  person?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  thumb?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  userId?: Maybe<Scalars['UUID']>;
};

/** A connection to a list of `Experience` values. */
export type ExperiencesConnection = {
  __typename?: 'ExperiencesConnection';
  /** A list of edges which contains the `Experience` and cursor to aid in pagination. */
  edges: Array<ExperiencesEdge>;
  /** A list of `Experience` objects. */
  nodes: Array<Experience>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Experience` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Experience` edge in the connection. */
export type ExperiencesEdge = {
  __typename?: 'ExperiencesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Experience` at the end of the edge. */
  node: Experience;
};

/** Methods to use when ordering `Experience`. */
export enum ExperiencesOrderBy {
  ExperienceCategoryIdAsc = 'EXPERIENCE_CATEGORY_ID_ASC',
  ExperienceCategoryIdDesc = 'EXPERIENCE_CATEGORY_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IsPublicAsc = 'IS_PUBLIC_ASC',
  IsPublicDesc = 'IS_PUBLIC_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  PriorityAsc = 'PRIORITY_ASC',
  PriorityDesc = 'PRIORITY_DESC'
}

export type FitplanChooseSubscriptionInput = {
  id: Scalars['String'];
  planId: Scalars['Int'];
};

export type FitplanChooseSubscriptionPayload = {
  __typename?: 'FitplanChooseSubscriptionPayload';
  code?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

/** All input for the `forgotPassword` mutation. */
export type ForgotPasswordInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
};

/** The output of our `forgotPassword` mutation. */
export type ForgotPasswordPayload = {
  __typename?: 'ForgotPasswordPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

export type GameActiveMembershipInput = {
  appId?: Maybe<Scalars['Int']>;
  machineId?: Maybe<Scalars['String']>;
  membershipId: Scalars['String'];
};

export type GameAddMachineForMembershipInput = {
  appId: Scalars['Int'];
  machineId: Scalars['String'];
  membershipId: Scalars['String'];
};

export type GameDeleteMachineFromMembershipInput = {
  machineIds: Array<Scalars['String']>;
  membershipId: Scalars['String'];
};

export type GameProfile = {
  __typename?: 'GameProfile';
  dob?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Float']>;
  heightUnit?: Maybe<Scalars['Int']>;
  lastName?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['Int']>;
};

export type GameReplaceMachineFromMembershipInput = {
  appId: Scalars['Int'];
  membershipId: Scalars['String'];
  newMachineId: Scalars['String'];
  oldMachineId: Scalars['String'];
};

export type GameSessionPayload = {
  __typename?: 'GameSessionPayload';
  accessToken?: Maybe<Scalars['String']>;
  expires?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
};

export type GameUpdateUserPayload = {
  __typename?: 'GameUpdateUserPayload';
  code?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['String']>;
};

export type GameUpdateUserProfileInput = {
  avatarUrl?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['String']>;
  firstname?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Float']>;
  height_unit?: Maybe<Scalars['Int']>;
  lastname?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Float']>;
  weight_unit?: Maybe<Scalars['Int']>;
};

export type GameUser = {
  __typename?: 'GameUser';
  avatarUrl?: Maybe<Scalars['String']>;
  profile?: Maybe<GameProfile>;
  timezone?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

export type GetMembershipListByPlanIdInput = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  planId: Scalars['Int'];
  search?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

export type GetOrCreateUserInput = {
  email: Scalars['String'];
};

export type GetPlansInput = {
  filter?: Maybe<Scalars['JSON']>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
};

export type GetProductRatingsFields = {
  __typename?: 'GetProductRatingsFields';
  badge?: Maybe<Scalars['String']>;
  badgeqna?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
  countQuestions?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['Float']>;
};

export type GetProductRatingsInput = {
  productIds: Array<Maybe<Scalars['Int']>>;
};

export type GetPublicReviewsData = {
  __typename?: 'GetPublicReviewsData';
  author?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  countryIso?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['Int']>;
  location?: Maybe<Scalars['String']>;
  reviewDate?: Maybe<Scalars['String']>;
  reviewMessage?: Maybe<Scalars['String']>;
  reviewRating?: Maybe<Scalars['Int']>;
  reviewTitle?: Maybe<Scalars['String']>;
  reviewType?: Maybe<Scalars['Int']>;
  reviewUserPhotos?: Maybe<Scalars['String']>;
};

export type GetPublicReviewsInput = {
  isWithPhotos?: Maybe<Scalars['Boolean']>;
  page?: Maybe<Scalars['Int']>;
  sortReviews?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Int']>;
};

export type GetPublicReviewsPayload = {
  __typename?: 'GetPublicReviewsPayload';
  data?: Maybe<Array<Maybe<GetPublicReviewsData>>>;
  lang?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  rating?: Maybe<Scalars['Float']>;
  ratingAll?: Maybe<Scalars['Float']>;
  shop?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
  totalAll?: Maybe<Scalars['Int']>;
  totalAllWithNPS?: Maybe<Scalars['Int']>;
  translations?: Maybe<Scalars['JSON']>;
};

/** All input for the `getStripeFailedMembershipOfMachine` mutation. */
export type GetStripeFailedMembershipOfMachineInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  machineId: Scalars['String'];
};

/** The output of our `getStripeFailedMembershipOfMachine` mutation. */
export type GetStripeFailedMembershipOfMachinePayload = {
  __typename?: 'GetStripeFailedMembershipOfMachinePayload';
  avironSubscriptions?: Maybe<Array<AvironSubscription>>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

export type GetSubscriptionsByUserInput = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
};

export type IdTokenPayload = {
  __typename?: 'IdTokenPayload';
  idToken?: Maybe<Scalars['String']>;
};

/** A filter to be used against Int fields. All fields are combined with a logical ‘and.’ */
export type IntFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<Scalars['Int']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<Scalars['Int']>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<Scalars['Int']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<Scalars['Int']>;
  /** Included in the specified list. */
  in?: Maybe<Array<Scalars['Int']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: Maybe<Scalars['Int']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<Scalars['Int']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<Scalars['Int']>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<Scalars['Int']>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<Scalars['Int']>>;
};

/** All input for the `inviteToOrganization` mutation. */
export type InviteToOrganizationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  organizationId: Scalars['UUID'];
  username?: Maybe<Scalars['String']>;
};

/** The output of our `inviteToOrganization` mutation. */
export type InviteToOrganizationPayload = {
  __typename?: 'InviteToOrganizationPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


export type LeaveBetaProgramInput = {
  reason: Scalars['String'];
  userId: Scalars['String'];
};

export type LeaveBetaProgramPayload = {
  __typename?: 'LeaveBetaProgramPayload';
  code?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
};

export type LoginInput = {
  password: Scalars['String'];
  username: Scalars['String'];
};

export type LoginPayload = {
  __typename?: 'LoginPayload';
  user: User;
};

export type LogoutPayload = {
  __typename?: 'LogoutPayload';
  success?: Maybe<Scalars['Boolean']>;
};

export type Machine = {
  __typename?: 'Machine';
  /** Reads and enables pagination through a set of `AvironSubscription`. */
  avironSubscriptions: AvironSubscriptionsConnection;
  createdAt: Scalars['Datetime'];
  deleteAt?: Maybe<Scalars['Datetime']>;
  machineId: Scalars['String'];
  /**
   * Reads and enables pagination through a set of `MachineLastActivity`.
   * @deprecated Please use machineLastActivity instead
   */
  machineLastActivities: MachineLastActivitiesConnection;
  /** Reads a single `MachineLastActivity` that is related to this `Machine`. */
  machineLastActivity?: Maybe<MachineLastActivity>;
  machineType?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  serialNumber: Scalars['String'];
  status?: Maybe<Scalars['String']>;
  updatedAt: Scalars['Datetime'];
};


export type MachineAvironSubscriptionsArgs = {
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  condition?: Maybe<AvironSubscriptionCondition>;
  filter?: Maybe<AvironSubscriptionFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<AvironSubscriptionsOrderBy>>;
};


export type MachineMachineLastActivitiesArgs = {
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  condition?: Maybe<MachineLastActivityCondition>;
  filter?: Maybe<MachineLastActivityFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<MachineLastActivitiesOrderBy>>;
};

/** A condition to be used against `Machine` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type MachineCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `machineId` field. */
  machineId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `serialNumber` field. */
  serialNumber?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `status` field. */
  status?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
};

/** A filter to be used against `Machine` object types. All fields are combined with a logical ‘and.’ */
export type MachineFilter = {
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<MachineFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `machineId` field. */
  machineId?: Maybe<StringFilter>;
  /** Negates the expression. */
  not?: Maybe<MachineFilter>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<MachineFilter>>;
  /** Filter by the object’s `serialNumber` field. */
  serialNumber?: Maybe<StringFilter>;
  /** Filter by the object’s `status` field. */
  status?: Maybe<StringFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
};

/** An input for mutations affecting `Machine` */
export type MachineInput = {
  machineId: Scalars['String'];
  machineType?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  serialNumber: Scalars['String'];
};

/** A connection to a list of `MachineLastActivity` values. */
export type MachineLastActivitiesConnection = {
  __typename?: 'MachineLastActivitiesConnection';
  /** A list of edges which contains the `MachineLastActivity` and cursor to aid in pagination. */
  edges: Array<MachineLastActivitiesEdge>;
  /** A list of `MachineLastActivity` objects. */
  nodes: Array<MachineLastActivity>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `MachineLastActivity` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `MachineLastActivity` edge in the connection. */
export type MachineLastActivitiesEdge = {
  __typename?: 'MachineLastActivitiesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `MachineLastActivity` at the end of the edge. */
  node: MachineLastActivity;
};

/** Methods to use when ordering `MachineLastActivity`. */
export enum MachineLastActivitiesOrderBy {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  MachineIdAsc = 'MACHINE_ID_ASC',
  MachineIdDesc = 'MACHINE_ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type MachineLastActivity = {
  __typename?: 'MachineLastActivity';
  action: Scalars['String'];
  createdAt?: Maybe<Scalars['Datetime']>;
  id: Scalars['UUID'];
  /** Reads a single `Machine` that is related to this `MachineLastActivity`. */
  machine?: Maybe<Machine>;
  machineId: Scalars['String'];
  metadata?: Maybe<Scalars['JSON']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
};

/**
 * A condition to be used against `MachineLastActivity` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type MachineLastActivityCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `machineId` field. */
  machineId?: Maybe<Scalars['String']>;
};

/** A filter to be used against `MachineLastActivity` object types. All fields are combined with a logical ‘and.’ */
export type MachineLastActivityFilter = {
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<MachineLastActivityFilter>>;
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `machineId` field. */
  machineId?: Maybe<StringFilter>;
  /** Negates the expression. */
  not?: Maybe<MachineLastActivityFilter>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<MachineLastActivityFilter>>;
};

/** Represents an update to a `Machine`. Fields that are set will be updated. */
export type MachinePatch = {
  deleteAt?: Maybe<Scalars['Datetime']>;
  machineId?: Maybe<Scalars['String']>;
  machineType?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type MachineType = {
  __typename?: 'MachineType';
  value: Scalars['String'];
};

/**
 * A condition to be used against `MachineType` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type MachineTypeCondition = {
  /** Checks for equality with the object’s `value` field. */
  value?: Maybe<Scalars['String']>;
};

/** A filter to be used against `MachineType` object types. All fields are combined with a logical ‘and.’ */
export type MachineTypeFilter = {
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<MachineTypeFilter>>;
  /** Negates the expression. */
  not?: Maybe<MachineTypeFilter>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<MachineTypeFilter>>;
  /** Filter by the object’s `value` field. */
  value?: Maybe<StringFilter>;
};

/** A connection to a list of `MachineType` values. */
export type MachineTypesConnection = {
  __typename?: 'MachineTypesConnection';
  /** A list of edges which contains the `MachineType` and cursor to aid in pagination. */
  edges: Array<MachineTypesEdge>;
  /** A list of `MachineType` objects. */
  nodes: Array<MachineType>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `MachineType` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `MachineType` edge in the connection. */
export type MachineTypesEdge = {
  __typename?: 'MachineTypesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `MachineType` at the end of the edge. */
  node: MachineType;
};

/** Methods to use when ordering `MachineType`. */
export enum MachineTypesOrderBy {
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ValueAsc = 'VALUE_ASC',
  ValueDesc = 'VALUE_DESC'
}

/** A connection to a list of `Machine` values. */
export type MachinesConnection = {
  __typename?: 'MachinesConnection';
  /** A list of edges which contains the `Machine` and cursor to aid in pagination. */
  edges: Array<MachinesEdge>;
  /** A list of `Machine` objects. */
  nodes: Array<Machine>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Machine` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Machine` edge in the connection. */
export type MachinesEdge = {
  __typename?: 'MachinesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Machine` at the end of the edge. */
  node: Machine;
};

/** Methods to use when ordering `Machine`. */
export enum MachinesOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  MachineIdAsc = 'MACHINE_ID_ASC',
  MachineIdDesc = 'MACHINE_ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  SerialNumberAsc = 'SERIAL_NUMBER_ASC',
  SerialNumberDesc = 'SERIAL_NUMBER_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

/** All input for the `makeEmailPrimary` mutation. */
export type MakeEmailPrimaryInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  emailId: Scalars['UUID'];
};

/** The output of our `makeEmailPrimary` mutation. */
export type MakeEmailPrimaryPayload = {
  __typename?: 'MakeEmailPrimaryPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `UserEmail`. */
  user?: Maybe<User>;
  userEmail?: Maybe<UserEmail>;
  /** An edge for our `UserEmail`. May be used by Relay 1. */
  userEmailEdge?: Maybe<UserEmailsEdge>;
};


/** The output of our `makeEmailPrimary` mutation. */
export type MakeEmailPrimaryPayloadUserEmailEdgeArgs = {
  orderBy?: Maybe<Array<UserEmailsOrderBy>>;
};

/** The root mutation type which contains root level fields which mutate data. */
export type Mutation = {
  __typename?: 'Mutation';
  /**
   * If someone invited you by your email address then you must include the code
   * that was emailed to you, otherwise you may accept the invitation directly
   * using the UUID. If successful, you will be a member of the organization.
   */
  acceptInvitationToOrganization?: Maybe<AcceptInvitationToOrganizationPayload>;
  activateUserSubscription?: Maybe<ActivateUserSubscriptionPayload>;
  activeUserSubscription?: Maybe<Scalars['JSON']>;
  addContact?: Maybe<ContactFields>;
  addLabel?: Maybe<LabelFields>;
  addParticipant?: Maybe<AddParticipantPayload>;
  addWorkoutToUser?: Maybe<Scalars['JSON']>;
  adminAcceptRejectBetaRequest?: Maybe<Scalars['JSON']>;
  adminAddOrUpdateGameCategories?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  adminAddOrUpdateGameInfo?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  adminAddOrUpdateGameOptions?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  adminAddOrUpdateGameTypes?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  adminAuthorization?: Maybe<AdminAuthorizationPayload>;
  adminCancelUserSubscription?: Maybe<Scalars['JSON']>;
  adminChangeUserPassword?: Maybe<AdminChangeUserPasswordPayload>;
  adminCreateAchievement?: Maybe<Scalars['JSON']>;
  adminCreateBetaUser?: Maybe<Scalars['JSON']>;
  adminCreateMonthlyChallenge?: Maybe<Scalars['JSON']>;
  adminCreateProduct?: Maybe<Scalars['JSON']>;
  adminCreateProductCategory?: Maybe<Scalars['JSON']>;
  adminDeleteAchievement?: Maybe<Scalars['JSON']>;
  adminDeleteBetaUser?: Maybe<Scalars['JSON']>;
  adminDeleteMonthlyChallenge?: Maybe<Scalars['JSON']>;
  adminDeleteProduct?: Maybe<Scalars['JSON']>;
  adminDeleteProductCategory?: Maybe<Scalars['JSON']>;
  adminExportCancelSubscription?: Maybe<Scalars['JSON']>;
  adminExportMachine?: Maybe<Scalars['JSON']>;
  adminExportMembership?: Maybe<Scalars['JSON']>;
  adminExportSubscription?: Maybe<Scalars['JSON']>;
  adminExportUser?: Maybe<Scalars['JSON']>;
  adminLogin?: Maybe<LoginPayload>;
  adminUpdateAchievement?: Maybe<Scalars['JSON']>;
  adminUpdateGameSetting?: Maybe<Scalars['JSON']>;
  adminUpdateMonthlyChallenge?: Maybe<Scalars['JSON']>;
  adminUpdateProduct?: Maybe<Scalars['JSON']>;
  adminUpdateProductCategory?: Maybe<Scalars['JSON']>;
  adminUpdateUserAccount?: Maybe<GameUpdateUserPayload>;
  attachUserPaymentMethod?: Maybe<AttachUserPaymentMethodPayload>;
  cancelOfferSubscription?: Maybe<PausePaymentSubscriptionPayload>;
  cancelPaymentIntent?: Maybe<Scalars['JSON']>;
  cancelUserSubscription?: Maybe<Scalars['JSON']>;
  capturePaymentIntent?: Maybe<Scalars['JSON']>;
  /** Enter your old password and a new password to change your password. */
  changePassword?: Maybe<ChangePasswordPayload>;
  changePwdlessPassword?: Maybe<ChangePwdlessPasswordPayload>;
  changeUserEmail?: Maybe<ChangeUserEmailPayload>;
  changeUserSubscriptionPlan?: Maybe<Scalars['JSON']>;
  checkEmailExists?: Maybe<CheckEmailExistsPayload>;
  checkGuestUser?: Maybe<CheckGuestUserPayload>;
  checkMachinesActivated?: Maybe<CheckMachinesActivatedPayload>;
  checkPasswordlessAccount?: Maybe<CheckPasswordlessAccountPayload>;
  checkStripeSubscriptionExists?: Maybe<CheckStripeSubscriptionExistsPayload>;
  checkSubscriptionFinanceFinish?: Maybe<Scalars['Boolean']>;
  checkUpgradeSubscription?: Maybe<Scalars['JSON']>;
  checkUserHaveFinanceSubscription?: Maybe<Scalars['Boolean']>;
  checkUserVerified?: Maybe<CheckUserVerifiedPayload>;
  compareUserPriority?: Maybe<CompareUserPriorityPayload>;
  /**
   * If you're certain you want to delete your account, use
   * `requestAccountDeletion` to request an account deletion token, and then supply
   * the token through this mutation to complete account deletion.
   */
  confirmAccountDeletion?: Maybe<ConfirmAccountDeletionPayload>;
  countMachinesActivated?: Maybe<CountMachinesActivatedPayload>;
  createAdminAccount?: Maybe<CreateAdminAccountPayload>;
  /** Creates a single `AdminHistory`. */
  createAdminHistory?: Maybe<CreateAdminHistoryPayload>;
  createAndChargeLaterPayment?: Maybe<Scalars['JSON']>;
  /** Creates a single `AvironSubscription`. */
  createAvironSubscription?: Maybe<CreateAvironSubscriptionPayload>;
  /** Creates a single `BetaRequest`. */
  createBetaRequest?: Maybe<CreateBetaRequestPayload>;
  /** Creates a single `BlogCategory`. */
  createBlogCategory?: Maybe<CreateBlogCategoryPayload>;
  /** Creates a single `Challenge`. */
  createChallenge?: Maybe<CreateChallengePayload>;
  /** Creates a single `DealerLocation`. */
  createDealerLocation?: Maybe<CreateDealerLocationPayload>;
  /** Creates a single `Experience`. */
  createExperience?: Maybe<CreateExperiencePayload>;
  /** Creates a single `ExperienceCategory`. */
  createExperienceCategory?: Maybe<CreateExperienceCategoryPayload>;
  /** Creates a single `ExperienceDifficulty`. */
  createExperienceDifficulty?: Maybe<CreateExperienceDifficultyPayload>;
  /** Creates a single `Machine`. */
  createMachine?: Maybe<CreateMachinePayload>;
  createManualSubscription?: Maybe<CreateManualSubscriptionPayload>;
  createManualSubscriptions?: Maybe<CreateManualSubscriptionsPayload>;
  /**
   * An `Organization` is a great way of sharing access to resources between
   * multiple users without compromising security. When you create an organization
   * you will have the 'owner' and 'billing contact' roles. You may invite other
   * users and redistribute these roles.
   */
  createOrganization?: Maybe<CreateOrganizationPayload>;
  createPaymentIntent?: Maybe<Scalars['JSON']>;
  /** Creates a single `Plan`. */
  createPlan?: Maybe<CreatePlanPayload>;
  /** Creates a single `Post`. */
  createPost?: Maybe<CreatePostPayload>;
  /** Creates a single `Press`. */
  createPress?: Maybe<CreatePressPayload>;
  createSession?: Maybe<CreateSessionPayload>;
  createStripeCustomer?: Maybe<Scalars['JSON']>;
  createStripeSubscription?: Maybe<Scalars['JSON']>;
  createStripeSubscriptions?: Maybe<Scalars['JSON']>;
  /** Creates a single `Support`. */
  createSupport?: Maybe<CreateSupportPayload>;
  /** Creates a single `SupportCategory`. */
  createSupportCategory?: Maybe<CreateSupportCategoryPayload>;
  createUploadPhotoUrl?: Maybe<CreateUploadUrlPayload>;
  /** Get a signed URL for uploading files. It will expire in 5 minutes. */
  createUploadUrl?: Maybe<CreateUploadUrlPayload>;
  /** Creates a single `UserEmail`. */
  createUserEmail?: Maybe<CreateUserEmailPayload>;
  createUserSubscription?: Maybe<AvironSubscriptionsPayload>;
  deleteAccountConfirm?: Maybe<DeleteAccountConfirmPayload>;
  deleteAdminAccount?: Maybe<DeleteAdminAccountPayload>;
  /** Deletes a single `BlogCategory` using a unique key. */
  deleteBlogCategory?: Maybe<DeleteBlogCategoryPayload>;
  /** Deletes a single `Challenge` using a unique key. */
  deleteChallenge?: Maybe<DeleteChallengePayload>;
  /** Deletes a single `DealerLocation` using a unique key. */
  deleteDealerLocation?: Maybe<DeleteDealerLocationPayload>;
  /** Deletes a single `Experience` using a unique key. */
  deleteExperience?: Maybe<DeleteExperiencePayload>;
  /** Deletes a single `ExperienceCategory` using a unique key. */
  deleteExperienceCategory?: Maybe<DeleteExperienceCategoryPayload>;
  /** Deletes a single `ExperienceDifficulty` using a unique key. */
  deleteExperienceDifficulty?: Maybe<DeleteExperienceDifficultyPayload>;
  deleteLabel?: Maybe<LabelFields>;
  deleteMachine?: Maybe<DeleteMachinePayload>;
  /** Only the 'owner' may delete an organization. This operation cannot be undone, so be sure that it is what you intend. */
  deleteOrganization?: Maybe<DeleteOrganizationPayload>;
  /** Deletes a single `Plan` using a unique key. */
  deletePlan?: Maybe<DeletePlanPayload>;
  /** Deletes a single `Post` using a unique key. */
  deletePost?: Maybe<DeletePostPayload>;
  /** Deletes a single `Press` using a unique key. */
  deletePress?: Maybe<DeletePressPayload>;
  /** Deletes a single `Support` using a unique key. */
  deleteSupport?: Maybe<DeleteSupportPayload>;
  /** Deletes a single `SupportCategory` using a unique key. */
  deleteSupportCategory?: Maybe<DeleteSupportCategoryPayload>;
  /** Deletes a single `UserAuthentication` using a unique key. */
  deleteUserAuthentication?: Maybe<DeleteUserAuthenticationPayload>;
  /** Deletes a single `UserEmail` using a unique key. */
  deleteUserEmail?: Maybe<DeleteUserEmailPayload>;
  fitplanChooseSubscription?: Maybe<FitplanChooseSubscriptionPayload>;
  /**
   * If you've forgotten your password, give us one of your email addresses and
   * we'll send you a reset token. Note this only works if you have added an email address!
   */
  forgotPassword?: Maybe<ForgotPasswordPayload>;
  gameActiveMembership?: Maybe<Scalars['JSON']>;
  gameAddMachineForMembership?: Maybe<Scalars['JSON']>;
  gameDeleteMachineFromMembership?: Maybe<Scalars['JSON']>;
  gameReplaceMachineFromMembership?: Maybe<Scalars['JSON']>;
  gameUpdateUserProfile?: Maybe<GameUpdateUserPayload>;
  getOrCreateUser?: Maybe<Scalars['JSON']>;
  getStripeFailedMembershipOfMachine?: Maybe<GetStripeFailedMembershipOfMachinePayload>;
  /**
   * You may invite a user to your organization either by their username (only for
   * verified users) or by their email. If you opt to invite by email then an email
   * will be sent to this person containing a code that they need to accept the
   * invitation. If the person doesn't already have an account they will be
   * instructed to create one; their account need not have the email address that
   * you invited listed as the secret code is confirmation enough.
   */
  inviteToOrganization?: Maybe<InviteToOrganizationPayload>;
  leaveBetaProgram?: Maybe<LeaveBetaProgramPayload>;
  /** Use this mutation to log in to your account; this login uses sessions so you do not need to take further action. */
  login?: Maybe<LoginPayload>;
  /** Use this mutation to logout from your account. Don't forget to clear the client state! */
  logout?: Maybe<LogoutPayload>;
  /**
   * Your primary email is where we'll notify of account events; other emails may
   * be used for discovery or login. Use this when you're changing your email address.
   */
  makeEmailPrimary?: Maybe<MakeEmailPrimaryPayload>;
  oauth?: Maybe<OAuthPayload>;
  oauthPasswordless?: Maybe<OAuthPayload>;
  passwordlessAuthenticate?: Maybe<PasswordlessAuthPayload>;
  passwordlessLogin?: Maybe<PasswordlessLoginPayload>;
  pausePaymentSubscription?: Maybe<PausePaymentSubscriptionPayload>;
  reactiveUserSubscription?: Maybe<Scalars['JSON']>;
  refresh?: Maybe<RefreshPayload>;
  /** Use this mutation to create an account on our system. This may only be used if you are logged out. */
  register?: Maybe<RegisterPayload>;
  /** The owner of an `Organization` may remove an `OrganizationMember` with this mutation. */
  removeFromOrganization?: Maybe<RemoveFromOrganizationPayload>;
  removePassword?: Maybe<RemovePasswordPayload>;
  /** Begin the account deletion flow by requesting the confirmation email */
  requestAccountDeletion?: Maybe<RequestAccountDeletionPayload>;
  /**
   * If you didn't receive the verification code for this email, we can resend it.
   * We silently cap the rate of resends on the backend, so calls to this function
   * may not result in another email being sent if it has been called recently.
   */
  resendEmailVerificationCode?: Maybe<ResendEmailVerificationCodePayload>;
  /**
   * After triggering forgotPassword, you'll be sent a reset token. Combine this
   * with your user ID and a new password to reset your password.
   */
  resetPassword?: Maybe<ResetPasswordPayload>;
  resumePaymentSubscription?: Maybe<ResumePaymentSubscriptionPayload>;
  retryChargeSubscription?: Maybe<RetryChargeSubscriptionPayload>;
  retryChargeSubscriptionV2?: Maybe<RetryChargeSubscriptionPayload>;
  revoke?: Maybe<RevokePayload>;
  sendConfirmNewEmail?: Maybe<Scalars['JSON']>;
  setupIntent?: Maybe<Scalars['JSON']>;
  setupIntentExpressPayment?: Maybe<Scalars['JSON']>;
  setupIntentKlarna?: Maybe<Scalars['JSON']>;
  stopCancelOfferSubscription?: Maybe<ResumePaymentSubscriptionPayload>;
  stripeUpdateCustomerDefaultPaymentMethod?: Maybe<Scalars['JSON']>;
  submitContact?: Maybe<SubmitContactPayload>;
  trackingAddToCart?: Maybe<TrackingSuccessPayload>;
  trackingGA?: Maybe<TrackingSuccessPayload>;
  trackingInitiateCheckout?: Maybe<TrackingSuccessPayload>;
  trackingKlaviyoFormSubmit?: Maybe<TrackingSuccessPayload>;
  trackingPurchased?: Maybe<TrackingSuccessPayload>;
  trackingPurchasedNorthbeam?: Maybe<TrackingSuccessPayload>;
  trackingPurchasedV2?: Maybe<TrackingSuccessPayload>;
  trackingSubscriptionAction?: Maybe<Scalars['Boolean']>;
  trackingViewContent?: Maybe<TrackingSuccessPayload>;
  /** The owner of an `Organization` may use this mutation to make any organization member the billing contact. */
  transferOrganizationBillingContact?: Maybe<TransferOrganizationBillingContactPayload>;
  /**
   * The owner of an `Organization` may use this mutation to transfer ownership to
   * a different organization member. Take care, if you assign ownership to someone
   * who cannot or will not access their account then you may need to contact
   * support to have ownership reassigned.
   */
  transferOrganizationOwnership?: Maybe<TransferOrganizationOwnershipPayload>;
  triggerReferral?: Maybe<TriggerReferralPayload>;
  updateAdminRoles?: Maybe<UpdateAdminRolesPayload>;
  /** Updates a single `AvironSubscription` using a unique key and a patch. */
  updateAvironSubscription?: Maybe<UpdateAvironSubscriptionPayload>;
  /** Updates a single `BetaRequest` using a unique key and a patch. */
  updateBetaRequest?: Maybe<UpdateBetaRequestPayload>;
  /** Updates a single `BlogCategory` using a unique key and a patch. */
  updateBlogCategory?: Maybe<UpdateBlogCategoryPayload>;
  /** Updates a single `Challenge` using a unique key and a patch. */
  updateChallenge?: Maybe<UpdateChallengePayload>;
  updateContactInfo?: Maybe<ContactFields>;
  /** Updates a single `DealerLocation` using a unique key and a patch. */
  updateDealerLocation?: Maybe<UpdateDealerLocationPayload>;
  /** Updates a single `Experience` using a unique key and a patch. */
  updateExperience?: Maybe<UpdateExperiencePayload>;
  /** Updates a single `ExperienceCategory` using a unique key and a patch. */
  updateExperienceCategory?: Maybe<UpdateExperienceCategoryPayload>;
  /** Updates a single `ExperienceDifficulty` using a unique key and a patch. */
  updateExperienceDifficulty?: Maybe<UpdateExperienceDifficultyPayload>;
  updateIntentExpressPayment?: Maybe<Scalars['JSON']>;
  updateLabel?: Maybe<LabelFields>;
  /** Updates a single `Machine` using a unique key and a patch. */
  updateMachine?: Maybe<UpdateMachinePayload>;
  updateManualSubscription?: Maybe<AvironSubscriptionsPayload>;
  /** Updates a single `Organization` using a unique key and a patch. */
  updateOrganization?: Maybe<UpdateOrganizationPayload>;
  updateParticipant?: Maybe<UpdateParticipantPayload>;
  /** Updates a single `Plan` using a unique key and a patch. */
  updatePlan?: Maybe<UpdatePlanPayload>;
  /** Updates a single `Post` using a unique key and a patch. */
  updatePost?: Maybe<UpdatePostPayload>;
  /** Updates a single `Press` using a unique key and a patch. */
  updatePress?: Maybe<UpdatePressPayload>;
  updateSubscriptionUser?: Maybe<UpdateSubscriptionUserPayload>;
  /** Updates a single `Support` using a unique key and a patch. */
  updateSupport?: Maybe<UpdateSupportPayload>;
  /** Updates a single `SupportCategory` using a unique key and a patch. */
  updateSupportCategory?: Maybe<UpdateSupportCategoryPayload>;
  /** Updates a single `User` using a unique key and a patch. */
  updateUser?: Maybe<UpdateUserPayload>;
  updateUserAccount?: Maybe<User>;
  updateUserCustom?: Maybe<Scalars['JSON']>;
  /** Updates a single `UserEmail` using a unique key and a patch. */
  updateUserEmail?: Maybe<UpdateUserEmailPayload>;
  updateUserWithStripeCustomer?: Maybe<UpdateUserWithStripeCustomerPayload>;
  upgradeSubscription?: Maybe<Scalars['JSON']>;
  userChangeEmail?: Maybe<Scalars['JSON']>;
  /**
   * Once you have received a verification token for your email, you may call this
   * mutation with that token to make your email verified.
   */
  verifyEmail?: Maybe<VerifyEmailPayload>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationAcceptInvitationToOrganizationArgs = {
  input: AcceptInvitationToOrganizationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationActivateUserSubscriptionArgs = {
  input: ActivateUserSubscriptionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationActiveUserSubscriptionArgs = {
  input: ActiveUserSubscriptionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationAddContactArgs = {
  input?: Maybe<AddContactInput>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationAddLabelArgs = {
  input?: Maybe<AddLabelInput>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationAddParticipantArgs = {
  input?: Maybe<AddParticipantInput>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationAddWorkoutToUserArgs = {
  input?: Maybe<Scalars['JSON']>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationAdminAcceptRejectBetaRequestArgs = {
  input?: Maybe<Scalars['JSON']>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationAdminAddOrUpdateGameCategoriesArgs = {
  input?: Maybe<Array<Maybe<Scalars['JSON']>>>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationAdminAddOrUpdateGameInfoArgs = {
  input?: Maybe<Array<Maybe<Scalars['JSON']>>>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationAdminAddOrUpdateGameOptionsArgs = {
  input?: Maybe<Array<Maybe<Scalars['JSON']>>>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationAdminAddOrUpdateGameTypesArgs = {
  input?: Maybe<Array<Maybe<Scalars['JSON']>>>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationAdminAuthorizationArgs = {
  input: AdminAuthorizationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationAdminCancelUserSubscriptionArgs = {
  input: AdminCancelUserSubscriptionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationAdminChangeUserPasswordArgs = {
  input: AdminChangeUserPasswordInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationAdminCreateAchievementArgs = {
  input?: Maybe<Scalars['JSON']>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationAdminCreateBetaUserArgs = {
  input?: Maybe<Scalars['JSON']>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationAdminCreateMonthlyChallengeArgs = {
  input?: Maybe<Scalars['JSON']>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationAdminCreateProductArgs = {
  input?: Maybe<Scalars['JSON']>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationAdminCreateProductCategoryArgs = {
  input?: Maybe<Scalars['JSON']>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationAdminDeleteAchievementArgs = {
  input?: Maybe<Scalars['JSON']>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationAdminDeleteBetaUserArgs = {
  input?: Maybe<Scalars['JSON']>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationAdminDeleteMonthlyChallengeArgs = {
  input?: Maybe<Scalars['JSON']>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationAdminDeleteProductArgs = {
  input?: Maybe<Scalars['JSON']>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationAdminDeleteProductCategoryArgs = {
  input?: Maybe<Scalars['JSON']>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationAdminExportCancelSubscriptionArgs = {
  input?: Maybe<Scalars['JSON']>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationAdminExportMachineArgs = {
  input?: Maybe<Scalars['JSON']>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationAdminExportMembershipArgs = {
  input?: Maybe<Scalars['JSON']>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationAdminExportSubscriptionArgs = {
  input?: Maybe<Scalars['JSON']>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationAdminExportUserArgs = {
  input?: Maybe<Scalars['JSON']>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationAdminLoginArgs = {
  input: LoginInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationAdminUpdateAchievementArgs = {
  input?: Maybe<Scalars['JSON']>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationAdminUpdateGameSettingArgs = {
  input?: Maybe<Scalars['JSON']>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationAdminUpdateMonthlyChallengeArgs = {
  input?: Maybe<Scalars['JSON']>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationAdminUpdateProductArgs = {
  input?: Maybe<Scalars['JSON']>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationAdminUpdateProductCategoryArgs = {
  input?: Maybe<Scalars['JSON']>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationAdminUpdateUserAccountArgs = {
  input?: Maybe<AdminUpdateUserAccount>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationAttachUserPaymentMethodArgs = {
  input: AttachUserPaymentMethodInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCancelOfferSubscriptionArgs = {
  input?: Maybe<Scalars['JSON']>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCancelPaymentIntentArgs = {
  input?: Maybe<CancelPaymentIntentInput>;
  params?: Maybe<CancelPaymentIntentParams>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCancelUserSubscriptionArgs = {
  input: CancelUserSubscriptionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCapturePaymentIntentArgs = {
  input?: Maybe<CapturePaymentIntentInput>;
  params?: Maybe<CapturePaymentIntentParams>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationChangePasswordArgs = {
  input: ChangePasswordInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationChangePwdlessPasswordArgs = {
  input: ChangePwdlessPasswordInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationChangeUserEmailArgs = {
  input: ChangeUserEmailInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationChangeUserSubscriptionPlanArgs = {
  input: ChangeUserSubscriptionPlanInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCheckEmailExistsArgs = {
  input: CheckEmailExistsInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCheckGuestUserArgs = {
  input: CheckGuestUserInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCheckMachinesActivatedArgs = {
  input: CheckMachinesActivatedInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCheckPasswordlessAccountArgs = {
  input: CheckPasswordlessAccountInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCheckStripeSubscriptionExistsArgs = {
  input: CheckStripeSubscriptionExistsInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCheckSubscriptionFinanceFinishArgs = {
  input?: Maybe<CheckSubscriptionFinanceFinishInput>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCheckUpgradeSubscriptionArgs = {
  input: CheckUpgradeSubscriptionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCheckUserHaveFinanceSubscriptionArgs = {
  input?: Maybe<CheckUserHaveFinanceSubscriptionInput>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCheckUserVerifiedArgs = {
  input: CheckUserVerifiedInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCompareUserPriorityArgs = {
  input: CompareUserPriorityInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationConfirmAccountDeletionArgs = {
  input: ConfirmAccountDeletionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCountMachinesActivatedArgs = {
  input: CountMachinesActivatedInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateAdminAccountArgs = {
  input: CreateAdminAccountInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateAdminHistoryArgs = {
  input: CreateAdminHistoryInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateAndChargeLaterPaymentArgs = {
  input?: Maybe<CreateAndChargeLaterPaymentInput>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateAvironSubscriptionArgs = {
  input: CreateAvironSubscriptionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateBetaRequestArgs = {
  input: CreateBetaRequestInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateBlogCategoryArgs = {
  input: CreateBlogCategoryInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateChallengeArgs = {
  input: CreateChallengeInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateDealerLocationArgs = {
  input: CreateDealerLocationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateExperienceArgs = {
  input: CreateExperienceInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateExperienceCategoryArgs = {
  input: CreateExperienceCategoryInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateExperienceDifficultyArgs = {
  input: CreateExperienceDifficultyInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateMachineArgs = {
  input: CreateMachineInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateManualSubscriptionArgs = {
  input: CreateManualSubscriptionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateManualSubscriptionsArgs = {
  input: CreateManualSubscriptionsInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateOrganizationArgs = {
  input: CreateOrganizationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreatePaymentIntentArgs = {
  input?: Maybe<CreatePaymentIntentInput>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreatePlanArgs = {
  input: CreatePlanInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreatePostArgs = {
  input: CreatePostInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreatePressArgs = {
  input: CreatePressInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateSessionArgs = {
  input?: Maybe<CreateSessionInput>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateStripeCustomerArgs = {
  input?: Maybe<CreateCustomerInput>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateStripeSubscriptionArgs = {
  input?: Maybe<CreateSubscriptionInput>;
  paymentMethod?: Maybe<Scalars['JSON']>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateStripeSubscriptionsArgs = {
  input: CreateStripeSubscriptionsInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateSupportArgs = {
  input: CreateSupportInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateSupportCategoryArgs = {
  input: CreateSupportCategoryInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateUploadPhotoUrlArgs = {
  input: CreateUploadPhotoUrlInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateUploadUrlArgs = {
  input: CreateUploadUrlInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateUserEmailArgs = {
  input: CreateUserEmailInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateUserSubscriptionArgs = {
  input?: Maybe<AvironSubscriptionsInput>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAccountConfirmArgs = {
  input: DeleteAccountConfirmInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAdminAccountArgs = {
  input: DeleteAdminAccountInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteBlogCategoryArgs = {
  input: DeleteBlogCategoryInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteChallengeArgs = {
  input: DeleteChallengeInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteDealerLocationArgs = {
  input: DeleteDealerLocationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteExperienceArgs = {
  input: DeleteExperienceInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteExperienceCategoryArgs = {
  input: DeleteExperienceCategoryInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteExperienceDifficultyArgs = {
  input: DeleteExperienceDifficultyInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLabelArgs = {
  input?: Maybe<DeleteLabelInput>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteMachineArgs = {
  input: DeleteMachineInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteOrganizationArgs = {
  input: DeleteOrganizationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeletePlanArgs = {
  input: DeletePlanInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeletePostArgs = {
  input: DeletePostInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeletePressArgs = {
  input: DeletePressInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteSupportArgs = {
  input: DeleteSupportInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteSupportCategoryArgs = {
  input: DeleteSupportCategoryInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteUserAuthenticationArgs = {
  input: DeleteUserAuthenticationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteUserEmailArgs = {
  input: DeleteUserEmailInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationFitplanChooseSubscriptionArgs = {
  input?: Maybe<FitplanChooseSubscriptionInput>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationForgotPasswordArgs = {
  input: ForgotPasswordInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationGameActiveMembershipArgs = {
  input?: Maybe<GameActiveMembershipInput>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationGameAddMachineForMembershipArgs = {
  input?: Maybe<GameAddMachineForMembershipInput>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationGameDeleteMachineFromMembershipArgs = {
  input?: Maybe<GameDeleteMachineFromMembershipInput>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationGameReplaceMachineFromMembershipArgs = {
  input?: Maybe<GameReplaceMachineFromMembershipInput>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationGameUpdateUserProfileArgs = {
  input?: Maybe<GameUpdateUserProfileInput>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationGetOrCreateUserArgs = {
  input: GetOrCreateUserInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationGetStripeFailedMembershipOfMachineArgs = {
  input: GetStripeFailedMembershipOfMachineInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationInviteToOrganizationArgs = {
  input: InviteToOrganizationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationLeaveBetaProgramArgs = {
  input?: Maybe<LeaveBetaProgramInput>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationLoginArgs = {
  input: LoginInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationMakeEmailPrimaryArgs = {
  input: MakeEmailPrimaryInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationOauthArgs = {
  input: OAuthInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationOauthPasswordlessArgs = {
  input: OAuthPasswordlessInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationPasswordlessAuthenticateArgs = {
  input?: Maybe<PasswordlessAuthInput>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationPasswordlessLoginArgs = {
  input: PasswordlessLoginInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationPausePaymentSubscriptionArgs = {
  input: PausePaymentSubscriptionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationReactiveUserSubscriptionArgs = {
  input: ReactiveUserSubscriptionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationRefreshArgs = {
  input: RefreshInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationRegisterArgs = {
  input: RegisterInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationRemoveFromOrganizationArgs = {
  input: RemoveFromOrganizationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationRequestAccountDeletionArgs = {
  input: RequestAccountDeletionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationResendEmailVerificationCodeArgs = {
  input: ResendEmailVerificationCodeInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationResetPasswordArgs = {
  input: ResetPasswordInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationResumePaymentSubscriptionArgs = {
  input: ResumePaymentSubscriptionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationRetryChargeSubscriptionArgs = {
  input: RetryChargeSubscriptionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationRetryChargeSubscriptionV2Args = {
  input: RetryChargeSubscriptionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationRevokeArgs = {
  input: RevokeInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationSendConfirmNewEmailArgs = {
  input?: Maybe<Scalars['JSON']>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationSetupIntentArgs = {
  input?: Maybe<SetupIntentInput>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationSetupIntentExpressPaymentArgs = {
  input?: Maybe<SetupIntentExpressPaymentInput>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationSetupIntentKlarnaArgs = {
  input?: Maybe<SetupIntentKlarnaInput>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationStopCancelOfferSubscriptionArgs = {
  input?: Maybe<Scalars['JSON']>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationStripeUpdateCustomerDefaultPaymentMethodArgs = {
  input?: Maybe<StripeUpdateCustomerDefaultPaymentMethodInput>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationSubmitContactArgs = {
  input: SubmitContactInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationTrackingAddToCartArgs = {
  input?: Maybe<TrackingAddToCartInput>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationTrackingGaArgs = {
  input?: Maybe<TrackingGaInput>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationTrackingInitiateCheckoutArgs = {
  input?: Maybe<TrackingInitiateCheckoutInput>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationTrackingKlaviyoFormSubmitArgs = {
  input?: Maybe<TrackingKlaviyoFormSubmitInput>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationTrackingPurchasedArgs = {
  input?: Maybe<TrackingPurchasedInput>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationTrackingPurchasedNorthbeamArgs = {
  input?: Maybe<TrackingPurchasedNorthbeamInput>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationTrackingPurchasedV2Args = {
  input?: Maybe<TrackingPurchasedInput>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationTrackingSubscriptionActionArgs = {
  input?: Maybe<TrackingSubscriptionActionInput>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationTrackingViewContentArgs = {
  input?: Maybe<TrackingViewContentInput>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationTransferOrganizationBillingContactArgs = {
  input: TransferOrganizationBillingContactInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationTransferOrganizationOwnershipArgs = {
  input: TransferOrganizationOwnershipInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationTriggerReferralArgs = {
  input?: Maybe<TriggerReferralInput>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAdminRolesArgs = {
  input: UpdateAdminRolesInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAvironSubscriptionArgs = {
  input: UpdateAvironSubscriptionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateBetaRequestArgs = {
  input: UpdateBetaRequestInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateBlogCategoryArgs = {
  input: UpdateBlogCategoryInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateChallengeArgs = {
  input: UpdateChallengeInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateContactInfoArgs = {
  input?: Maybe<UpdateContactInput>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateDealerLocationArgs = {
  input: UpdateDealerLocationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateExperienceArgs = {
  input: UpdateExperienceInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateExperienceCategoryArgs = {
  input: UpdateExperienceCategoryInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateExperienceDifficultyArgs = {
  input: UpdateExperienceDifficultyInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateIntentExpressPaymentArgs = {
  input?: Maybe<UpdateIntentExpressPaymentInput>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLabelArgs = {
  input?: Maybe<UpdateLabelInput>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateMachineArgs = {
  input: UpdateMachineInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateManualSubscriptionArgs = {
  input: UpdateManualSubscriptionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateOrganizationArgs = {
  input: UpdateOrganizationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateParticipantArgs = {
  input?: Maybe<UpdateParticipantInput>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePlanArgs = {
  input: UpdatePlanInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePostArgs = {
  input: UpdatePostInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePressArgs = {
  input: UpdatePressInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSubscriptionUserArgs = {
  input: UpdateSubscriptionUserInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSupportArgs = {
  input: UpdateSupportInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSupportCategoryArgs = {
  input: UpdateSupportCategoryInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserAccountArgs = {
  input?: Maybe<UpdateUserAccount>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserCustomArgs = {
  input?: Maybe<UpdateUserCustomInput>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserEmailArgs = {
  input: UpdateUserEmailInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpgradeSubscriptionArgs = {
  input: ChangeUserSubscriptionPlanInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUserChangeEmailArgs = {
  input?: Maybe<UserChangeUserInput>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationVerifyEmailArgs = {
  input: VerifyEmailInput;
};

export type OAuthInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type OAuthPasswordlessInput = {
  token: Scalars['String'];
  userEmailId: Scalars['String'];
};

export type OAuthPayload = {
  __typename?: 'OAuthPayload';
  expires?: Maybe<Scalars['Date']>;
  refreshToken?: Maybe<Scalars['String']>;
  token: Scalars['String'];
  type?: Maybe<Scalars['String']>;
};


export type Organization = {
  __typename?: 'Organization';
  createdAt: Scalars['Datetime'];
  currentUserIsBillingContact?: Maybe<Scalars['Boolean']>;
  currentUserIsOwner?: Maybe<Scalars['Boolean']>;
  id: Scalars['UUID'];
  name: Scalars['String'];
  /** Reads and enables pagination through a set of `OrganizationInvitation`. */
  organizationInvitations: OrganizationInvitationsConnection;
  /** Reads and enables pagination through a set of `OrganizationMembership`. */
  organizationMemberships: OrganizationMembershipsConnection;
  slug: Scalars['String'];
};


export type OrganizationOrganizationInvitationsArgs = {
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  condition?: Maybe<OrganizationInvitationCondition>;
  filter?: Maybe<OrganizationInvitationFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<OrganizationInvitationsOrderBy>>;
};


export type OrganizationOrganizationMembershipsArgs = {
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  condition?: Maybe<OrganizationMembershipCondition>;
  filter?: Maybe<OrganizationMembershipFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<OrganizationMembershipsOrderBy>>;
};

/**
 * A condition to be used against `Organization` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type OrganizationCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `slug` field. */
  slug?: Maybe<Scalars['String']>;
};

/** A filter to be used against `Organization` object types. All fields are combined with a logical ‘and.’ */
export type OrganizationFilter = {
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<OrganizationFilter>>;
  /** Filter by the object’s `currentUserIsBillingContact` field. */
  currentUserIsBillingContact?: Maybe<BooleanFilter>;
  /** Filter by the object’s `currentUserIsOwner` field. */
  currentUserIsOwner?: Maybe<BooleanFilter>;
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Negates the expression. */
  not?: Maybe<OrganizationFilter>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<OrganizationFilter>>;
  /** Filter by the object’s `slug` field. */
  slug?: Maybe<StringFilter>;
};

export type OrganizationInvitation = {
  __typename?: 'OrganizationInvitation';
  code?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  /** Reads a single `Organization` that is related to this `OrganizationInvitation`. */
  organization?: Maybe<Organization>;
  organizationId: Scalars['UUID'];
  /** Reads a single `User` that is related to this `OrganizationInvitation`. */
  user?: Maybe<User>;
  userId?: Maybe<Scalars['UUID']>;
};

/**
 * A condition to be used against `OrganizationInvitation` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type OrganizationInvitationCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `organizationId` field. */
  organizationId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: Maybe<Scalars['UUID']>;
};

/** A filter to be used against `OrganizationInvitation` object types. All fields are combined with a logical ‘and.’ */
export type OrganizationInvitationFilter = {
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<OrganizationInvitationFilter>>;
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Negates the expression. */
  not?: Maybe<OrganizationInvitationFilter>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<OrganizationInvitationFilter>>;
  /** Filter by the object’s `organizationId` field. */
  organizationId?: Maybe<UuidFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: Maybe<UuidFilter>;
};

/** A connection to a list of `OrganizationInvitation` values. */
export type OrganizationInvitationsConnection = {
  __typename?: 'OrganizationInvitationsConnection';
  /** A list of edges which contains the `OrganizationInvitation` and cursor to aid in pagination. */
  edges: Array<OrganizationInvitationsEdge>;
  /** A list of `OrganizationInvitation` objects. */
  nodes: Array<OrganizationInvitation>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `OrganizationInvitation` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `OrganizationInvitation` edge in the connection. */
export type OrganizationInvitationsEdge = {
  __typename?: 'OrganizationInvitationsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `OrganizationInvitation` at the end of the edge. */
  node: OrganizationInvitation;
};

/** Methods to use when ordering `OrganizationInvitation`. */
export enum OrganizationInvitationsOrderBy {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  OrganizationIdAsc = 'ORGANIZATION_ID_ASC',
  OrganizationIdDesc = 'ORGANIZATION_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC'
}

export type OrganizationMembership = {
  __typename?: 'OrganizationMembership';
  createdAt: Scalars['Datetime'];
  id: Scalars['UUID'];
  isBillingContact: Scalars['Boolean'];
  isOwner: Scalars['Boolean'];
  /** Reads a single `Organization` that is related to this `OrganizationMembership`. */
  organization?: Maybe<Organization>;
  organizationId: Scalars['UUID'];
  /** Reads a single `User` that is related to this `OrganizationMembership`. */
  user?: Maybe<User>;
  userId: Scalars['UUID'];
};

/**
 * A condition to be used against `OrganizationMembership` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type OrganizationMembershipCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `organizationId` field. */
  organizationId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: Maybe<Scalars['UUID']>;
};

/** A filter to be used against `OrganizationMembership` object types. All fields are combined with a logical ‘and.’ */
export type OrganizationMembershipFilter = {
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<OrganizationMembershipFilter>>;
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Negates the expression. */
  not?: Maybe<OrganizationMembershipFilter>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<OrganizationMembershipFilter>>;
  /** Filter by the object’s `organizationId` field. */
  organizationId?: Maybe<UuidFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: Maybe<UuidFilter>;
};

/** A connection to a list of `OrganizationMembership` values. */
export type OrganizationMembershipsConnection = {
  __typename?: 'OrganizationMembershipsConnection';
  /** A list of edges which contains the `OrganizationMembership` and cursor to aid in pagination. */
  edges: Array<OrganizationMembershipsEdge>;
  /** A list of `OrganizationMembership` objects. */
  nodes: Array<OrganizationMembership>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `OrganizationMembership` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `OrganizationMembership` edge in the connection. */
export type OrganizationMembershipsEdge = {
  __typename?: 'OrganizationMembershipsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `OrganizationMembership` at the end of the edge. */
  node: OrganizationMembership;
};

/** Methods to use when ordering `OrganizationMembership`. */
export enum OrganizationMembershipsOrderBy {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  MemberNameAsc = 'MEMBER_NAME_ASC',
  MemberNameDesc = 'MEMBER_NAME_DESC',
  Natural = 'NATURAL',
  OrganizationIdAsc = 'ORGANIZATION_ID_ASC',
  OrganizationIdDesc = 'ORGANIZATION_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC'
}

/** Represents an update to a `Organization`. Fields that are set will be updated. */
export type OrganizationPatch = {
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

/** A connection to a list of `Organization` values. */
export type OrganizationsConnection = {
  __typename?: 'OrganizationsConnection';
  /** A list of edges which contains the `Organization` and cursor to aid in pagination. */
  edges: Array<OrganizationsEdge>;
  /** A list of `Organization` objects. */
  nodes: Array<Organization>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Organization` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Organization` edge in the connection. */
export type OrganizationsEdge = {
  __typename?: 'OrganizationsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Organization` at the end of the edge. */
  node: Organization;
};

/** Methods to use when ordering `Organization`. */
export enum OrganizationsOrderBy {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  SlugAsc = 'SLUG_ASC',
  SlugDesc = 'SLUG_DESC'
}

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['Cursor']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['Cursor']>;
};

export type PasswordlessAuthInput = {
  token: Scalars['String'];
  userEmailId: Scalars['UUID'];
};

export type PasswordlessAuthPayload = {
  __typename?: 'PasswordlessAuthPayload';
  user: User;
};

/** All input for the `passwordlessLogin` mutation. */
export type PasswordlessLoginInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
};

/** The output of our `passwordlessLogin` mutation. */
export type PasswordlessLoginPayload = {
  __typename?: 'PasswordlessLoginPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `UserEmail`. */
  user?: Maybe<User>;
  userEmail?: Maybe<UserEmail>;
  /** An edge for our `UserEmail`. May be used by Relay 1. */
  userEmailEdge?: Maybe<UserEmailsEdge>;
};


/** The output of our `passwordlessLogin` mutation. */
export type PasswordlessLoginPayloadUserEmailEdgeArgs = {
  orderBy?: Maybe<Array<UserEmailsOrderBy>>;
};

export type PausePaymentSubscriptionInput = {
  behavior: Scalars['String'];
  id: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  resumesAt?: Maybe<Scalars['Date']>;
  source?: Maybe<Scalars['String']>;
};

export type PausePaymentSubscriptionPayload = {
  __typename?: 'PausePaymentSubscriptionPayload';
  code?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export type Plan = {
  __typename?: 'Plan';
  amount?: Maybe<Scalars['BigFloat']>;
  appId?: Maybe<Scalars['Int']>;
  appIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** Reads and enables pagination through a set of `AvironSubscription`. */
  avironSubscriptions: AvironSubscriptionsConnection;
  createdAt?: Maybe<Scalars['Datetime']>;
  currency?: Maybe<Scalars['String']>;
  customerType?: Maybe<Scalars['String']>;
  /** Reads a single `CustomerType` that is related to this `Plan`. */
  customerTypeByCustomerType?: Maybe<CustomerType>;
  enable?: Maybe<Scalars['Boolean']>;
  gamePlanId?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  interval?: Maybe<Scalars['String']>;
  intervalCount?: Maybe<Scalars['Int']>;
  isRecurring?: Maybe<Scalars['Boolean']>;
  maxMachines?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  planDowngrade?: Maybe<Scalars['Int']>;
  planSequence?: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** Reads a single `PlanType` that is related to this `Plan`. */
  planTypeByType?: Maybe<PlanType>;
  planUpgrade?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['String']>;
  stripeCaPriceId?: Maybe<Scalars['String']>;
  stripePriceId?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
};


export type PlanAvironSubscriptionsArgs = {
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  condition?: Maybe<AvironSubscriptionCondition>;
  filter?: Maybe<AvironSubscriptionFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<AvironSubscriptionsOrderBy>>;
};

/** A condition to be used against `Plan` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type PlanCondition = {
  /** Checks for equality with the object’s `appId` field. */
  appId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `customerType` field. */
  customerType?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `interval` field. */
  interval?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `name` field. */
  name?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `productId` field. */
  productId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `type` field. */
  type?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
};

/** A filter to be used against `Plan` object types. All fields are combined with a logical ‘and.’ */
export type PlanFilter = {
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<PlanFilter>>;
  /** Filter by the object’s `appId` field. */
  appId?: Maybe<IntFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `customerType` field. */
  customerType?: Maybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `interval` field. */
  interval?: Maybe<StringFilter>;
  /** Filter by the object’s `name` field. */
  name?: Maybe<StringFilter>;
  /** Negates the expression. */
  not?: Maybe<PlanFilter>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<PlanFilter>>;
  /** Filter by the object’s `productId` field. */
  productId?: Maybe<StringFilter>;
  /** Filter by the object’s `type` field. */
  type?: Maybe<StringFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
};

/** An input for mutations affecting `Plan` */
export type PlanInput = {
  amount?: Maybe<Scalars['BigFloat']>;
  appId?: Maybe<Scalars['Int']>;
  appIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['Datetime']>;
  currency?: Maybe<Scalars['String']>;
  customerType?: Maybe<Scalars['String']>;
  enable?: Maybe<Scalars['Boolean']>;
  gamePlanId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  interval?: Maybe<Scalars['String']>;
  intervalCount?: Maybe<Scalars['Int']>;
  isRecurring?: Maybe<Scalars['Boolean']>;
  maxMachines?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  planDowngrade?: Maybe<Scalars['Int']>;
  planSequence?: Maybe<Array<Maybe<Scalars['Int']>>>;
  planUpgrade?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['String']>;
  stripeCaPriceId?: Maybe<Scalars['String']>;
  stripePriceId?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
};

/** Represents an update to a `Plan`. Fields that are set will be updated. */
export type PlanPatch = {
  amount?: Maybe<Scalars['BigFloat']>;
  appId?: Maybe<Scalars['Int']>;
  appIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  createdAt?: Maybe<Scalars['Datetime']>;
  currency?: Maybe<Scalars['String']>;
  customerType?: Maybe<Scalars['String']>;
  enable?: Maybe<Scalars['Boolean']>;
  gamePlanId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  interval?: Maybe<Scalars['String']>;
  intervalCount?: Maybe<Scalars['Int']>;
  isRecurring?: Maybe<Scalars['Boolean']>;
  maxMachines?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  planDowngrade?: Maybe<Scalars['Int']>;
  planSequence?: Maybe<Array<Maybe<Scalars['Int']>>>;
  planUpgrade?: Maybe<Scalars['Int']>;
  productId?: Maybe<Scalars['String']>;
  stripeCaPriceId?: Maybe<Scalars['String']>;
  stripePriceId?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
};

export type PlanType = {
  __typename?: 'PlanType';
  /** Reads and enables pagination through a set of `Plan`. */
  plansByType: PlansConnection;
  value: Scalars['String'];
};


export type PlanTypePlansByTypeArgs = {
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  condition?: Maybe<PlanCondition>;
  filter?: Maybe<PlanFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<PlansOrderBy>>;
};

/**
 * A condition to be used against `PlanType` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type PlanTypeCondition = {
  /** Checks for equality with the object’s `value` field. */
  value?: Maybe<Scalars['String']>;
};

/** A filter to be used against `PlanType` object types. All fields are combined with a logical ‘and.’ */
export type PlanTypeFilter = {
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<PlanTypeFilter>>;
  /** Negates the expression. */
  not?: Maybe<PlanTypeFilter>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<PlanTypeFilter>>;
  /** Filter by the object’s `value` field. */
  value?: Maybe<StringFilter>;
};

/** A connection to a list of `PlanType` values. */
export type PlanTypesConnection = {
  __typename?: 'PlanTypesConnection';
  /** A list of edges which contains the `PlanType` and cursor to aid in pagination. */
  edges: Array<PlanTypesEdge>;
  /** A list of `PlanType` objects. */
  nodes: Array<PlanType>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PlanType` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `PlanType` edge in the connection. */
export type PlanTypesEdge = {
  __typename?: 'PlanTypesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `PlanType` at the end of the edge. */
  node: PlanType;
};

/** Methods to use when ordering `PlanType`. */
export enum PlanTypesOrderBy {
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ValueAsc = 'VALUE_ASC',
  ValueDesc = 'VALUE_DESC'
}

/** A connection to a list of `Plan` values. */
export type PlansConnection = {
  __typename?: 'PlansConnection';
  /** A list of edges which contains the `Plan` and cursor to aid in pagination. */
  edges: Array<PlansEdge>;
  /** A list of `Plan` objects. */
  nodes: Array<Plan>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Plan` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Plan` edge in the connection. */
export type PlansEdge = {
  __typename?: 'PlansEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Plan` at the end of the edge. */
  node: Plan;
};

/** Methods to use when ordering `Plan`. */
export enum PlansOrderBy {
  AppIdAsc = 'APP_ID_ASC',
  AppIdDesc = 'APP_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CustomerTypeAsc = 'CUSTOMER_TYPE_ASC',
  CustomerTypeDesc = 'CUSTOMER_TYPE_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IntervalAsc = 'INTERVAL_ASC',
  IntervalDesc = 'INTERVAL_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ProductIdAsc = 'PRODUCT_ID_ASC',
  ProductIdDesc = 'PRODUCT_ID_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

export type PolicyFields = {
  __typename?: 'PolicyFields';
  key: Scalars['String'];
  policy: Scalars['String'];
  x_goog_algorithm: Scalars['String'];
  x_goog_credential: Scalars['String'];
  x_goog_date: Scalars['String'];
  x_goog_signature: Scalars['String'];
};

export type Post = {
  __typename?: 'Post';
  /** Reads a single `BlogCategory` that is related to this `Post`. */
  blogCategory?: Maybe<BlogCategory>;
  blogCategoryId: Scalars['UUID'];
  content: Scalars['String'];
  createdAt?: Maybe<Scalars['Datetime']>;
  id: Scalars['UUID'];
  isPin: Scalars['Boolean'];
  isPublic: Scalars['Boolean'];
  metaDescription: Scalars['String'];
  metaTitle: Scalars['String'];
  shortDescription: Scalars['String'];
  slug: Scalars['String'];
  thumb: Scalars['String'];
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['Datetime']>;
  userId: Scalars['UUID'];
  viewCount: Scalars['Int'];
};

/** A condition to be used against `Post` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type PostCondition = {
  /** Checks for equality with the object’s `blogCategoryId` field. */
  blogCategoryId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `isPin` field. */
  isPin?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `isPublic` field. */
  isPublic?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `slug` field. */
  slug?: Maybe<Scalars['String']>;
};

/** A filter to be used against `Post` object types. All fields are combined with a logical ‘and.’ */
export type PostFilter = {
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<PostFilter>>;
  /** Filter by the object’s `blogCategoryId` field. */
  blogCategoryId?: Maybe<UuidFilter>;
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `isPin` field. */
  isPin?: Maybe<BooleanFilter>;
  /** Filter by the object’s `isPublic` field. */
  isPublic?: Maybe<BooleanFilter>;
  /** Negates the expression. */
  not?: Maybe<PostFilter>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<PostFilter>>;
  /** Filter by the object’s `slug` field. */
  slug?: Maybe<StringFilter>;
};

/** An input for mutations affecting `Post` */
export type PostInput = {
  blogCategoryId: Scalars['UUID'];
  content: Scalars['String'];
  createdAt?: Maybe<Scalars['Datetime']>;
  id?: Maybe<Scalars['UUID']>;
  isPin?: Maybe<Scalars['Boolean']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  metaDescription: Scalars['String'];
  metaTitle: Scalars['String'];
  shortDescription: Scalars['String'];
  slug: Scalars['String'];
  thumb: Scalars['String'];
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['Datetime']>;
  userId: Scalars['UUID'];
  viewCount?: Maybe<Scalars['Int']>;
};

/** Represents an update to a `Post`. Fields that are set will be updated. */
export type PostPatch = {
  blogCategoryId?: Maybe<Scalars['UUID']>;
  content?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  id?: Maybe<Scalars['UUID']>;
  isPin?: Maybe<Scalars['Boolean']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  metaDescription?: Maybe<Scalars['String']>;
  metaTitle?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  thumb?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  userId?: Maybe<Scalars['UUID']>;
  viewCount?: Maybe<Scalars['Int']>;
};

/** A connection to a list of `Post` values. */
export type PostsConnection = {
  __typename?: 'PostsConnection';
  /** A list of edges which contains the `Post` and cursor to aid in pagination. */
  edges: Array<PostsEdge>;
  /** A list of `Post` objects. */
  nodes: Array<Post>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Post` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Post` edge in the connection. */
export type PostsEdge = {
  __typename?: 'PostsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Post` at the end of the edge. */
  node: Post;
};

/** Methods to use when ordering `Post`. */
export enum PostsOrderBy {
  BlogCategoryIdAsc = 'BLOG_CATEGORY_ID_ASC',
  BlogCategoryIdDesc = 'BLOG_CATEGORY_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IsPinAsc = 'IS_PIN_ASC',
  IsPinDesc = 'IS_PIN_DESC',
  IsPublicAsc = 'IS_PUBLIC_ASC',
  IsPublicDesc = 'IS_PUBLIC_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  SlugAsc = 'SLUG_ASC',
  SlugDesc = 'SLUG_DESC'
}

export type Press = {
  __typename?: 'Press';
  createdAt: Scalars['Datetime'];
  description: Scalars['String'];
  id: Scalars['UUID'];
  link: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  title: Scalars['String'];
  updatedAt: Scalars['Datetime'];
};

/** A condition to be used against `Press` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type PressCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `order` field. */
  order?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `title` field. */
  title?: Maybe<Scalars['String']>;
};

/** A filter to be used against `Press` object types. All fields are combined with a logical ‘and.’ */
export type PressFilter = {
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<PressFilter>>;
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Negates the expression. */
  not?: Maybe<PressFilter>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<PressFilter>>;
  /** Filter by the object’s `order` field. */
  order?: Maybe<IntFilter>;
  /** Filter by the object’s `title` field. */
  title?: Maybe<StringFilter>;
};

/** An input for mutations affecting `Press` */
export type PressInput = {
  createdAt?: Maybe<Scalars['Datetime']>;
  description: Scalars['String'];
  id?: Maybe<Scalars['UUID']>;
  link: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['Datetime']>;
};

/** Represents an update to a `Press`. Fields that are set will be updated. */
export type PressPatch = {
  createdAt?: Maybe<Scalars['Datetime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  link?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
};

/** A connection to a list of `Press` values. */
export type PressesConnection = {
  __typename?: 'PressesConnection';
  /** A list of edges which contains the `Press` and cursor to aid in pagination. */
  edges: Array<PressesEdge>;
  /** A list of `Press` objects. */
  nodes: Array<Press>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Press` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Press` edge in the connection. */
export type PressesEdge = {
  __typename?: 'PressesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Press` at the end of the edge. */
  node: Press;
};

/** Methods to use when ordering `Press`. */
export enum PressesOrderBy {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  OrderAsc = 'ORDER_ASC',
  OrderDesc = 'ORDER_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  TitleAsc = 'TITLE_ASC',
  TitleDesc = 'TITLE_DESC'
}

export type ProductItemNorthbeamType = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Int']>;
};

export type ProductParameters = {
  category: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  price: Scalars['Int'];
  quantity: Scalars['Int'];
};

/** The root query type which gives access points into the data universe. */
export type Query = {
  __typename?: 'Query';
  adminAchievement?: Maybe<Scalars['JSON']>;
  adminAchievements?: Maybe<Scalars['JSON']>;
  adminAchievementTypes?: Maybe<Scalars['JSON']>;
  adminBetaUsers?: Maybe<Scalars['JSON']>;
  adminExportMembership?: Maybe<Scalars['JSON']>;
  adminGameApps?: Maybe<Scalars['JSON']>;
  adminGameCurrencies?: Maybe<Scalars['JSON']>;
  adminGameProductCategoryGroups?: Maybe<Scalars['JSON']>;
  adminGameSetting?: Maybe<Scalars['JSON']>;
  adminGameSettings?: Maybe<Scalars['JSON']>;
  adminGameTypes?: Maybe<Scalars['JSON']>;
  adminGeneralMembershipChart?: Maybe<Scalars['JSON']>;
  adminGeneralNewSubscribers?: Maybe<Scalars['JSON']>;
  adminGeneralRecentActiveMembership?: Maybe<Scalars['JSON']>;
  adminGeneralRecentCancelMembership?: Maybe<Scalars['JSON']>;
  adminGeneralStatistic?: Maybe<Scalars['JSON']>;
  adminGetCDCGroup?: Maybe<Scalars['JSON']>;
  adminGetUserProfile?: Maybe<AdminGetUserPayload>;
  adminGetUsersNotJoinBeta?: Maybe<Scalars['JSON']>;
  /** Reads and enables pagination through a set of `AdminHistory`. */
  adminHistories?: Maybe<AdminHistoriesConnection>;
  adminHistory?: Maybe<AdminHistory>;
  adminMachineList?: Maybe<AdminMachineListPayload>;
  adminMembershipC2Detail?: Maybe<Scalars['JSON']>;
  adminMembershipC2List?: Maybe<Scalars['JSON']>;
  adminMembershipC2ListByUser?: Maybe<Scalars['JSON']>;
  adminMonthlyChallenge?: Maybe<Scalars['JSON']>;
  adminMonthlyChallenges?: Maybe<Scalars['JSON']>;
  adminPricingPlansList?: Maybe<Scalars['JSON']>;
  adminPrivilege?: Maybe<AdminPrivilege>;
  adminPrivilegeByUserIdAndTableSchemaAndTableNameAndPrivilegeType?: Maybe<AdminPrivilege>;
  /** Reads and enables pagination through a set of `AdminPrivilege`. */
  adminPrivileges?: Maybe<AdminPrivilegesConnection>;
  adminProduct?: Maybe<Scalars['JSON']>;
  adminProductCategories?: Maybe<Scalars['JSON']>;
  adminProductCategory?: Maybe<Scalars['JSON']>;
  adminProducts?: Maybe<Scalars['JSON']>;
  adminRole?: Maybe<AdminRole>;
  /** Reads and enables pagination through a set of `AdminRole`. */
  adminRoles?: Maybe<AdminRolesConnection>;
  adminRoleValue?: Maybe<AdminRoleValue>;
  /** Reads and enables pagination through a set of `AdminRoleValue`. */
  adminRoleValues?: Maybe<AdminRoleValuesConnection>;
  adminSubscriptionHistories?: Maybe<AdminSubscriptionHistoriesPayload>;
  adminSubscriptionList?: Maybe<AdminSubscriptionListPayload>;
  adminUserList?: Maybe<AdminUserListPayload>;
  adminUserListByOrgId?: Maybe<Scalars['JSON']>;
  avironSubscription?: Maybe<AvironSubscription>;
  avironSubscriptionByMachineIdAndSubscriptionToken?: Maybe<AvironSubscription>;
  /** Reads and enables pagination through a set of `AvironSubscription`. */
  avironSubscriptions?: Maybe<AvironSubscriptionsConnection>;
  betaRequest?: Maybe<BetaRequest>;
  /** Reads and enables pagination through a set of `BetaRequest`. */
  betaRequests?: Maybe<BetaRequestsConnection>;
  /** Reads and enables pagination through a set of `BlogCategory`. */
  blogCategories?: Maybe<BlogCategoriesConnection>;
  blogCategory?: Maybe<BlogCategory>;
  challenge?: Maybe<Challenge>;
  /** Reads and enables pagination through a set of `Challenge`. */
  challenges?: Maybe<ChallengesConnection>;
  checkBetaIsLive?: Maybe<CheckBetaIsLivePayload>;
  checkCancelOfferAvailableSubscription?: Maybe<Scalars['Boolean']>;
  checkoutItem?: Maybe<CheckoutItem>;
  checkoutItemMachine?: Maybe<CheckoutItemMachine>;
  /** Reads and enables pagination through a set of `CheckoutItemMachine`. */
  checkoutItemMachines?: Maybe<CheckoutItemMachinesConnection>;
  /** Reads and enables pagination through a set of `CheckoutItem`. */
  checkoutItems?: Maybe<CheckoutItemsConnection>;
  checkoutItemSubscription?: Maybe<CheckoutItemSubscription>;
  /** Reads and enables pagination through a set of `CheckoutItemSubscription`. */
  checkoutItemSubscriptions?: Maybe<CheckoutItemSubscriptionsConnection>;
  checkUserJoinedBetaProgram?: Maybe<Scalars['JSON']>;
  /** Reads and enables pagination through a set of `BetaRequest`. */
  countSearchBetaRequestAdvance: BetaRequestsConnection;
  countSearchSubscriptionAdvanceOp?: Maybe<Scalars['Int']>;
  /** The currently logged in user (or null if not logged in). */
  currentUser?: Maybe<User>;
  currentUserRole?: Maybe<Scalars['String']>;
  customerType?: Maybe<CustomerType>;
  /** Reads and enables pagination through a set of `CustomerType`. */
  customerTypes?: Maybe<CustomerTypesConnection>;
  dealerLocation?: Maybe<DealerLocation>;
  /** Reads and enables pagination through a set of `DealerLocation`. */
  dealerLocations?: Maybe<DealerLocationsConnection>;
  experience?: Maybe<Experience>;
  /** Reads and enables pagination through a set of `ExperienceCategory`. */
  experienceCategories?: Maybe<ExperienceCategoriesConnection>;
  experienceCategory?: Maybe<ExperienceCategory>;
  /** Reads and enables pagination through a set of `ExperienceDifficulty`. */
  experienceDifficulties?: Maybe<ExperienceDifficultiesConnection>;
  experienceDifficulty?: Maybe<ExperienceDifficulty>;
  /** Reads and enables pagination through a set of `Experience`. */
  experiences?: Maybe<ExperiencesConnection>;
  gameGetUserProfile?: Maybe<GameUser>;
  /** Reads and enables pagination through a set of `User`. */
  getAdmins: UsersConnection;
  getAGameInfoData?: Maybe<Scalars['JSON']>;
  getAllGameInfoData?: Maybe<Scalars['JSON']>;
  getContactList?: Maybe<ContactListPayload>;
  getCrmSession?: Maybe<CrmServerSessionPayload>;
  getGameCategoriesData?: Maybe<Scalars['JSON']>;
  getGameInfoData?: Maybe<Scalars['JSON']>;
  getGameOptionsData?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  getGameSession?: Maybe<GameSessionPayload>;
  getGameTypesData?: Maybe<Scalars['JSON']>;
  getIdToken?: Maybe<IdTokenPayload>;
  getLabelDetail?: Maybe<LabelFields>;
  getLabelList?: Maybe<LabelListPayload>;
  getMembershipListByPlanId?: Maybe<Scalars['JSON']>;
  getPaymentIntents?: Maybe<Scalars['JSON']>;
  getPaymentMethodsOfCustomer?: Maybe<Scalars['JSON']>;
  getPlans?: Maybe<Scalars['JSON']>;
  getProductsRatings?: Maybe<Array<Maybe<GetProductRatingsFields>>>;
  getPublicReviews?: Maybe<GetPublicReviewsPayload>;
  getSubscriptionsByUser?: Maybe<Scalars['JSON']>;
  healthcheck?: Maybe<Scalars['JSON']>;
  machine?: Maybe<Machine>;
  machineBySerialNumber?: Maybe<Machine>;
  /** Reads and enables pagination through a set of `MachineLastActivity`. */
  machineLastActivities?: Maybe<MachineLastActivitiesConnection>;
  machineLastActivity?: Maybe<MachineLastActivity>;
  machineLastActivityByMachineId?: Maybe<MachineLastActivity>;
  /** Reads and enables pagination through a set of `Machine`. */
  machines?: Maybe<MachinesConnection>;
  machineType?: Maybe<MachineType>;
  /** Reads and enables pagination through a set of `MachineType`. */
  machineTypes?: Maybe<MachineTypesConnection>;
  organization?: Maybe<Organization>;
  organizationBySlug?: Maybe<Organization>;
  /**
   * Given an invitation UUID (and, if required, the code that was emailed to you),
   * retrieves the `Organization` that you were invited to.
   */
  organizationForInvitation?: Maybe<Organization>;
  organizationInvitation?: Maybe<OrganizationInvitation>;
  /** Reads and enables pagination through a set of `OrganizationInvitation`. */
  organizationInvitations?: Maybe<OrganizationInvitationsConnection>;
  organizationMembership?: Maybe<OrganizationMembership>;
  /** Reads and enables pagination through a set of `Organization`. */
  organizations?: Maybe<OrganizationsConnection>;
  plan?: Maybe<Plan>;
  /** Reads and enables pagination through a set of `Plan`. */
  plans?: Maybe<PlansConnection>;
  planType?: Maybe<PlanType>;
  /** Reads and enables pagination through a set of `PlanType`. */
  planTypes?: Maybe<PlanTypesConnection>;
  post?: Maybe<Post>;
  /** Reads and enables pagination through a set of `Post`. */
  posts?: Maybe<PostsConnection>;
  press?: Maybe<Press>;
  pressByOrder?: Maybe<Press>;
  /** Reads and enables pagination through a set of `Press`. */
  presses?: Maybe<PressesConnection>;
  retrieveMachineSubscriptions: RetrieveMachineSubscriptionsConnection;
  retriveCustomerCurrency?: Maybe<Scalars['JSON']>;
  retrivePaymentIntent?: Maybe<Scalars['JSON']>;
  retriveStripeCustomer?: Maybe<Scalars['JSON']>;
  /** Reads and enables pagination through a set of `AdminHistory`. */
  searchAdminHistoriesAdvance: AdminHistoriesConnection;
  /** Reads and enables pagination through a set of `BetaRequest`. */
  searchBetaRequestAdvance: BetaRequestsConnection;
  /** Reads and enables pagination through a set of `Challenge`. */
  searchChallenges: ChallengesConnection;
  /** Reads and enables pagination through a set of `Experience`. */
  searchExperiences: ExperiencesConnection;
  /** Reads and enables pagination through a set of `Machine`. */
  searchMachine: MachinesConnection;
  /** Reads and enables pagination through a set of `Post`. */
  searchPosts: PostsConnection;
  /** Reads and enables pagination through a set of `AvironSubscription`. */
  searchSubscription: AvironSubscriptionsConnection;
  /** Reads and enables pagination through a set of `AvironSubscription`. */
  searchSubscriptionAdvance: AvironSubscriptionsConnection;
  /** Reads and enables pagination through a set of `AvironSubscription`. */
  searchSubscriptionAdvanceOp: AvironSubscriptionsConnection;
  /** Reads and enables pagination through a set of `AvironSubscription`. */
  searchSubscriptionByCustomerId: AvironSubscriptionsConnection;
  /** Reads and enables pagination through a set of `AvironSubscription`. */
  searchSubscriptionByMachineId: AvironSubscriptionsConnection;
  /** Reads and enables pagination through a set of `User`. */
  searchUser: UsersConnection;
  /** Reads and enables pagination through a set of `User`. */
  searchUsers: UsersConnection;
  stripeVerifyChangePaymentMethodToken?: Maybe<StripeVerifyChangePaymentMethodPayload>;
  subscriptionCancel?: Maybe<SubscriptionCancel>;
  /** Reads and enables pagination through a set of `SubscriptionCancel`. */
  subscriptionCancels?: Maybe<SubscriptionCancelsConnection>;
  subscriptionNote?: Maybe<SubscriptionNote>;
  /** Reads and enables pagination through a set of `SubscriptionNote`. */
  subscriptionNotes?: Maybe<SubscriptionNotesConnection>;
  subscriptionPause?: Maybe<SubscriptionPause>;
  /** Reads and enables pagination through a set of `SubscriptionPause`. */
  subscriptionPauses?: Maybe<SubscriptionPausesConnection>;
  subscriptionReceipt?: Maybe<SubscriptionReceipt>;
  subscriptionReceiptByStripeInvoiceId?: Maybe<SubscriptionReceipt>;
  /** Reads and enables pagination through a set of `SubscriptionReceipt`. */
  subscriptionReceipts?: Maybe<SubscriptionReceiptsConnection>;
  subscriptionState?: Maybe<SubscriptionState>;
  /** Reads and enables pagination through a set of `SubscriptionState`. */
  subscriptionStates?: Maybe<SubscriptionStatesConnection>;
  support?: Maybe<Support>;
  supportByOrder?: Maybe<Support>;
  supportBySlug?: Maybe<Support>;
  /** Reads and enables pagination through a set of `SupportCategory`. */
  supportCategories?: Maybe<SupportCategoriesConnection>;
  supportCategory?: Maybe<SupportCategory>;
  supportCategoryBySlug?: Maybe<SupportCategory>;
  /** Reads and enables pagination through a set of `Support`. */
  supports?: Maybe<SupportsConnection>;
  tblCheckout?: Maybe<TblCheckout>;
  /** Reads and enables pagination through a set of `TblCheckout`. */
  tblCheckouts?: Maybe<TblCheckoutsConnection>;
  /** Reads and enables pagination through a set of `User`. */
  test: UsersConnection;
  trackingPageView?: Maybe<TrackingSuccessPayload>;
  user?: Maybe<User>;
  userAuthentication?: Maybe<UserAuthentication>;
  userByUsername?: Maybe<User>;
  userEmail?: Maybe<UserEmail>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAdminAchievementArgs = {
  input?: Maybe<Scalars['JSON']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAdminAchievementsArgs = {
  input?: Maybe<Scalars['JSON']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAdminAchievementTypesArgs = {
  input?: Maybe<Scalars['JSON']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAdminBetaUsersArgs = {
  input?: Maybe<Scalars['JSON']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAdminExportMembershipArgs = {
  input?: Maybe<Scalars['JSON']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAdminGameAppsArgs = {
  input?: Maybe<Scalars['JSON']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAdminGameCurrenciesArgs = {
  input?: Maybe<Scalars['JSON']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAdminGameProductCategoryGroupsArgs = {
  input?: Maybe<Scalars['JSON']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAdminGameSettingArgs = {
  input?: Maybe<Scalars['JSON']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAdminGameSettingsArgs = {
  input?: Maybe<Scalars['JSON']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAdminGameTypesArgs = {
  input?: Maybe<Scalars['JSON']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAdminGeneralMembershipChartArgs = {
  input?: Maybe<Scalars['JSON']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAdminGeneralNewSubscribersArgs = {
  input?: Maybe<Scalars['JSON']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAdminGeneralRecentActiveMembershipArgs = {
  input?: Maybe<Scalars['JSON']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAdminGeneralRecentCancelMembershipArgs = {
  input?: Maybe<Scalars['JSON']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAdminGeneralStatisticArgs = {
  input?: Maybe<Scalars['JSON']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAdminGetCdcGroupArgs = {
  input?: Maybe<Scalars['JSON']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAdminGetUserProfileArgs = {
  input?: Maybe<AdminGetUserProfileInput>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAdminGetUsersNotJoinBetaArgs = {
  input?: Maybe<Scalars['JSON']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAdminHistoriesArgs = {
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  condition?: Maybe<AdminHistoryCondition>;
  filter?: Maybe<AdminHistoryFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<AdminHistoriesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAdminHistoryArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryAdminMachineListArgs = {
  input?: Maybe<AdminMachineListInput>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAdminMembershipC2DetailArgs = {
  input?: Maybe<Scalars['JSON']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAdminMembershipC2ListArgs = {
  input?: Maybe<Scalars['JSON']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAdminMembershipC2ListByUserArgs = {
  input?: Maybe<Scalars['JSON']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAdminMonthlyChallengeArgs = {
  input?: Maybe<Scalars['JSON']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAdminMonthlyChallengesArgs = {
  input?: Maybe<Scalars['JSON']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAdminPricingPlansListArgs = {
  input?: Maybe<Scalars['JSON']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAdminPrivilegeArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryAdminPrivilegeByUserIdAndTableSchemaAndTableNameAndPrivilegeTypeArgs = {
  privilegeType: Scalars['String'];
  tableName: Scalars['String'];
  tableSchema: Scalars['String'];
  userId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryAdminPrivilegesArgs = {
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  condition?: Maybe<AdminPrivilegeCondition>;
  filter?: Maybe<AdminPrivilegeFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<AdminPrivilegesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAdminProductArgs = {
  input?: Maybe<Scalars['JSON']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAdminProductCategoriesArgs = {
  input?: Maybe<Scalars['JSON']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAdminProductCategoryArgs = {
  input?: Maybe<Scalars['JSON']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAdminProductsArgs = {
  input?: Maybe<Scalars['JSON']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAdminRoleArgs = {
  role: Scalars['String'];
  userId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryAdminRolesArgs = {
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  condition?: Maybe<AdminRoleCondition>;
  filter?: Maybe<AdminRoleFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<AdminRolesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAdminRoleValueArgs = {
  role: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryAdminRoleValuesArgs = {
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  condition?: Maybe<AdminRoleValueCondition>;
  filter?: Maybe<AdminRoleValueFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<AdminRoleValuesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAdminSubscriptionHistoriesArgs = {
  input?: Maybe<AdminSubscriptionHistoriesInput>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAdminSubscriptionListArgs = {
  input?: Maybe<AdminSubscriptionListInput>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAdminUserListArgs = {
  input?: Maybe<AdminUserListInput>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAdminUserListByOrgIdArgs = {
  input?: Maybe<Scalars['JSON']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAvironSubscriptionArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryAvironSubscriptionByMachineIdAndSubscriptionTokenArgs = {
  machineId: Scalars['String'];
  subscriptionToken: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryAvironSubscriptionsArgs = {
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  condition?: Maybe<AvironSubscriptionCondition>;
  filter?: Maybe<AvironSubscriptionFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<AvironSubscriptionsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryBetaRequestArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryBetaRequestsArgs = {
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  condition?: Maybe<BetaRequestCondition>;
  filter?: Maybe<BetaRequestFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<BetaRequestsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryBlogCategoriesArgs = {
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  condition?: Maybe<BlogCategoryCondition>;
  filter?: Maybe<BlogCategoryFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<BlogCategoriesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryBlogCategoryArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryChallengeArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryChallengesArgs = {
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  condition?: Maybe<ChallengeCondition>;
  filter?: Maybe<ChallengeFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ChallengesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCheckoutItemArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCheckoutItemMachineArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCheckoutItemMachinesArgs = {
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  condition?: Maybe<CheckoutItemMachineCondition>;
  filter?: Maybe<CheckoutItemMachineFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<CheckoutItemMachinesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCheckoutItemsArgs = {
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  condition?: Maybe<CheckoutItemCondition>;
  filter?: Maybe<CheckoutItemFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<CheckoutItemsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCheckoutItemSubscriptionArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCheckoutItemSubscriptionsArgs = {
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  condition?: Maybe<CheckoutItemSubscriptionCondition>;
  filter?: Maybe<CheckoutItemSubscriptionFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<CheckoutItemSubscriptionsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCheckUserJoinedBetaProgramArgs = {
  input?: Maybe<CheckUserJoinedBetaProgramInput>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCountSearchBetaRequestAdvanceArgs = {
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  filter?: Maybe<BetaRequestFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  search: Scalars['String'];
  status: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCountSearchSubscriptionAdvanceOpArgs = {
  customerType: Scalars['String'];
  endTime: Scalars['Datetime'];
  pauseStatus: Array<Maybe<Scalars['Boolean']>>;
  planType: Scalars['String'];
  search: Scalars['String'];
  startTime: Scalars['Datetime'];
  state: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCustomerTypeArgs = {
  value: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCustomerTypesArgs = {
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  condition?: Maybe<CustomerTypeCondition>;
  filter?: Maybe<CustomerTypeFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<CustomerTypesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryDealerLocationArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryDealerLocationsArgs = {
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  condition?: Maybe<DealerLocationCondition>;
  filter?: Maybe<DealerLocationFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<DealerLocationsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryExperienceArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryExperienceCategoriesArgs = {
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  condition?: Maybe<ExperienceCategoryCondition>;
  filter?: Maybe<ExperienceCategoryFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ExperienceCategoriesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryExperienceCategoryArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryExperienceDifficultiesArgs = {
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  condition?: Maybe<ExperienceDifficultyCondition>;
  filter?: Maybe<ExperienceDifficultyFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ExperienceDifficultiesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryExperienceDifficultyArgs = {
  value: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryExperiencesArgs = {
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  condition?: Maybe<ExperienceCondition>;
  filter?: Maybe<ExperienceFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<ExperiencesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetAdminsArgs = {
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  filter?: Maybe<UserFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  search: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGetAGameInfoDataArgs = {
  input?: Maybe<Scalars['JSON']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetAllGameInfoDataArgs = {
  input?: Maybe<Scalars['JSON']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetContactListArgs = {
  input?: Maybe<GetContactListInput>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetGameCategoriesDataArgs = {
  input?: Maybe<Scalars['JSON']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetGameInfoDataArgs = {
  input?: Maybe<Scalars['JSON']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetGameOptionsDataArgs = {
  input?: Maybe<Scalars['JSON']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetGameTypesDataArgs = {
  input?: Maybe<Scalars['JSON']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetLabelDetailArgs = {
  input?: Maybe<GetLabelDetailInput>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetMembershipListByPlanIdArgs = {
  input?: Maybe<GetMembershipListByPlanIdInput>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetPaymentIntentsArgs = {
  input?: Maybe<GetPaymentIntentsInput>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetPaymentMethodsOfCustomerArgs = {
  input?: Maybe<GetPaymentMethodOfCustomerInput>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetPlansArgs = {
  input: GetPlansInput;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetProductsRatingsArgs = {
  input: GetProductRatingsInput;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetPublicReviewsArgs = {
  input?: Maybe<GetPublicReviewsInput>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetSubscriptionsByUserArgs = {
  input: GetSubscriptionsByUserInput;
};


/** The root query type which gives access points into the data universe. */
export type QueryMachineArgs = {
  machineId: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryMachineBySerialNumberArgs = {
  serialNumber: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryMachineLastActivitiesArgs = {
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  condition?: Maybe<MachineLastActivityCondition>;
  filter?: Maybe<MachineLastActivityFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<MachineLastActivitiesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryMachineLastActivityArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryMachineLastActivityByMachineIdArgs = {
  machineId: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryMachinesArgs = {
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  condition?: Maybe<MachineCondition>;
  filter?: Maybe<MachineFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<MachinesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryMachineTypeArgs = {
  value: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryMachineTypesArgs = {
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  condition?: Maybe<MachineTypeCondition>;
  filter?: Maybe<MachineTypeFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<MachineTypesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryOrganizationArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryOrganizationBySlugArgs = {
  slug: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryOrganizationForInvitationArgs = {
  code?: Maybe<Scalars['String']>;
  invitationId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryOrganizationInvitationArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryOrganizationInvitationsArgs = {
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  condition?: Maybe<OrganizationInvitationCondition>;
  filter?: Maybe<OrganizationInvitationFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<OrganizationInvitationsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryOrganizationMembershipArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryOrganizationsArgs = {
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  condition?: Maybe<OrganizationCondition>;
  filter?: Maybe<OrganizationFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<OrganizationsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryPlanArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPlansArgs = {
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  condition?: Maybe<PlanCondition>;
  filter?: Maybe<PlanFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<PlansOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryPlanTypeArgs = {
  value: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPlanTypesArgs = {
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  condition?: Maybe<PlanTypeCondition>;
  filter?: Maybe<PlanTypeFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<PlanTypesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryPostArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPostsArgs = {
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  condition?: Maybe<PostCondition>;
  filter?: Maybe<PostFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<PostsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryPressArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPressByOrderArgs = {
  order: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPressesArgs = {
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  condition?: Maybe<PressCondition>;
  filter?: Maybe<PressFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<PressesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryRetrieveMachineSubscriptionsArgs = {
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  filter?: Maybe<RetrieveMachineSubscriptionsRecordFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  machineid: Scalars['String'];
  offset?: Maybe<Scalars['Int']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryRetrivePaymentIntentArgs = {
  input?: Maybe<RetrivePaymentIntentInput>;
};


/** The root query type which gives access points into the data universe. */
export type QueryRetriveStripeCustomerArgs = {
  input?: Maybe<RetriceCustomerInput>;
  params?: Maybe<Scalars['JSON']>;
};


/** The root query type which gives access points into the data universe. */
export type QuerySearchAdminHistoriesAdvanceArgs = {
  action: Scalars['String'];
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  endTime: Scalars['Datetime'];
  filter?: Maybe<AdminHistoryFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  search: Scalars['String'];
  startTime: Scalars['Datetime'];
  tablename: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySearchBetaRequestAdvanceArgs = {
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  filter?: Maybe<BetaRequestFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy: Scalars['String'];
  qoffset: Scalars['Int'];
  qtake: Scalars['Int'];
  search: Scalars['String'];
  status: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySearchChallengesArgs = {
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  endTime: Scalars['Datetime'];
  filter?: Maybe<ChallengeFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  publicStatus: Array<Maybe<Scalars['Boolean']>>;
  search: Scalars['String'];
  startTime: Scalars['Datetime'];
  targetType: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySearchExperiencesArgs = {
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  category: Scalars['String'];
  difficulty: Scalars['String'];
  filter?: Maybe<ExperienceFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  publicStatus: Array<Maybe<Scalars['Boolean']>>;
  search: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySearchMachineArgs = {
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  filter?: Maybe<MachineFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  search: Scalars['String'];
  type: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySearchPostsArgs = {
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  category: Scalars['String'];
  filter?: Maybe<PostFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  pinStatus: Array<Maybe<Scalars['Boolean']>>;
  publicStatus: Array<Maybe<Scalars['Boolean']>>;
  search: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySearchSubscriptionArgs = {
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  filter?: Maybe<AvironSubscriptionFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  search: Scalars['String'];
  state: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySearchSubscriptionAdvanceArgs = {
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  customerType: Scalars['String'];
  endTime: Scalars['Datetime'];
  filter?: Maybe<AvironSubscriptionFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  pauseStatus: Array<Maybe<Scalars['Boolean']>>;
  planType: Scalars['String'];
  search: Scalars['String'];
  startTime: Scalars['Datetime'];
  state: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySearchSubscriptionAdvanceOpArgs = {
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  customerType: Scalars['String'];
  endTime: Scalars['Datetime'];
  filter?: Maybe<AvironSubscriptionFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  pauseStatus: Array<Maybe<Scalars['Boolean']>>;
  planType: Scalars['String'];
  qoffset: Scalars['Int'];
  qtake: Scalars['Int'];
  search: Scalars['String'];
  startTime: Scalars['Datetime'];
  state: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySearchSubscriptionByCustomerIdArgs = {
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  customerId: Scalars['UUID'];
  filter?: Maybe<AvironSubscriptionFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  pauseStatus: Array<Maybe<Scalars['Boolean']>>;
  search: Scalars['String'];
  state: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySearchSubscriptionByMachineIdArgs = {
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  filter?: Maybe<AvironSubscriptionFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  machineId: Scalars['String'];
  offset?: Maybe<Scalars['Int']>;
  pauseStatus: Array<Maybe<Scalars['Boolean']>>;
  search: Scalars['String'];
  state: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySearchUserArgs = {
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  filter?: Maybe<UserFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  search: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySearchUsersArgs = {
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  filter?: Maybe<UserFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy: Scalars['String'];
  search: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryStripeVerifyChangePaymentMethodTokenArgs = {
  input?: Maybe<StripeVerifyChangePaymentMethodTokenInput>;
};


/** The root query type which gives access points into the data universe. */
export type QuerySubscriptionCancelArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySubscriptionCancelsArgs = {
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  condition?: Maybe<SubscriptionCancelCondition>;
  filter?: Maybe<SubscriptionCancelFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<SubscriptionCancelsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QuerySubscriptionNoteArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySubscriptionNotesArgs = {
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  condition?: Maybe<SubscriptionNoteCondition>;
  filter?: Maybe<SubscriptionNoteFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<SubscriptionNotesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QuerySubscriptionPauseArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySubscriptionPausesArgs = {
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  condition?: Maybe<SubscriptionPauseCondition>;
  filter?: Maybe<SubscriptionPauseFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<SubscriptionPausesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QuerySubscriptionReceiptArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySubscriptionReceiptByStripeInvoiceIdArgs = {
  stripeInvoiceId: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySubscriptionReceiptsArgs = {
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  condition?: Maybe<SubscriptionReceiptCondition>;
  filter?: Maybe<SubscriptionReceiptFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<SubscriptionReceiptsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QuerySubscriptionStateArgs = {
  value: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySubscriptionStatesArgs = {
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  condition?: Maybe<SubscriptionStateCondition>;
  filter?: Maybe<SubscriptionStateFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<SubscriptionStatesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QuerySupportArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySupportByOrderArgs = {
  order: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySupportBySlugArgs = {
  slug: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySupportCategoriesArgs = {
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  condition?: Maybe<SupportCategoryCondition>;
  filter?: Maybe<SupportCategoryFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<SupportCategoriesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QuerySupportCategoryArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySupportCategoryBySlugArgs = {
  slug: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySupportsArgs = {
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  condition?: Maybe<SupportCondition>;
  filter?: Maybe<SupportFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<SupportsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryTblCheckoutArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryTblCheckoutsArgs = {
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  condition?: Maybe<TblCheckoutCondition>;
  filter?: Maybe<TblCheckoutFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<TblCheckoutsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryTestArgs = {
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  filter?: Maybe<UserFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryTrackingPageViewArgs = {
  input?: Maybe<TrackingPageViewInput>;
};


/** The root query type which gives access points into the data universe. */
export type QueryUserArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryUserAuthenticationArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryUserByUsernameArgs = {
  username: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryUserEmailArgs = {
  id: Scalars['UUID'];
};

export type ReactiveUserSubscriptionInput = {
  id: Scalars['String'];
  isHavePromo?: Maybe<Scalars['Boolean']>;
  source?: Maybe<Scalars['String']>;
};

export type RefreshInput = {
  refreshToken: Scalars['String'];
};

export type RefreshPayload = {
  __typename?: 'RefreshPayload';
  expires?: Maybe<Scalars['Date']>;
  refreshToken?: Maybe<Scalars['String']>;
  token: Scalars['String'];
  type?: Maybe<Scalars['String']>;
};

export type RegisterInput = {
  avatarUrl?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  username: Scalars['String'];
};

export type RegisterPayload = {
  __typename?: 'RegisterPayload';
  user: User;
};

/** All input for the `removeFromOrganization` mutation. */
export type RemoveFromOrganizationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  organizationId: Scalars['UUID'];
  userId: Scalars['UUID'];
};

/** The output of our `removeFromOrganization` mutation. */
export type RemoveFromOrganizationPayload = {
  __typename?: 'RemoveFromOrganizationPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

export type RemovePasswordPayload = {
  __typename?: 'RemovePasswordPayload';
  success?: Maybe<Scalars['Boolean']>;
};

/** All input for the `requestAccountDeletion` mutation. */
export type RequestAccountDeletionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** The output of our `requestAccountDeletion` mutation. */
export type RequestAccountDeletionPayload = {
  __typename?: 'RequestAccountDeletionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  success?: Maybe<Scalars['Boolean']>;
};

/** All input for the `resendEmailVerificationCode` mutation. */
export type ResendEmailVerificationCodeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  emailId: Scalars['UUID'];
};

/** The output of our `resendEmailVerificationCode` mutation. */
export type ResendEmailVerificationCodePayload = {
  __typename?: 'ResendEmailVerificationCodePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  success?: Maybe<Scalars['Boolean']>;
};

/** All input for the `resetPassword` mutation. */
export type ResetPasswordInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  newPassword: Scalars['String'];
  resetToken: Scalars['String'];
  userId: Scalars['UUID'];
};

/** The output of our `resetPassword` mutation. */
export type ResetPasswordPayload = {
  __typename?: 'ResetPasswordPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  success?: Maybe<Scalars['Boolean']>;
};

export type ResumePaymentSubscriptionInput = {
  id: Scalars['String'];
  source?: Maybe<Scalars['String']>;
};

export type ResumePaymentSubscriptionPayload = {
  __typename?: 'ResumePaymentSubscriptionPayload';
  code?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

/** A `RetrieveMachineSubscriptionsRecord` edge in the connection. */
export type RetrieveMachineSubscriptionEdge = {
  __typename?: 'RetrieveMachineSubscriptionEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `RetrieveMachineSubscriptionsRecord` at the end of the edge. */
  node: RetrieveMachineSubscriptionsRecord;
};

/** A connection to a list of `RetrieveMachineSubscriptionsRecord` values. */
export type RetrieveMachineSubscriptionsConnection = {
  __typename?: 'RetrieveMachineSubscriptionsConnection';
  /** A list of edges which contains the `RetrieveMachineSubscriptionsRecord` and cursor to aid in pagination. */
  edges: Array<RetrieveMachineSubscriptionEdge>;
  /** A list of `RetrieveMachineSubscriptionsRecord` objects. */
  nodes: Array<RetrieveMachineSubscriptionsRecord>;
  /** The count of *all* `RetrieveMachineSubscriptionsRecord` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** The return type of our `retrieveMachineSubscriptions` query. */
export type RetrieveMachineSubscriptionsRecord = {
  __typename?: 'RetrieveMachineSubscriptionsRecord';
  currentPeriodEnd?: Maybe<Scalars['Datetime']>;
  currentPeriodStart?: Maybe<Scalars['Datetime']>;
  customerType?: Maybe<Scalars['String']>;
  isValid?: Maybe<Scalars['Boolean']>;
  machineId?: Maybe<Scalars['String']>;
  planId?: Maybe<Scalars['Int']>;
};

/** A filter to be used against `RetrieveMachineSubscriptionsRecord` object types. All fields are combined with a logical ‘and.’ */
export type RetrieveMachineSubscriptionsRecordFilter = {
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<RetrieveMachineSubscriptionsRecordFilter>>;
  /** Filter by the object’s `currentPeriodEnd` field. */
  currentPeriodEnd?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `currentPeriodStart` field. */
  currentPeriodStart?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `customerType` field. */
  customerType?: Maybe<StringFilter>;
  /** Filter by the object’s `isValid` field. */
  isValid?: Maybe<BooleanFilter>;
  /** Filter by the object’s `machineId` field. */
  machineId?: Maybe<StringFilter>;
  /** Negates the expression. */
  not?: Maybe<RetrieveMachineSubscriptionsRecordFilter>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<RetrieveMachineSubscriptionsRecordFilter>>;
  /** Filter by the object’s `planId` field. */
  planId?: Maybe<IntFilter>;
};

export type RetryChargeSubscriptionInput = {
  token: Scalars['String'];
};

export type RetryChargeSubscriptionPayload = {
  __typename?: 'RetryChargeSubscriptionPayload';
  code?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['String']>;
};

export type RevokeInput = {
  token: Scalars['String'];
};

export type RevokePayload = {
  __typename?: 'RevokePayload';
  code?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

/** A filter to be used against String fields. All fields are combined with a logical ‘and.’ */
export type StringFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<Scalars['String']>;
  /** Not equal to the specified value, treating null like an ordinary value (case-insensitive). */
  distinctFromInsensitive?: Maybe<Scalars['String']>;
  /** Ends with the specified string (case-sensitive). */
  endsWith?: Maybe<Scalars['String']>;
  /** Ends with the specified string (case-insensitive). */
  endsWithInsensitive?: Maybe<Scalars['String']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<Scalars['String']>;
  /** Equal to the specified value (case-insensitive). */
  equalToInsensitive?: Maybe<Scalars['String']>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<Scalars['String']>;
  /** Greater than the specified value (case-insensitive). */
  greaterThanInsensitive?: Maybe<Scalars['String']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<Scalars['String']>;
  /** Greater than or equal to the specified value (case-insensitive). */
  greaterThanOrEqualToInsensitive?: Maybe<Scalars['String']>;
  /** Included in the specified list. */
  in?: Maybe<Array<Scalars['String']>>;
  /** Contains the specified string (case-sensitive). */
  includes?: Maybe<Scalars['String']>;
  /** Contains the specified string (case-insensitive). */
  includesInsensitive?: Maybe<Scalars['String']>;
  /** Included in the specified list (case-insensitive). */
  inInsensitive?: Maybe<Array<Scalars['String']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: Maybe<Scalars['String']>;
  /** Less than the specified value (case-insensitive). */
  lessThanInsensitive?: Maybe<Scalars['String']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<Scalars['String']>;
  /** Less than or equal to the specified value (case-insensitive). */
  lessThanOrEqualToInsensitive?: Maybe<Scalars['String']>;
  /**
   * Matches the specified pattern (case-sensitive). An underscore (_) matches any
   * single character; a percent sign (%) matches any sequence of zero or more characters.
   */
  like?: Maybe<Scalars['String']>;
  /**
   * Matches the specified pattern (case-insensitive). An underscore (_) matches
   * any single character; a percent sign (%) matches any sequence of zero or more characters.
   */
  likeInsensitive?: Maybe<Scalars['String']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<Scalars['String']>;
  /** Equal to the specified value, treating null like an ordinary value (case-insensitive). */
  notDistinctFromInsensitive?: Maybe<Scalars['String']>;
  /** Does not end with the specified string (case-sensitive). */
  notEndsWith?: Maybe<Scalars['String']>;
  /** Does not end with the specified string (case-insensitive). */
  notEndsWithInsensitive?: Maybe<Scalars['String']>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<Scalars['String']>;
  /** Not equal to the specified value (case-insensitive). */
  notEqualToInsensitive?: Maybe<Scalars['String']>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<Scalars['String']>>;
  /** Does not contain the specified string (case-sensitive). */
  notIncludes?: Maybe<Scalars['String']>;
  /** Does not contain the specified string (case-insensitive). */
  notIncludesInsensitive?: Maybe<Scalars['String']>;
  /** Not included in the specified list (case-insensitive). */
  notInInsensitive?: Maybe<Array<Scalars['String']>>;
  /**
   * Does not match the specified pattern (case-sensitive). An underscore (_)
   * matches any single character; a percent sign (%) matches any sequence of zero
   * or more characters.
   */
  notLike?: Maybe<Scalars['String']>;
  /**
   * Does not match the specified pattern (case-insensitive). An underscore (_)
   * matches any single character; a percent sign (%) matches any sequence of zero
   * or more characters.
   */
  notLikeInsensitive?: Maybe<Scalars['String']>;
  /** Does not start with the specified string (case-sensitive). */
  notStartsWith?: Maybe<Scalars['String']>;
  /** Does not start with the specified string (case-insensitive). */
  notStartsWithInsensitive?: Maybe<Scalars['String']>;
  /** Starts with the specified string (case-sensitive). */
  startsWith?: Maybe<Scalars['String']>;
  /** Starts with the specified string (case-insensitive). */
  startsWithInsensitive?: Maybe<Scalars['String']>;
};

export type SubmitContactErrorFields = {
  __typename?: 'SubmitContactErrorFields';
  code?: Maybe<Scalars['Int']>;
  key?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export type SubmitContactInput = {
  email: Scalars['String'];
  message: Scalars['String'];
  name: Scalars['String'];
};

export type SubmitContactPayload = {
  __typename?: 'SubmitContactPayload';
  contact?: Maybe<SubmitContactSuccessFields>;
  error?: Maybe<SubmitContactErrorFields>;
};

export type SubmitContactSuccessFields = {
  __typename?: 'SubmitContactSuccessFields';
  code?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['String']>;
};

/** The root subscription type: contains realtime events you can subscribe to with the `subscription` operation. */
export type Subscription = {
  __typename?: 'Subscription';
  /** Triggered when the logged in user's record is updated in some way. */
  currentUserUpdated?: Maybe<UserSubscriptionPayload>;
};

export type SubscriptionCancel = {
  __typename?: 'SubscriptionCancel';
  cancelBy?: Maybe<Scalars['String']>;
  cancelDay: Scalars['Datetime'];
  id: Scalars['UUID'];
  reason: Scalars['String'];
  reasonDetail?: Maybe<Scalars['String']>;
  /** Reads a single `AvironSubscription` that is related to this `SubscriptionCancel`. */
  subscription?: Maybe<AvironSubscription>;
  subscriptionId: Scalars['UUID'];
};

/**
 * A condition to be used against `SubscriptionCancel` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type SubscriptionCancelCondition = {
  /** Checks for equality with the object’s `cancelBy` field. */
  cancelBy?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `subscriptionId` field. */
  subscriptionId?: Maybe<Scalars['UUID']>;
};

/** A filter to be used against `SubscriptionCancel` object types. All fields are combined with a logical ‘and.’ */
export type SubscriptionCancelFilter = {
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<SubscriptionCancelFilter>>;
  /** Filter by the object’s `cancelBy` field. */
  cancelBy?: Maybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Negates the expression. */
  not?: Maybe<SubscriptionCancelFilter>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<SubscriptionCancelFilter>>;
  /** Filter by the object’s `subscriptionId` field. */
  subscriptionId?: Maybe<UuidFilter>;
};

/** A connection to a list of `SubscriptionCancel` values. */
export type SubscriptionCancelsConnection = {
  __typename?: 'SubscriptionCancelsConnection';
  /** A list of edges which contains the `SubscriptionCancel` and cursor to aid in pagination. */
  edges: Array<SubscriptionCancelsEdge>;
  /** A list of `SubscriptionCancel` objects. */
  nodes: Array<SubscriptionCancel>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `SubscriptionCancel` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `SubscriptionCancel` edge in the connection. */
export type SubscriptionCancelsEdge = {
  __typename?: 'SubscriptionCancelsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `SubscriptionCancel` at the end of the edge. */
  node: SubscriptionCancel;
};

/** Methods to use when ordering `SubscriptionCancel`. */
export enum SubscriptionCancelsOrderBy {
  CancelByAsc = 'CANCEL_BY_ASC',
  CancelByDesc = 'CANCEL_BY_DESC',
  CancelDayAsc = 'CANCEL_DAY_ASC',
  CancelDayDesc = 'CANCEL_DAY_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  SubscriptionIdAsc = 'SUBSCRIPTION_ID_ASC',
  SubscriptionIdDesc = 'SUBSCRIPTION_ID_DESC'
}

export type SubscriptionNote = {
  __typename?: 'SubscriptionNote';
  createdAt?: Maybe<Scalars['Datetime']>;
  id: Scalars['UUID'];
  note?: Maybe<Scalars['String']>;
  /** Reads a single `AvironSubscription` that is related to this `SubscriptionNote`. */
  subscription?: Maybe<AvironSubscription>;
  subscriptionId?: Maybe<Scalars['UUID']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
};

/**
 * A condition to be used against `SubscriptionNote` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type SubscriptionNoteCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `subscriptionId` field. */
  subscriptionId?: Maybe<Scalars['UUID']>;
};

/** A filter to be used against `SubscriptionNote` object types. All fields are combined with a logical ‘and.’ */
export type SubscriptionNoteFilter = {
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<SubscriptionNoteFilter>>;
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Negates the expression. */
  not?: Maybe<SubscriptionNoteFilter>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<SubscriptionNoteFilter>>;
  /** Filter by the object’s `subscriptionId` field. */
  subscriptionId?: Maybe<UuidFilter>;
};

/** A connection to a list of `SubscriptionNote` values. */
export type SubscriptionNotesConnection = {
  __typename?: 'SubscriptionNotesConnection';
  /** A list of edges which contains the `SubscriptionNote` and cursor to aid in pagination. */
  edges: Array<SubscriptionNotesEdge>;
  /** A list of `SubscriptionNote` objects. */
  nodes: Array<SubscriptionNote>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `SubscriptionNote` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `SubscriptionNote` edge in the connection. */
export type SubscriptionNotesEdge = {
  __typename?: 'SubscriptionNotesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `SubscriptionNote` at the end of the edge. */
  node: SubscriptionNote;
};

/** Methods to use when ordering `SubscriptionNote`. */
export enum SubscriptionNotesOrderBy {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  SubscriptionIdAsc = 'SUBSCRIPTION_ID_ASC',
  SubscriptionIdDesc = 'SUBSCRIPTION_ID_DESC'
}

export type SubscriptionPause = {
  __typename?: 'SubscriptionPause';
  createdAt?: Maybe<Scalars['Datetime']>;
  id: Scalars['UUID'];
  note?: Maybe<Scalars['String']>;
  pauseAt: Scalars['Datetime'];
  reason?: Maybe<Scalars['String']>;
  resumeAt?: Maybe<Scalars['Datetime']>;
  status?: Maybe<Scalars['String']>;
  /** Reads a single `AvironSubscription` that is related to this `SubscriptionPause`. */
  subscription?: Maybe<AvironSubscription>;
  subscriptionId: Scalars['UUID'];
  /** Reads a single `User` that is related to this `SubscriptionPause`. */
  user?: Maybe<User>;
  userId: Scalars['UUID'];
};

/**
 * A condition to be used against `SubscriptionPause` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type SubscriptionPauseCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `subscriptionId` field. */
  subscriptionId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: Maybe<Scalars['UUID']>;
};

/** A filter to be used against `SubscriptionPause` object types. All fields are combined with a logical ‘and.’ */
export type SubscriptionPauseFilter = {
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<SubscriptionPauseFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Negates the expression. */
  not?: Maybe<SubscriptionPauseFilter>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<SubscriptionPauseFilter>>;
  /** Filter by the object’s `subscriptionId` field. */
  subscriptionId?: Maybe<UuidFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: Maybe<UuidFilter>;
};

/** A connection to a list of `SubscriptionPause` values. */
export type SubscriptionPausesConnection = {
  __typename?: 'SubscriptionPausesConnection';
  /** A list of edges which contains the `SubscriptionPause` and cursor to aid in pagination. */
  edges: Array<SubscriptionPausesEdge>;
  /** A list of `SubscriptionPause` objects. */
  nodes: Array<SubscriptionPause>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `SubscriptionPause` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `SubscriptionPause` edge in the connection. */
export type SubscriptionPausesEdge = {
  __typename?: 'SubscriptionPausesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `SubscriptionPause` at the end of the edge. */
  node: SubscriptionPause;
};

/** Methods to use when ordering `SubscriptionPause`. */
export enum SubscriptionPausesOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  SubscriptionIdAsc = 'SUBSCRIPTION_ID_ASC',
  SubscriptionIdDesc = 'SUBSCRIPTION_ID_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC'
}

export type SubscriptionReceipt = {
  __typename?: 'SubscriptionReceipt';
  amount: Scalars['Int'];
  /** Reads a single `AvironSubscription` that is related to this `SubscriptionReceipt`. */
  avironSubscription?: Maybe<AvironSubscription>;
  avironSubscriptionId: Scalars['UUID'];
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  id: Scalars['UUID'];
  note?: Maybe<Scalars['String']>;
  paymentMethod: Scalars['String'];
  periodEnd?: Maybe<Scalars['Datetime']>;
  periodStart?: Maybe<Scalars['Datetime']>;
  status: Scalars['String'];
  stripeInvoiceId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  userId: Scalars['UUID'];
};

/**
 * A condition to be used against `SubscriptionReceipt` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type SubscriptionReceiptCondition = {
  /** Checks for equality with the object’s `avironSubscriptionId` field. */
  avironSubscriptionId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `stripeInvoiceId` field. */
  stripeInvoiceId?: Maybe<Scalars['String']>;
};

/** A filter to be used against `SubscriptionReceipt` object types. All fields are combined with a logical ‘and.’ */
export type SubscriptionReceiptFilter = {
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<SubscriptionReceiptFilter>>;
  /** Filter by the object’s `avironSubscriptionId` field. */
  avironSubscriptionId?: Maybe<UuidFilter>;
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Negates the expression. */
  not?: Maybe<SubscriptionReceiptFilter>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<SubscriptionReceiptFilter>>;
  /** Filter by the object’s `stripeInvoiceId` field. */
  stripeInvoiceId?: Maybe<StringFilter>;
};

/** A connection to a list of `SubscriptionReceipt` values. */
export type SubscriptionReceiptsConnection = {
  __typename?: 'SubscriptionReceiptsConnection';
  /** A list of edges which contains the `SubscriptionReceipt` and cursor to aid in pagination. */
  edges: Array<SubscriptionReceiptsEdge>;
  /** A list of `SubscriptionReceipt` objects. */
  nodes: Array<SubscriptionReceipt>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `SubscriptionReceipt` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `SubscriptionReceipt` edge in the connection. */
export type SubscriptionReceiptsEdge = {
  __typename?: 'SubscriptionReceiptsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `SubscriptionReceipt` at the end of the edge. */
  node: SubscriptionReceipt;
};

/** Methods to use when ordering `SubscriptionReceipt`. */
export enum SubscriptionReceiptsOrderBy {
  AvironSubscriptionIdAsc = 'AVIRON_SUBSCRIPTION_ID_ASC',
  AvironSubscriptionIdDesc = 'AVIRON_SUBSCRIPTION_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  StripeInvoiceIdAsc = 'STRIPE_INVOICE_ID_ASC',
  StripeInvoiceIdDesc = 'STRIPE_INVOICE_ID_DESC'
}

export type SubscriptionState = {
  __typename?: 'SubscriptionState';
  value: Scalars['String'];
};

/**
 * A condition to be used against `SubscriptionState` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type SubscriptionStateCondition = {
  /** Checks for equality with the object’s `value` field. */
  value?: Maybe<Scalars['String']>;
};

/** A filter to be used against `SubscriptionState` object types. All fields are combined with a logical ‘and.’ */
export type SubscriptionStateFilter = {
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<SubscriptionStateFilter>>;
  /** Negates the expression. */
  not?: Maybe<SubscriptionStateFilter>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<SubscriptionStateFilter>>;
  /** Filter by the object’s `value` field. */
  value?: Maybe<StringFilter>;
};

/** A connection to a list of `SubscriptionState` values. */
export type SubscriptionStatesConnection = {
  __typename?: 'SubscriptionStatesConnection';
  /** A list of edges which contains the `SubscriptionState` and cursor to aid in pagination. */
  edges: Array<SubscriptionStatesEdge>;
  /** A list of `SubscriptionState` objects. */
  nodes: Array<SubscriptionState>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `SubscriptionState` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `SubscriptionState` edge in the connection. */
export type SubscriptionStatesEdge = {
  __typename?: 'SubscriptionStatesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `SubscriptionState` at the end of the edge. */
  node: SubscriptionState;
};

/** Methods to use when ordering `SubscriptionState`. */
export enum SubscriptionStatesOrderBy {
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ValueAsc = 'VALUE_ASC',
  ValueDesc = 'VALUE_DESC'
}

export type Support = {
  __typename?: 'Support';
  buttonTitle?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  createdAt: Scalars['Datetime'];
  createdBy?: Maybe<Scalars['UUID']>;
  data?: Maybe<Scalars['JSON']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  link?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  /** Reads a single `SupportCategory` that is related to this `Support`. */
  supportCategory?: Maybe<SupportCategory>;
  supportCategoryId?: Maybe<Scalars['UUID']>;
  title: Scalars['String'];
  type: Scalars['String'];
  updatedAt: Scalars['Datetime'];
  /** Reads a single `User` that is related to this `Support`. */
  userByCreatedBy?: Maybe<User>;
};

/** A connection to a list of `SupportCategory` values. */
export type SupportCategoriesConnection = {
  __typename?: 'SupportCategoriesConnection';
  /** A list of edges which contains the `SupportCategory` and cursor to aid in pagination. */
  edges: Array<SupportCategoriesEdge>;
  /** A list of `SupportCategory` objects. */
  nodes: Array<SupportCategory>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `SupportCategory` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `SupportCategory` edge in the connection. */
export type SupportCategoriesEdge = {
  __typename?: 'SupportCategoriesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `SupportCategory` at the end of the edge. */
  node: SupportCategory;
};

/** Methods to use when ordering `SupportCategory`. */
export enum SupportCategoriesOrderBy {
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  OrderAsc = 'ORDER_ASC',
  OrderDesc = 'ORDER_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  SlugAsc = 'SLUG_ASC',
  SlugDesc = 'SLUG_DESC'
}

export type SupportCategory = {
  __typename?: 'SupportCategory';
  createdAt: Scalars['Datetime'];
  createdBy?: Maybe<Scalars['UUID']>;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  slug: Scalars['String'];
  /** Reads and enables pagination through a set of `Support`. */
  supports: SupportsConnection;
  type?: Maybe<Scalars['String']>;
  updatedAt: Scalars['Datetime'];
  /** Reads a single `User` that is related to this `SupportCategory`. */
  userByCreatedBy?: Maybe<User>;
};


export type SupportCategorySupportsArgs = {
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  condition?: Maybe<SupportCondition>;
  filter?: Maybe<SupportFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<SupportsOrderBy>>;
};

/**
 * A condition to be used against `SupportCategory` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type SupportCategoryCondition = {
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `name` field. */
  name?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `slug` field. */
  slug?: Maybe<Scalars['String']>;
};

/** A filter to be used against `SupportCategory` object types. All fields are combined with a logical ‘and.’ */
export type SupportCategoryFilter = {
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<SupportCategoryFilter>>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: Maybe<UuidFilter>;
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `name` field. */
  name?: Maybe<StringFilter>;
  /** Negates the expression. */
  not?: Maybe<SupportCategoryFilter>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<SupportCategoryFilter>>;
  /** Filter by the object’s `slug` field. */
  slug?: Maybe<StringFilter>;
};

/** An input for mutations affecting `SupportCategory` */
export type SupportCategoryInput = {
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  icon?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  slug: Scalars['String'];
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
};

/** Represents an update to a `SupportCategory`. Fields that are set will be updated. */
export type SupportCategoryPatch = {
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  icon?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
};

/** A condition to be used against `Support` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type SupportCondition = {
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `order` field. */
  order?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `slug` field. */
  slug?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `supportCategoryId` field. */
  supportCategoryId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `title` field. */
  title?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `type` field. */
  type?: Maybe<Scalars['String']>;
};

/** A filter to be used against `Support` object types. All fields are combined with a logical ‘and.’ */
export type SupportFilter = {
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<SupportFilter>>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: Maybe<UuidFilter>;
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Negates the expression. */
  not?: Maybe<SupportFilter>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<SupportFilter>>;
  /** Filter by the object’s `order` field. */
  order?: Maybe<IntFilter>;
  /** Filter by the object’s `slug` field. */
  slug?: Maybe<StringFilter>;
  /** Filter by the object’s `supportCategoryId` field. */
  supportCategoryId?: Maybe<UuidFilter>;
  /** Filter by the object’s `title` field. */
  title?: Maybe<StringFilter>;
  /** Filter by the object’s `type` field. */
  type?: Maybe<StringFilter>;
};

/** An input for mutations affecting `Support` */
export type SupportInput = {
  buttonTitle?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  data?: Maybe<Scalars['JSON']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  link?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  supportCategoryId?: Maybe<Scalars['UUID']>;
  title: Scalars['String'];
  type: Scalars['String'];
  updatedAt?: Maybe<Scalars['Datetime']>;
};

/** Represents an update to a `Support`. Fields that are set will be updated. */
export type SupportPatch = {
  buttonTitle?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['UUID']>;
  data?: Maybe<Scalars['JSON']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  link?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  supportCategoryId?: Maybe<Scalars['UUID']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
};

/** A connection to a list of `Support` values. */
export type SupportsConnection = {
  __typename?: 'SupportsConnection';
  /** A list of edges which contains the `Support` and cursor to aid in pagination. */
  edges: Array<SupportsEdge>;
  /** A list of `Support` objects. */
  nodes: Array<Support>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Support` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Support` edge in the connection. */
export type SupportsEdge = {
  __typename?: 'SupportsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Support` at the end of the edge. */
  node: Support;
};

/** Methods to use when ordering `Support`. */
export enum SupportsOrderBy {
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  OrderAsc = 'ORDER_ASC',
  OrderDesc = 'ORDER_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  SlugAsc = 'SLUG_ASC',
  SlugDesc = 'SLUG_DESC',
  SupportCategoryIdAsc = 'SUPPORT_CATEGORY_ID_ASC',
  SupportCategoryIdDesc = 'SUPPORT_CATEGORY_ID_DESC',
  TitleAsc = 'TITLE_ASC',
  TitleDesc = 'TITLE_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC'
}

export type TblCheckout = {
  __typename?: 'TblCheckout';
  billingAddress?: Maybe<Scalars['JSON']>;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  discountAmount: Scalars['Float'];
  email?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  paymentMethod: Scalars['JSON'];
  saleorOrderId: Scalars['String'];
  shipingMethod?: Maybe<Scalars['JSON']>;
  shippingAddress?: Maybe<Scalars['JSON']>;
  shippingAmount: Scalars['Float'];
  status: Scalars['String'];
  subTotalAmount: Scalars['Float'];
  totalAmount: Scalars['Float'];
  totalQuantity: Scalars['Float'];
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Reads a single `User` that is related to this `TblCheckout`. */
  user?: Maybe<User>;
  userId: Scalars['UUID'];
  voucher?: Maybe<Scalars['JSON']>;
  warranty?: Maybe<Scalars['JSON']>;
  warrantyAmount?: Maybe<Scalars['Float']>;
};

/**
 * A condition to be used against `TblCheckout` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type TblCheckoutCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: Maybe<Scalars['UUID']>;
};

/** A filter to be used against `TblCheckout` object types. All fields are combined with a logical ‘and.’ */
export type TblCheckoutFilter = {
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<TblCheckoutFilter>>;
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Negates the expression. */
  not?: Maybe<TblCheckoutFilter>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<TblCheckoutFilter>>;
  /** Filter by the object’s `userId` field. */
  userId?: Maybe<UuidFilter>;
};

/** A connection to a list of `TblCheckout` values. */
export type TblCheckoutsConnection = {
  __typename?: 'TblCheckoutsConnection';
  /** A list of edges which contains the `TblCheckout` and cursor to aid in pagination. */
  edges: Array<TblCheckoutsEdge>;
  /** A list of `TblCheckout` objects. */
  nodes: Array<TblCheckout>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `TblCheckout` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `TblCheckout` edge in the connection. */
export type TblCheckoutsEdge = {
  __typename?: 'TblCheckoutsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `TblCheckout` at the end of the edge. */
  node: TblCheckout;
};

/** Methods to use when ordering `TblCheckout`. */
export enum TblCheckoutsOrderBy {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC'
}

export type TrackingAddToCartInput = {
  email?: Maybe<Scalars['String']>;
  eventId?: Maybe<Scalars['String']>;
  product?: Maybe<ProductParameters>;
  tracking?: Maybe<TrackingParameters>;
};

export type TrackingGaInput = {
  clientId: Scalars['String'];
  eventName: Scalars['String'];
  payload: Scalars['JSON'];
  userId?: Maybe<Scalars['String']>;
};

export type TrackingInitiateCheckoutInput = {
  email?: Maybe<Scalars['String']>;
  eventId?: Maybe<Scalars['String']>;
  totalAmount?: Maybe<Scalars['Int']>;
  tracking?: Maybe<TrackingParameters>;
};

export type TrackingKlaviyoFormSubmitInput = {
  email?: Maybe<Scalars['String']>;
  eventId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  tracking?: Maybe<TrackingParameters>;
};

export type TrackingPageViewInput = {
  fbc?: Maybe<Scalars['String']>;
  fbp?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
};

export type TrackingParameters = {
  fbc?: Maybe<Scalars['String']>;
  fbp?: Maybe<Scalars['String']>;
  ip?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  sourceUrl?: Maybe<Scalars['String']>;
  userAgent?: Maybe<Scalars['String']>;
};

export type TrackingPurchasedInput = {
  clientId?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  products?: Maybe<Array<Maybe<ProductParameters>>>;
  revenue?: Maybe<Scalars['Int']>;
  shippingAmount?: Maybe<Scalars['Int']>;
  tax?: Maybe<Scalars['Int']>;
  tracking?: Maybe<TrackingParameters>;
  transactionId?: Maybe<Scalars['String']>;
  voucher?: Maybe<Scalars['String']>;
};

export type TrackingPurchasedNorthbeamInput = {
  currency?: Maybe<Scalars['String']>;
  customerEmail?: Maybe<Scalars['String']>;
  customerId: Scalars['String'];
  customerName?: Maybe<Scalars['String']>;
  customerPhoneNumber?: Maybe<Scalars['String']>;
  customerShippingAddress?: Maybe<CustomerAddressNorthbeamType>;
  discountAmount?: Maybe<Scalars['Float']>;
  discountCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
  isRecurringOrder?: Maybe<Scalars['Boolean']>;
  orderId: Scalars['String'];
  orderTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  products?: Maybe<Array<Maybe<ProductItemNorthbeamType>>>;
  purchaseTotal?: Maybe<Scalars['Float']>;
  shippingCost?: Maybe<Scalars['Float']>;
  tax?: Maybe<Scalars['Float']>;
  timeOfPurchase?: Maybe<Scalars['String']>;
};

export type TrackingSuccessPayload = {
  __typename?: 'TrackingSuccessPayload';
  code?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export type TrackingViewContentInput = {
  email?: Maybe<Scalars['String']>;
  eventId?: Maybe<Scalars['String']>;
  productIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  tracking?: Maybe<TrackingParameters>;
};

/** All input for the `transferOrganizationBillingContact` mutation. */
export type TransferOrganizationBillingContactInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  organizationId: Scalars['UUID'];
  userId: Scalars['UUID'];
};

/** The output of our `transferOrganizationBillingContact` mutation. */
export type TransferOrganizationBillingContactPayload = {
  __typename?: 'TransferOrganizationBillingContactPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
  /** An edge for our `Organization`. May be used by Relay 1. */
  organizationEdge?: Maybe<OrganizationsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our `transferOrganizationBillingContact` mutation. */
export type TransferOrganizationBillingContactPayloadOrganizationEdgeArgs = {
  orderBy?: Maybe<Array<OrganizationsOrderBy>>;
};

/** All input for the `transferOrganizationOwnership` mutation. */
export type TransferOrganizationOwnershipInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  organizationId: Scalars['UUID'];
  userId: Scalars['UUID'];
};

/** The output of our `transferOrganizationOwnership` mutation. */
export type TransferOrganizationOwnershipPayload = {
  __typename?: 'TransferOrganizationOwnershipPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
  /** An edge for our `Organization`. May be used by Relay 1. */
  organizationEdge?: Maybe<OrganizationsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our `transferOrganizationOwnership` mutation. */
export type TransferOrganizationOwnershipPayloadOrganizationEdgeArgs = {
  orderBy?: Maybe<Array<OrganizationsOrderBy>>;
};

export type TriggerReferralInput = {
  participantId?: Maybe<Scalars['String']>;
};

export type TriggerReferralPayload = {
  __typename?: 'TriggerReferralPayload';
  code?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};


/** A filter to be used against UUID fields. All fields are combined with a logical ‘and.’ */
export type UuidFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<Scalars['UUID']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<Scalars['UUID']>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<Scalars['UUID']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<Scalars['UUID']>;
  /** Included in the specified list. */
  in?: Maybe<Array<Scalars['UUID']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: Maybe<Scalars['UUID']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<Scalars['UUID']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<Scalars['UUID']>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<Scalars['UUID']>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<Scalars['UUID']>>;
};

/** All input for the `updateAdminRoles` mutation. */
export type UpdateAdminRolesInput = {
  adminPrivileges: Scalars['JSON'];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  role: Scalars['String'];
};

/** The output of our `updateAdminRoles` mutation. */
export type UpdateAdminRolesPayload = {
  __typename?: 'UpdateAdminRolesPayload';
  boolean?: Maybe<Scalars['Boolean']>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `updateAvironSubscription` mutation. */
export type UpdateAvironSubscriptionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `AvironSubscription` being updated. */
  patch: AvironSubscriptionPatch;
};

/** The output of our update `AvironSubscription` mutation. */
export type UpdateAvironSubscriptionPayload = {
  __typename?: 'UpdateAvironSubscriptionPayload';
  /** The `AvironSubscription` that was updated by this mutation. */
  avironSubscription?: Maybe<AvironSubscription>;
  /** An edge for our `AvironSubscription`. May be used by Relay 1. */
  avironSubscriptionEdge?: Maybe<AvironSubscriptionsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `User` that is related to this `AvironSubscription`. */
  customer?: Maybe<User>;
  /** Reads a single `Machine` that is related to this `AvironSubscription`. */
  machine?: Maybe<Machine>;
  /** Reads a single `Plan` that is related to this `AvironSubscription`. */
  plan?: Maybe<Plan>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our update `AvironSubscription` mutation. */
export type UpdateAvironSubscriptionPayloadAvironSubscriptionEdgeArgs = {
  orderBy?: Maybe<Array<AvironSubscriptionsOrderBy>>;
};

/** All input for the `updateBetaRequest` mutation. */
export type UpdateBetaRequestInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `BetaRequest` being updated. */
  patch: BetaRequestPatch;
};

/** The output of our update `BetaRequest` mutation. */
export type UpdateBetaRequestPayload = {
  __typename?: 'UpdateBetaRequestPayload';
  /** The `BetaRequest` that was updated by this mutation. */
  betaRequest?: Maybe<BetaRequest>;
  /** An edge for our `BetaRequest`. May be used by Relay 1. */
  betaRequestEdge?: Maybe<BetaRequestsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `BetaRequest`. */
  user?: Maybe<User>;
};


/** The output of our update `BetaRequest` mutation. */
export type UpdateBetaRequestPayloadBetaRequestEdgeArgs = {
  orderBy?: Maybe<Array<BetaRequestsOrderBy>>;
};

/** All input for the `updateBlogCategory` mutation. */
export type UpdateBlogCategoryInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `BlogCategory` being updated. */
  patch: BlogCategoryPatch;
};

/** The output of our update `BlogCategory` mutation. */
export type UpdateBlogCategoryPayload = {
  __typename?: 'UpdateBlogCategoryPayload';
  /** The `BlogCategory` that was updated by this mutation. */
  blogCategory?: Maybe<BlogCategory>;
  /** An edge for our `BlogCategory`. May be used by Relay 1. */
  blogCategoryEdge?: Maybe<BlogCategoriesEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our update `BlogCategory` mutation. */
export type UpdateBlogCategoryPayloadBlogCategoryEdgeArgs = {
  orderBy?: Maybe<Array<BlogCategoriesOrderBy>>;
};

/** All input for the `updateChallenge` mutation. */
export type UpdateChallengeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `Challenge` being updated. */
  patch: ChallengePatch;
};

/** The output of our update `Challenge` mutation. */
export type UpdateChallengePayload = {
  __typename?: 'UpdateChallengePayload';
  /** The `Challenge` that was updated by this mutation. */
  challenge?: Maybe<Challenge>;
  /** An edge for our `Challenge`. May be used by Relay 1. */
  challengeEdge?: Maybe<ChallengesEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our update `Challenge` mutation. */
export type UpdateChallengePayloadChallengeEdgeArgs = {
  orderBy?: Maybe<Array<ChallengesOrderBy>>;
};

/** All input for the `updateDealerLocation` mutation. */
export type UpdateDealerLocationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `DealerLocation` being updated. */
  patch: DealerLocationPatch;
};

/** The output of our update `DealerLocation` mutation. */
export type UpdateDealerLocationPayload = {
  __typename?: 'UpdateDealerLocationPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `DealerLocation` that was updated by this mutation. */
  dealerLocation?: Maybe<DealerLocation>;
  /** An edge for our `DealerLocation`. May be used by Relay 1. */
  dealerLocationEdge?: Maybe<DealerLocationsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our update `DealerLocation` mutation. */
export type UpdateDealerLocationPayloadDealerLocationEdgeArgs = {
  orderBy?: Maybe<Array<DealerLocationsOrderBy>>;
};

/** All input for the `updateExperienceCategory` mutation. */
export type UpdateExperienceCategoryInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `ExperienceCategory` being updated. */
  patch: ExperienceCategoryPatch;
};

/** The output of our update `ExperienceCategory` mutation. */
export type UpdateExperienceCategoryPayload = {
  __typename?: 'UpdateExperienceCategoryPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `ExperienceCategory` that was updated by this mutation. */
  experienceCategory?: Maybe<ExperienceCategory>;
  /** An edge for our `ExperienceCategory`. May be used by Relay 1. */
  experienceCategoryEdge?: Maybe<ExperienceCategoriesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our update `ExperienceCategory` mutation. */
export type UpdateExperienceCategoryPayloadExperienceCategoryEdgeArgs = {
  orderBy?: Maybe<Array<ExperienceCategoriesOrderBy>>;
};

/** All input for the `updateExperienceDifficulty` mutation. */
export type UpdateExperienceDifficultyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `ExperienceDifficulty` being updated. */
  patch: ExperienceDifficultyPatch;
  value: Scalars['String'];
};

/** The output of our update `ExperienceDifficulty` mutation. */
export type UpdateExperienceDifficultyPayload = {
  __typename?: 'UpdateExperienceDifficultyPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `ExperienceDifficulty` that was updated by this mutation. */
  experienceDifficulty?: Maybe<ExperienceDifficulty>;
  /** An edge for our `ExperienceDifficulty`. May be used by Relay 1. */
  experienceDifficultyEdge?: Maybe<ExperienceDifficultiesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our update `ExperienceDifficulty` mutation. */
export type UpdateExperienceDifficultyPayloadExperienceDifficultyEdgeArgs = {
  orderBy?: Maybe<Array<ExperienceDifficultiesOrderBy>>;
};

/** All input for the `updateExperience` mutation. */
export type UpdateExperienceInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `Experience` being updated. */
  patch: ExperiencePatch;
};

/** The output of our update `Experience` mutation. */
export type UpdateExperiencePayload = {
  __typename?: 'UpdateExperiencePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Experience` that was updated by this mutation. */
  experience?: Maybe<Experience>;
  /** Reads a single `ExperienceCategory` that is related to this `Experience`. */
  experienceCategory?: Maybe<ExperienceCategory>;
  /** An edge for our `Experience`. May be used by Relay 1. */
  experienceEdge?: Maybe<ExperiencesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our update `Experience` mutation. */
export type UpdateExperiencePayloadExperienceEdgeArgs = {
  orderBy?: Maybe<Array<ExperiencesOrderBy>>;
};

/** All input for the `updateMachine` mutation. */
export type UpdateMachineInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  machineId: Scalars['String'];
  /** An object where the defined keys will be set on the `Machine` being updated. */
  patch: MachinePatch;
};

/** The output of our update `Machine` mutation. */
export type UpdateMachinePayload = {
  __typename?: 'UpdateMachinePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Machine` that was updated by this mutation. */
  machine?: Maybe<Machine>;
  /** An edge for our `Machine`. May be used by Relay 1. */
  machineEdge?: Maybe<MachinesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our update `Machine` mutation. */
export type UpdateMachinePayloadMachineEdgeArgs = {
  orderBy?: Maybe<Array<MachinesOrderBy>>;
};

export type UpdateManualSubscriptionInput = {
  id: Scalars['String'];
  isSendMail?: Maybe<Scalars['Boolean']>;
  patch?: Maybe<UpdateManualSubscriptionPatchFields>;
};

export type UpdateManualSubscriptionPatchFields = {
  currentPeriodEnd?: Maybe<Scalars['Datetime']>;
  currentPeriodStart?: Maybe<Scalars['Datetime']>;
  planId?: Maybe<Scalars['Int']>;
  state?: Maybe<Scalars['String']>;
};

/** All input for the `updateOrganization` mutation. */
export type UpdateOrganizationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `Organization` being updated. */
  patch: OrganizationPatch;
};

/** The output of our update `Organization` mutation. */
export type UpdateOrganizationPayload = {
  __typename?: 'UpdateOrganizationPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Organization` that was updated by this mutation. */
  organization?: Maybe<Organization>;
  /** An edge for our `Organization`. May be used by Relay 1. */
  organizationEdge?: Maybe<OrganizationsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our update `Organization` mutation. */
export type UpdateOrganizationPayloadOrganizationEdgeArgs = {
  orderBy?: Maybe<Array<OrganizationsOrderBy>>;
};

export type UpdateParticipantInput = {
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  referredBy?: Maybe<Scalars['String']>;
};

export type UpdateParticipantPayload = {
  __typename?: 'UpdateParticipantPayload';
  code?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

/** All input for the `updatePlan` mutation. */
export type UpdatePlanInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `Plan` being updated. */
  patch: PlanPatch;
};

/** The output of our update `Plan` mutation. */
export type UpdatePlanPayload = {
  __typename?: 'UpdatePlanPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `CustomerType` that is related to this `Plan`. */
  customerTypeByCustomerType?: Maybe<CustomerType>;
  /** The `Plan` that was updated by this mutation. */
  plan?: Maybe<Plan>;
  /** An edge for our `Plan`. May be used by Relay 1. */
  planEdge?: Maybe<PlansEdge>;
  /** Reads a single `PlanType` that is related to this `Plan`. */
  planTypeByType?: Maybe<PlanType>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our update `Plan` mutation. */
export type UpdatePlanPayloadPlanEdgeArgs = {
  orderBy?: Maybe<Array<PlansOrderBy>>;
};

/** All input for the `updatePost` mutation. */
export type UpdatePostInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `Post` being updated. */
  patch: PostPatch;
};

/** The output of our update `Post` mutation. */
export type UpdatePostPayload = {
  __typename?: 'UpdatePostPayload';
  /** Reads a single `BlogCategory` that is related to this `Post`. */
  blogCategory?: Maybe<BlogCategory>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Post` that was updated by this mutation. */
  post?: Maybe<Post>;
  /** An edge for our `Post`. May be used by Relay 1. */
  postEdge?: Maybe<PostsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our update `Post` mutation. */
export type UpdatePostPayloadPostEdgeArgs = {
  orderBy?: Maybe<Array<PostsOrderBy>>;
};

/** All input for the `updatePress` mutation. */
export type UpdatePressInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `Press` being updated. */
  patch: PressPatch;
};

/** The output of our update `Press` mutation. */
export type UpdatePressPayload = {
  __typename?: 'UpdatePressPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Press` that was updated by this mutation. */
  press?: Maybe<Press>;
  /** An edge for our `Press`. May be used by Relay 1. */
  pressEdge?: Maybe<PressesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our update `Press` mutation. */
export type UpdatePressPayloadPressEdgeArgs = {
  orderBy?: Maybe<Array<PressesOrderBy>>;
};

/** All input for the `updateSubscriptionUser` mutation. */
export type UpdateSubscriptionUserInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  subscriptionId: Scalars['UUID'];
};

/** The output of our `updateSubscriptionUser` mutation. */
export type UpdateSubscriptionUserPayload = {
  __typename?: 'UpdateSubscriptionUserPayload';
  avironSubscription?: Maybe<AvironSubscription>;
  /** An edge for our `AvironSubscription`. May be used by Relay 1. */
  avironSubscriptionEdge?: Maybe<AvironSubscriptionsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `User` that is related to this `AvironSubscription`. */
  customer?: Maybe<User>;
  /** Reads a single `Machine` that is related to this `AvironSubscription`. */
  machine?: Maybe<Machine>;
  /** Reads a single `Plan` that is related to this `AvironSubscription`. */
  plan?: Maybe<Plan>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our `updateSubscriptionUser` mutation. */
export type UpdateSubscriptionUserPayloadAvironSubscriptionEdgeArgs = {
  orderBy?: Maybe<Array<AvironSubscriptionsOrderBy>>;
};

/** All input for the `updateSupportCategory` mutation. */
export type UpdateSupportCategoryInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `SupportCategory` being updated. */
  patch: SupportCategoryPatch;
};

/** The output of our update `SupportCategory` mutation. */
export type UpdateSupportCategoryPayload = {
  __typename?: 'UpdateSupportCategoryPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `SupportCategory` that was updated by this mutation. */
  supportCategory?: Maybe<SupportCategory>;
  /** An edge for our `SupportCategory`. May be used by Relay 1. */
  supportCategoryEdge?: Maybe<SupportCategoriesEdge>;
  /** Reads a single `User` that is related to this `SupportCategory`. */
  userByCreatedBy?: Maybe<User>;
};


/** The output of our update `SupportCategory` mutation. */
export type UpdateSupportCategoryPayloadSupportCategoryEdgeArgs = {
  orderBy?: Maybe<Array<SupportCategoriesOrderBy>>;
};

/** All input for the `updateSupport` mutation. */
export type UpdateSupportInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `Support` being updated. */
  patch: SupportPatch;
};

/** The output of our update `Support` mutation. */
export type UpdateSupportPayload = {
  __typename?: 'UpdateSupportPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `Support` that was updated by this mutation. */
  support?: Maybe<Support>;
  /** Reads a single `SupportCategory` that is related to this `Support`. */
  supportCategory?: Maybe<SupportCategory>;
  /** An edge for our `Support`. May be used by Relay 1. */
  supportEdge?: Maybe<SupportsEdge>;
  /** Reads a single `User` that is related to this `Support`. */
  userByCreatedBy?: Maybe<User>;
};


/** The output of our update `Support` mutation. */
export type UpdateSupportPayloadSupportEdgeArgs = {
  orderBy?: Maybe<Array<SupportsOrderBy>>;
};

export type UpdateUserAccount = {
  avatarUrl?: Maybe<Scalars['String']>;
  firstname?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
  lastname?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

export type UpdateUserCustomInput = {
  data?: Maybe<Scalars['JSON']>;
};

/** All input for the `updateUserEmail` mutation. */
export type UpdateUserEmailInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `UserEmail` being updated. */
  patch: UserEmailPatch;
};

/** The output of our update `UserEmail` mutation. */
export type UpdateUserEmailPayload = {
  __typename?: 'UpdateUserEmailPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `UserEmail`. */
  user?: Maybe<User>;
  /** The `UserEmail` that was updated by this mutation. */
  userEmail?: Maybe<UserEmail>;
  /** An edge for our `UserEmail`. May be used by Relay 1. */
  userEmailEdge?: Maybe<UserEmailsEdge>;
};


/** The output of our update `UserEmail` mutation. */
export type UpdateUserEmailPayloadUserEmailEdgeArgs = {
  orderBy?: Maybe<Array<UserEmailsOrderBy>>;
};

/** All input for the `updateUser` mutation. */
export type UpdateUserInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Unique identifier for the user. */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `User` being updated. */
  patch: UserPatch;
};

/** The output of our update `User` mutation. */
export type UpdateUserPayload = {
  __typename?: 'UpdateUserPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `User` that was updated by this mutation. */
  user?: Maybe<User>;
  /** An edge for our `User`. May be used by Relay 1. */
  userEdge?: Maybe<UsersEdge>;
};


/** The output of our update `User` mutation. */
export type UpdateUserPayloadUserEdgeArgs = {
  orderBy?: Maybe<Array<UsersOrderBy>>;
};

export type UpdateUserWithStripeCustomerPayload = {
  __typename?: 'UpdateUserWithStripeCustomerPayload';
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  profile?: Maybe<Scalars['JSON']>;
};

/** A user who can log in to the application. */
export type User = {
  __typename?: 'User';
  /** Reads and enables pagination through a set of `AdminHistory`. */
  adminHistories: AdminHistoriesConnection;
  /** Reads and enables pagination through a set of `AdminPrivilege`. */
  adminPrivileges: AdminPrivilegesConnection;
  /** Reads and enables pagination through a set of `AdminRole`. */
  adminRoles: AdminRolesConnection;
  /** Optional avatar URL. */
  avatarUrl?: Maybe<Scalars['String']>;
  /** Reads and enables pagination through a set of `AvironSubscription`. */
  avironSubscriptionsByCustomerId: AvironSubscriptionsConnection;
  /** Reads and enables pagination through a set of `BetaRequest`. */
  betaRequests: BetaRequestsConnection;
  createdAt: Scalars['Datetime'];
  firstName?: Maybe<Scalars['String']>;
  hasPassword?: Maybe<Scalars['Boolean']>;
  /** Unique identifier for the user. */
  id: Scalars['UUID'];
  /** If true, the user has elevated privileges. */
  isAdmin: Scalars['Boolean'];
  isVerified: Scalars['Boolean'];
  lastName?: Maybe<Scalars['String']>;
  /** Public-facing name (or pseudonym) of the user. */
  name?: Maybe<Scalars['String']>;
  /** Reads and enables pagination through a set of `OrganizationInvitation`. */
  organizationInvitations: OrganizationInvitationsConnection;
  /** Reads and enables pagination through a set of `OrganizationMembership`. */
  organizationMemberships: OrganizationMembershipsConnection;
  profile?: Maybe<Scalars['JSON']>;
  /** Reads and enables pagination through a set of `SubscriptionPause`. */
  subscriptionPauses: SubscriptionPausesConnection;
  /** Reads and enables pagination through a set of `SupportCategory`. */
  supportCategoriesByCreatedBy: SupportCategoriesConnection;
  /** Reads and enables pagination through a set of `Support`. */
  supportsByCreatedBy: SupportsConnection;
  /** Reads and enables pagination through a set of `TblCheckout`. */
  tblCheckouts: TblCheckoutsConnection;
  updatedAt: Scalars['Datetime'];
  /** Reads and enables pagination through a set of `UserAuthentication`. */
  userAuthenticationsList: Array<UserAuthentication>;
  /** Reads and enables pagination through a set of `UserEmail`. */
  userEmails: UserEmailsConnection;
  /** Public-facing username (or 'handle') of the user. */
  username: Scalars['String'];
};


/** A user who can log in to the application. */
export type UserAdminHistoriesArgs = {
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  condition?: Maybe<AdminHistoryCondition>;
  filter?: Maybe<AdminHistoryFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<AdminHistoriesOrderBy>>;
};


/** A user who can log in to the application. */
export type UserAdminPrivilegesArgs = {
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  condition?: Maybe<AdminPrivilegeCondition>;
  filter?: Maybe<AdminPrivilegeFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<AdminPrivilegesOrderBy>>;
};


/** A user who can log in to the application. */
export type UserAdminRolesArgs = {
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  condition?: Maybe<AdminRoleCondition>;
  filter?: Maybe<AdminRoleFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<AdminRolesOrderBy>>;
};


/** A user who can log in to the application. */
export type UserAvironSubscriptionsByCustomerIdArgs = {
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  condition?: Maybe<AvironSubscriptionCondition>;
  filter?: Maybe<AvironSubscriptionFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<AvironSubscriptionsOrderBy>>;
};


/** A user who can log in to the application. */
export type UserBetaRequestsArgs = {
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  condition?: Maybe<BetaRequestCondition>;
  filter?: Maybe<BetaRequestFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<BetaRequestsOrderBy>>;
};


/** A user who can log in to the application. */
export type UserOrganizationInvitationsArgs = {
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  condition?: Maybe<OrganizationInvitationCondition>;
  filter?: Maybe<OrganizationInvitationFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<OrganizationInvitationsOrderBy>>;
};


/** A user who can log in to the application. */
export type UserOrganizationMembershipsArgs = {
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  condition?: Maybe<OrganizationMembershipCondition>;
  filter?: Maybe<OrganizationMembershipFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<OrganizationMembershipsOrderBy>>;
};


/** A user who can log in to the application. */
export type UserSubscriptionPausesArgs = {
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  condition?: Maybe<SubscriptionPauseCondition>;
  filter?: Maybe<SubscriptionPauseFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<SubscriptionPausesOrderBy>>;
};


/** A user who can log in to the application. */
export type UserSupportCategoriesByCreatedByArgs = {
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  condition?: Maybe<SupportCategoryCondition>;
  filter?: Maybe<SupportCategoryFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<SupportCategoriesOrderBy>>;
};


/** A user who can log in to the application. */
export type UserSupportsByCreatedByArgs = {
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  condition?: Maybe<SupportCondition>;
  filter?: Maybe<SupportFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<SupportsOrderBy>>;
};


/** A user who can log in to the application. */
export type UserTblCheckoutsArgs = {
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  condition?: Maybe<TblCheckoutCondition>;
  filter?: Maybe<TblCheckoutFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<TblCheckoutsOrderBy>>;
};


/** A user who can log in to the application. */
export type UserUserAuthenticationsListArgs = {
  condition?: Maybe<UserAuthenticationCondition>;
  filter?: Maybe<UserAuthenticationFilter>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<UserAuthenticationsOrderBy>>;
};


/** A user who can log in to the application. */
export type UserUserEmailsArgs = {
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  condition?: Maybe<UserEmailCondition>;
  filter?: Maybe<UserEmailFilter>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<UserEmailsOrderBy>>;
};

/** Contains information about the login providers this user has used, so that they may disconnect them should they wish. */
export type UserAuthentication = {
  __typename?: 'UserAuthentication';
  createdAt: Scalars['Datetime'];
  id: Scalars['UUID'];
  /** A unique identifier for the user within the login service. */
  identifier: Scalars['String'];
  /** The login service used, e.g. `twitter` or `github`. */
  service: Scalars['String'];
  updatedAt: Scalars['Datetime'];
  /** Reads a single `User` that is related to this `UserAuthentication`. */
  user?: Maybe<User>;
  userId: Scalars['UUID'];
};

/**
 * A condition to be used against `UserAuthentication` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type UserAuthenticationCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `service` field. */
  service?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: Maybe<Scalars['UUID']>;
};

/** A filter to be used against `UserAuthentication` object types. All fields are combined with a logical ‘and.’ */
export type UserAuthenticationFilter = {
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<UserAuthenticationFilter>>;
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Negates the expression. */
  not?: Maybe<UserAuthenticationFilter>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<UserAuthenticationFilter>>;
  /** Filter by the object’s `service` field. */
  service?: Maybe<StringFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: Maybe<UuidFilter>;
};

/** Methods to use when ordering `UserAuthentication`. */
export enum UserAuthenticationsOrderBy {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ServiceAsc = 'SERVICE_ASC',
  ServiceDesc = 'SERVICE_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC'
}

/** Information about a user's email address. */
export type UserEmail = {
  __typename?: 'UserEmail';
  createdAt: Scalars['Datetime'];
  /** The users email address, in `a@b.c` format. */
  email: Scalars['String'];
  id: Scalars['UUID'];
  isPrimary: Scalars['Boolean'];
  /**
   * True if the user has is_verified their email address (by clicking the link in
   * the email we sent them, or logging in with a social login provider), false otherwise.
   */
  isVerified: Scalars['Boolean'];
  updatedAt: Scalars['Datetime'];
  /** Reads a single `User` that is related to this `UserEmail`. */
  user?: Maybe<User>;
  userId: Scalars['UUID'];
};

/**
 * A condition to be used against `UserEmail` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type UserEmailCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `isPrimary` field. */
  isPrimary?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: Maybe<Scalars['UUID']>;
};

/** A filter to be used against `UserEmail` object types. All fields are combined with a logical ‘and.’ */
export type UserEmailFilter = {
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<UserEmailFilter>>;
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `isPrimary` field. */
  isPrimary?: Maybe<BooleanFilter>;
  /** Negates the expression. */
  not?: Maybe<UserEmailFilter>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<UserEmailFilter>>;
  /** Filter by the object’s `userId` field. */
  userId?: Maybe<UuidFilter>;
};

/** An input for mutations affecting `UserEmail` */
export type UserEmailInput = {
  /** The users email address, in `a@b.c` format. */
  email: Scalars['String'];
};

/** Represents an update to a `UserEmail`. Fields that are set will be updated. */
export type UserEmailPatch = {
  /** The users email address, in `a@b.c` format. */
  email?: Maybe<Scalars['String']>;
};

/** A connection to a list of `UserEmail` values. */
export type UserEmailsConnection = {
  __typename?: 'UserEmailsConnection';
  /** A list of edges which contains the `UserEmail` and cursor to aid in pagination. */
  edges: Array<UserEmailsEdge>;
  /** A list of `UserEmail` objects. */
  nodes: Array<UserEmail>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `UserEmail` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `UserEmail` edge in the connection. */
export type UserEmailsEdge = {
  __typename?: 'UserEmailsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `UserEmail` at the end of the edge. */
  node: UserEmail;
};

/** Methods to use when ordering `UserEmail`. */
export enum UserEmailsOrderBy {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IsPrimaryAsc = 'IS_PRIMARY_ASC',
  IsPrimaryDesc = 'IS_PRIMARY_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC'
}

/** A filter to be used against `User` object types. All fields are combined with a logical ‘and.’ */
export type UserFilter = {
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<UserFilter>>;
  /** Filter by the object’s `hasPassword` field. */
  hasPassword?: Maybe<BooleanFilter>;
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Negates the expression. */
  not?: Maybe<UserFilter>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<UserFilter>>;
  /** Filter by the object’s `username` field. */
  username?: Maybe<StringFilter>;
};

/** Represents an update to a `User`. Fields that are set will be updated. */
export type UserPatch = {
  /** Optional avatar URL. */
  avatarUrl?: Maybe<Scalars['String']>;
  /** Public-facing name (or pseudonym) of the user. */
  name?: Maybe<Scalars['String']>;
  profile?: Maybe<Scalars['JSON']>;
  /** Public-facing username (or 'handle') of the user. */
  username?: Maybe<Scalars['String']>;
};

export type UserSubscriptionPayload = {
  __typename?: 'UserSubscriptionPayload';
  event?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

/** A connection to a list of `User` values. */
export type UsersConnection = {
  __typename?: 'UsersConnection';
  /** A list of edges which contains the `User` and cursor to aid in pagination. */
  edges: Array<UsersEdge>;
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection. */
export type UsersEdge = {
  __typename?: 'UsersEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
};

/** Methods to use when ordering `User`. */
export enum UsersOrderBy {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UsernameAsc = 'USERNAME_ASC',
  UsernameDesc = 'USERNAME_DESC'
}

/** All input for the `verifyEmail` mutation. */
export type VerifyEmailInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  token: Scalars['String'];
  userEmailId: Scalars['UUID'];
};

/** The output of our `verifyEmail` mutation. */
export type VerifyEmailPayload = {
  __typename?: 'VerifyEmailPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  success?: Maybe<Scalars['Boolean']>;
};

export type AddContactInput = {
  email?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
};

export type AddLabelInput = {
  background?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type AdminMachineFields = {
  __typename?: 'adminMachineFields';
  count?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['String']>;
  machine_id?: Maybe<Scalars['String']>;
  machine_type?: Maybe<Scalars['String']>;
  mla_action?: Maybe<Scalars['String']>;
  mla_updated_at?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  serial_number?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
};

export type AdminMachineListInput = {
  limit?: Maybe<Scalars['Int']>;
  machineType?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  sort?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

export type AdminMachineListPayload = {
  __typename?: 'adminMachineListPayload';
  nodes?: Maybe<Array<Maybe<AdminMachineFields>>>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type AdminSubscriptionFields = {
  __typename?: 'adminSubscriptionFields';
  cancel_day?: Maybe<Scalars['String']>;
  checkout_item_subscription_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['String']>;
  current_period_end?: Maybe<Scalars['String']>;
  current_period_start?: Maybe<Scalars['String']>;
  customer_id?: Maybe<Scalars['String']>;
  customer_type?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  ended_at?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  is_pause_collection?: Maybe<Scalars['Boolean']>;
  machine_id?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  plan_id?: Maybe<Scalars['Int']>;
  plan_name?: Maybe<Scalars['String']>;
  plan_product_type?: Maybe<Scalars['String']>;
  plan_type?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['JSON']>;
  resume_at?: Maybe<Scalars['String']>;
  serial_number?: Maybe<Scalars['String']>;
  start_day?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  stripe_subscription_id?: Maybe<Scalars['String']>;
  subscription_token?: Maybe<Scalars['String']>;
  trial_end?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
};

export type AdminSubscriptionHistoriesFields = {
  __typename?: 'adminSubscriptionHistoriesFields';
  action?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['JSON']>;
};

export type AdminSubscriptionHistoriesInput = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  subscriptionId: Scalars['String'];
};

export type AdminSubscriptionHistoriesPayload = {
  __typename?: 'adminSubscriptionHistoriesPayload';
  nodes?: Maybe<Array<Maybe<AdminSubscriptionHistoriesFields>>>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type AdminSubscriptionListInput = {
  customerType?: Maybe<Scalars['String']>;
  endTime?: Maybe<Scalars['Date']>;
  isPauseCollection?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  planName?: Maybe<Scalars['String']>;
  planProductType?: Maybe<Scalars['String']>;
  planType?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  sort?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['Date']>;
  state?: Maybe<Scalars['String']>;
};

export type AdminSubscriptionListPayload = {
  __typename?: 'adminSubscriptionListPayload';
  nodes?: Maybe<Array<Maybe<AdminSubscriptionFields>>>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type AdminUserFields = {
  __typename?: 'adminUserFields';
  count?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

export type AdminUserListInput = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  sort?: Maybe<Scalars['String']>;
};

export type AdminUserListPayload = {
  __typename?: 'adminUserListPayload';
  nodes?: Maybe<Array<Maybe<AdminUserFields>>>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type CancelPaymentIntentInput = {
  intentId?: Maybe<Scalars['String']>;
};

export type CancelPaymentIntentParams = {
  cancellation_reason?: Maybe<Scalars['String']>;
};

export type CapturePaymentIntentInput = {
  intentId?: Maybe<Scalars['String']>;
};

export type CapturePaymentIntentParams = {
  amount_to_capture?: Maybe<Scalars['Int']>;
};

export type CheckSubscriptionFinanceFinishInput = {
  id: Scalars['String'];
};

export type CheckUserHaveFinanceSubscriptionInput = {
  userId: Scalars['String'];
};

export type ContactFields = {
  __typename?: 'contactFields';
  assign_admin?: Maybe<Scalars['String']>;
  assign_admin_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  labels_info?: Maybe<Array<Maybe<LabelInfoFields>>>;
  message?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
};

export type ContactListPayload = {
  __typename?: 'contactListPayload';
  nodes?: Maybe<Array<Maybe<ContactFields>>>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type CreateAndChargeLaterPaymentInput = {
  amount?: Maybe<Scalars['Int']>;
  currency?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  paymentMethodId?: Maybe<Scalars['String']>;
};

export type CreateCustomerInput = {
  address?: Maybe<AddressFields>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['Object']>;
  name?: Maybe<Scalars['String']>;
  payment_method?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  shipping?: Maybe<Scalars['Object']>;
};

export type CreatePaymentIntentInput = {
  amount?: Maybe<Scalars['Int']>;
  currency?: Maybe<Scalars['String']>;
  payment_method?: Maybe<Scalars['String']>;
};

export type CreateSessionInput = {
  orderAmount?: Maybe<Scalars['Int']>;
  orderLines?: Maybe<Array<Maybe<OrderLinesParameters>>>;
};

export type CreateSessionPayload = {
  __typename?: 'createSessionPayload';
  client_token?: Maybe<Scalars['String']>;
  payment_method_categories?: Maybe<Scalars['JSON']>;
  session_id?: Maybe<Scalars['String']>;
};

export type CreateSubscriptionInput = {
  customerId?: Maybe<Scalars['String']>;
  priceId?: Maybe<Scalars['String']>;
};

export type DeleteLabelInput = {
  id?: Maybe<Scalars['String']>;
};

export type GetContactListInput = {
  admin?: Maybe<Scalars['String']>;
  label_id?: Maybe<Array<Maybe<Scalars['String']>>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  sort?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type GetLabelDetailInput = {
  id?: Maybe<Scalars['String']>;
};

export type GetPaymentIntentsInput = {
  customer?: Maybe<Scalars['String']>;
  ending_before?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  starting_before?: Maybe<Scalars['String']>;
};

export type GetPaymentMethodOfCustomerInput = {
  customer?: Maybe<Scalars['String']>;
};

export type LabelFields = {
  __typename?: 'labelFields';
  background?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
};

export type LabelInfoFields = {
  __typename?: 'labelInfoFields';
  background?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type LabelListPayload = {
  __typename?: 'labelListPayload';
  nodes?: Maybe<Array<Maybe<LabelFields>>>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type OrderLinesParameters = {
  name?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  totalAmount?: Maybe<Scalars['Int']>;
  unitPrice?: Maybe<Scalars['Int']>;
};

export type RetriceCustomerInput = {
  currency?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
};

export type RetrivePaymentIntentInput = {
  intentId?: Maybe<Scalars['String']>;
};

export type SetupIntentExpressPaymentInput = {
  amount?: Maybe<Scalars['Int']>;
  currency?: Maybe<Scalars['String']>;
  customer?: Maybe<Scalars['String']>;
};

export type SetupIntentInput = {
  currency?: Maybe<Scalars['String']>;
  customer?: Maybe<Scalars['String']>;
};

export type SetupIntentKlarnaInput = {
  amount?: Maybe<Scalars['Int']>;
  billingDetails?: Maybe<Scalars['JSON']>;
  currency?: Maybe<Scalars['String']>;
  oldpIid?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['String']>;
  updateBilling?: Maybe<Scalars['Boolean']>;
};

export type StripeUpdateCustomerDefaultPaymentMethodInput = {
  currency?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  paymentMethod?: Maybe<Scalars['JSON']>;
  userId?: Maybe<Scalars['String']>;
};

export type StripeVerifyChangePaymentMethodPayload = {
  __typename?: 'stripeVerifyChangePaymentMethodPayload';
  avironUserId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
};

export type StripeVerifyChangePaymentMethodTokenInput = {
  token: Scalars['String'];
};

export type TrackingSubscriptionActionInput = {
  action: Scalars['String'];
  id: Scalars['String'];
  payload?: Maybe<Scalars['JSON']>;
};

export type UpdateContactInput = {
  admin?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  labelDeleted?: Maybe<Array<Maybe<Scalars['String']>>>;
  newLabel?: Maybe<Array<Maybe<Scalars['String']>>>;
  note?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type UpdateIntentExpressPaymentInput = {
  amount?: Maybe<Scalars['Int']>;
  pIid?: Maybe<Scalars['String']>;
  pMid?: Maybe<Scalars['String']>;
};

export type UpdateLabelInput = {
  background?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type UserChangeUserInput = {
  emailToken: Scalars['String'];
};

export type AvironSubscriptionsQueryVariables = Exact<{
  userId?: Maybe<Scalars['UUID']>;
}>;


export type AvironSubscriptionsQuery = (
  { __typename?: 'Query' }
  & { avironSubscriptions?: Maybe<(
    { __typename?: 'AvironSubscriptionsConnection' }
    & { nodes: Array<(
      { __typename?: 'AvironSubscription' }
      & Pick<AvironSubscription, 'id' | 'subscriptionToken' | 'machineId' | 'startDay' | 'currentPeriodStart' | 'currentPeriodEnd' | 'isPauseCollection' | 'state' | 'remark'>
      & { machine?: Maybe<(
        { __typename?: 'Machine' }
        & Pick<Machine, 'machineType'>
      )>, plan?: Maybe<(
        { __typename?: 'Plan' }
        & Pick<Plan, 'id' | 'name' | 'appId' | 'customerType' | 'type' | 'productId'>
      )> }
    )> }
  )> }
);

export type CancelUserSubscriptionMutationVariables = Exact<{
  id: Scalars['String'];
  reason: Scalars['String'];
  reasonDetail?: Maybe<Scalars['String']>;
}>;


export type CancelUserSubscriptionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelUserSubscription'>
);

export type ChangePasswordMutationVariables = Exact<{
  oldPassword: Scalars['String'];
  newPassword: Scalars['String'];
}>;


export type ChangePasswordMutation = (
  { __typename?: 'Mutation' }
  & { changePassword?: Maybe<(
    { __typename?: 'ChangePasswordPayload' }
    & Pick<ChangePasswordPayload, 'success'>
  )> }
);

export type ChangeUserSubscriptionPlanMutationVariables = Exact<{
  id: Scalars['String'];
  planId: Scalars['Int'];
}>;


export type ChangeUserSubscriptionPlanMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'changeUserSubscriptionPlan'>
);

export type CheckCancelOfferAvaiableSubscriptionQueryVariables = Exact<{ [key: string]: never; }>;


export type CheckCancelOfferAvaiableSubscriptionQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'checkCancelOfferAvailableSubscription'>
);

export type CheckPasswordlessAccountMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type CheckPasswordlessAccountMutation = (
  { __typename?: 'Mutation' }
  & { checkPasswordlessAccount?: Maybe<(
    { __typename?: 'CheckPasswordlessAccountPayload' }
    & Pick<CheckPasswordlessAccountPayload, 'passwordless'>
  )> }
);

export type CheckSubscriptionFinanceFinishMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type CheckSubscriptionFinanceFinishMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'checkSubscriptionFinanceFinish'>
);

export type CheckUserHaveFinanceSubscriptionMutationVariables = Exact<{
  userId: Scalars['String'];
}>;


export type CheckUserHaveFinanceSubscriptionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'checkUserHaveFinanceSubscription'>
);

export type CheckUserJoinedQueryVariables = Exact<{
  userId: Scalars['String'];
}>;


export type CheckUserJoinedQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'checkUserJoinedBetaProgram'>
);

export type ClaimCancelOfferSubscriptionMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type ClaimCancelOfferSubscriptionMutation = (
  { __typename?: 'Mutation' }
  & { cancelOfferSubscription?: Maybe<(
    { __typename?: 'PausePaymentSubscriptionPayload' }
    & Pick<PausePaymentSubscriptionPayload, 'code' | 'key' | 'message'>
  )> }
);

export type CreateBetaRequestMutationVariables = Exact<{
  userId: Scalars['UUID'];
  discord: Scalars['String'];
  machineSerial: Scalars['String'];
  workoutPerWeek: Scalars['String'];
}>;


export type CreateBetaRequestMutation = (
  { __typename?: 'Mutation' }
  & { createBetaRequest?: Maybe<(
    { __typename?: 'CreateBetaRequestPayload' }
    & { betaRequest?: Maybe<(
      { __typename?: 'BetaRequest' }
      & Pick<BetaRequest, 'machineSerial' | 'cancelReason' | 'canceledAt' | 'createdAt' | 'discord' | 'id' | 'status' | 'userId' | 'updatedAt' | 'workoutPerWeek'>
    )> }
  )> }
);

export type CreateUploadUrlMutationVariables = Exact<{
  input: CreateUploadUrlInput;
}>;


export type CreateUploadUrlMutation = (
  { __typename?: 'Mutation' }
  & { createUploadUrl?: Maybe<(
    { __typename?: 'CreateUploadUrlPayload' }
    & Pick<CreateUploadUrlPayload, 'uploadUrl' | 'publicUrl'>
    & { fields: (
      { __typename?: 'PolicyFields' }
      & Pick<PolicyFields, 'key' | 'policy' | 'x_goog_algorithm' | 'x_goog_credential' | 'x_goog_date' | 'x_goog_signature'>
    ) }
  )> }
);

export type FitplanChooseSubscriptionMutationVariables = Exact<{
  id: Scalars['String'];
  planId: Scalars['Int'];
}>;


export type FitplanChooseSubscriptionMutation = (
  { __typename?: 'Mutation' }
  & { fitplanChooseSubscription?: Maybe<(
    { __typename?: 'FitplanChooseSubscriptionPayload' }
    & Pick<FitplanChooseSubscriptionPayload, 'code' | 'message' | 'key'>
  )> }
);

export type ForgotPasswordMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type ForgotPasswordMutation = (
  { __typename?: 'Mutation' }
  & { forgotPassword?: Maybe<(
    { __typename?: 'ForgotPasswordPayload' }
    & Pick<ForgotPasswordPayload, 'clientMutationId'>
  )> }
);

export type GetAvironSubscriptionQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type GetAvironSubscriptionQuery = (
  { __typename?: 'Query' }
  & { avironSubscription?: Maybe<(
    { __typename?: 'AvironSubscription' }
    & Pick<AvironSubscription, 'id' | 'subscriptionToken' | 'state' | 'startDay' | 'machineId' | 'planId' | 'resumeAt' | 'currentPeriodEnd' | 'isPauseCollection' | 'remark' | 'customerId'>
    & { machine?: Maybe<(
      { __typename?: 'Machine' }
      & Pick<Machine, 'machineType'>
    )>, plan?: Maybe<(
      { __typename?: 'Plan' }
      & Pick<Plan, 'id' | 'name' | 'enable' | 'type' | 'customerType' | 'interval' | 'amount' | 'isRecurring' | 'currency' | 'productId' | 'appId' | 'maxMachines' | 'appIds'>
    )> }
  )> }
);

export type GetAvironSubscriptionReceiptsQueryVariables = Exact<{
  id: Scalars['UUID'];
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type GetAvironSubscriptionReceiptsQuery = (
  { __typename?: 'Query' }
  & { subscriptionReceipts?: Maybe<(
    { __typename?: 'SubscriptionReceiptsConnection' }
    & Pick<SubscriptionReceiptsConnection, 'totalCount'>
    & { nodes: Array<(
      { __typename?: 'SubscriptionReceipt' }
      & Pick<SubscriptionReceipt, 'createdAt' | 'periodStart' | 'periodEnd' | 'amount' | 'status' | 'avironSubscriptionId'>
    )> }
  )> }
);

export type RetriveRequestsQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['UUID']>;
}>;


export type RetriveRequestsQuery = (
  { __typename?: 'Query' }
  & { betaRequests?: Maybe<(
    { __typename?: 'BetaRequestsConnection' }
    & Pick<BetaRequestsConnection, 'totalCount'>
    & { nodes: Array<(
      { __typename?: 'BetaRequest' }
      & Pick<BetaRequest, 'id' | 'userId' | 'machineSerial' | 'discord' | 'workoutPerWeek' | 'canceledAt' | 'cancelReason' | 'status' | 'createdAt' | 'updatedAt'>
    )> }
  )> }
);

export type CheckBetaIsLiveQueryVariables = Exact<{ [key: string]: never; }>;


export type CheckBetaIsLiveQuery = (
  { __typename?: 'Query' }
  & { checkBetaIsLive?: Maybe<(
    { __typename?: 'CheckBetaIsLivePayload' }
    & Pick<CheckBetaIsLivePayload, 'isLive' | 'startDate' | 'endDate'>
  )> }
);

export type GetCrmSessionQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCrmSessionQuery = (
  { __typename?: 'Query' }
  & { getCrmSession?: Maybe<(
    { __typename?: 'CrmServerSessionPayload' }
    & Pick<CrmServerSessionPayload, 'accessToken' | 'expires'>
  )> }
);

export type GetGameSessionQueryVariables = Exact<{ [key: string]: never; }>;


export type GetGameSessionQuery = (
  { __typename?: 'Query' }
  & { getGameSession?: Maybe<(
    { __typename?: 'GameSessionPayload' }
    & Pick<GameSessionPayload, 'accessToken' | 'expires'>
  )> }
);

export type GetIdTokenQueryVariables = Exact<{ [key: string]: never; }>;


export type GetIdTokenQuery = (
  { __typename?: 'Query' }
  & { getIdToken?: Maybe<(
    { __typename?: 'IdTokenPayload' }
    & Pick<IdTokenPayload, 'idToken'>
  )> }
);

export type GetSubscriptionsByUserQueryVariables = Exact<{
  limit: Scalars['Int'];
  offset: Scalars['Int'];
}>;


export type GetSubscriptionsByUserQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getSubscriptionsByUser'>
);

export type LeaveBetaProgramMutationVariables = Exact<{
  userId: Scalars['String'];
  reason: Scalars['String'];
}>;


export type LeaveBetaProgramMutation = (
  { __typename?: 'Mutation' }
  & { leaveBetaProgram?: Maybe<(
    { __typename?: 'LeaveBetaProgramPayload' }
    & Pick<LeaveBetaProgramPayload, 'status' | 'code'>
  )> }
);

export type LoginMutationVariables = Exact<{
  username: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { login?: Maybe<(
    { __typename?: 'LoginPayload' }
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'username' | 'name'>
    ) }
  )> }
);

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = (
  { __typename?: 'Mutation' }
  & { logout?: Maybe<(
    { __typename?: 'LogoutPayload' }
    & Pick<LogoutPayload, 'success'>
  )> }
);

export type PasswordlessLoginMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type PasswordlessLoginMutation = (
  { __typename?: 'Mutation' }
  & { passwordlessLogin?: Maybe<(
    { __typename?: 'PasswordlessLoginPayload' }
    & { userEmail?: Maybe<(
      { __typename?: 'UserEmail' }
      & Pick<UserEmail, 'id' | 'userId' | 'email'>
    )> }
  )> }
);

export type PasswordlessAuthenticateMutationVariables = Exact<{
  emailId: Scalars['UUID'];
  token: Scalars['String'];
}>;


export type PasswordlessAuthenticateMutation = (
  { __typename?: 'Mutation' }
  & { passwordlessAuthenticate?: Maybe<(
    { __typename?: 'PasswordlessAuthPayload' }
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'username' | 'name'>
    ) }
  )> }
);

export type PausePaymentSubscriptionMutationVariables = Exact<{
  id: Scalars['String'];
  behavior: Scalars['String'];
  resumesAt?: Maybe<Scalars['Date']>;
  reason?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
}>;


export type PausePaymentSubscriptionMutation = (
  { __typename?: 'Mutation' }
  & { pausePaymentSubscription?: Maybe<(
    { __typename?: 'PausePaymentSubscriptionPayload' }
    & Pick<PausePaymentSubscriptionPayload, 'code' | 'key' | 'message'>
  )> }
);

export type ReactiveUserSubscriptionMutationVariables = Exact<{
  id: Scalars['String'];
  isHavePromo?: Maybe<Scalars['Boolean']>;
  source?: Maybe<Scalars['String']>;
}>;


export type ReactiveUserSubscriptionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'reactiveUserSubscription'>
);

export type RemovePasswordMutationVariables = Exact<{ [key: string]: never; }>;


export type RemovePasswordMutation = (
  { __typename?: 'Mutation' }
  & { removePassword?: Maybe<(
    { __typename?: 'RemovePasswordPayload' }
    & Pick<RemovePasswordPayload, 'success'>
  )> }
);

export type ResetPasswordMutationVariables = Exact<{
  userId: Scalars['UUID'];
  token: Scalars['String'];
  password: Scalars['String'];
}>;


export type ResetPasswordMutation = (
  { __typename?: 'Mutation' }
  & { resetPassword?: Maybe<(
    { __typename?: 'ResetPasswordPayload' }
    & Pick<ResetPasswordPayload, 'success'>
  )> }
);

export type ResumePaymentSubscriptionMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type ResumePaymentSubscriptionMutation = (
  { __typename?: 'Mutation' }
  & { resumePaymentSubscription?: Maybe<(
    { __typename?: 'ResumePaymentSubscriptionPayload' }
    & Pick<ResumePaymentSubscriptionPayload, 'code' | 'key' | 'message'>
  )> }
);

export type RetriveCustomerCurrencyQueryVariables = Exact<{ [key: string]: never; }>;


export type RetriveCustomerCurrencyQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'retriveCustomerCurrency'>
);

export type RetryChargeSubscriptionMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type RetryChargeSubscriptionMutation = (
  { __typename?: 'Mutation' }
  & { retryChargeSubscriptionV2?: Maybe<(
    { __typename?: 'RetryChargeSubscriptionPayload' }
    & Pick<RetryChargeSubscriptionPayload, 'code' | 'message'>
  )> }
);

export type SharedQueryVariables = Exact<{ [key: string]: never; }>;


export type SharedQuery = (
  { __typename?: 'Query' }
  & SharedLayout_QueryFragment
);

export type SharedLayout_QueryFragment = (
  { __typename?: 'Query' }
  & { currentUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & SharedLayout_UserFragment
  )> }
);

export type SharedLayout_UserFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'name' | 'username' | 'avatarUrl' | 'isAdmin' | 'isVerified' | 'profile' | 'firstName' | 'lastName'>
  & { emails: (
    { __typename?: 'UserEmailsConnection' }
    & { edges: Array<(
      { __typename?: 'UserEmailsEdge' }
      & { node: (
        { __typename?: 'UserEmail' }
        & Pick<UserEmail, 'email' | 'isPrimary' | 'id'>
      ) }
    )> }
  ) }
);

export type GetMembershipReceiptsQueryVariables = Exact<{
  avironSubscriptionId: Scalars['UUID'];
}>;


export type GetMembershipReceiptsQuery = (
  { __typename?: 'Query' }
  & { subscriptionReceipts?: Maybe<(
    { __typename?: 'SubscriptionReceiptsConnection' }
    & { nodes: Array<(
      { __typename?: 'SubscriptionReceipt' }
      & Pick<SubscriptionReceipt, 'amount' | 'code' | 'createdAt' | 'id' | 'periodEnd' | 'periodStart' | 'status' | 'updatedAt'>
    )> }
  )> }
);

export type TrackingSubscriptionActionMutationVariables = Exact<{
  id: Scalars['String'];
  action: Scalars['String'];
  payload?: Maybe<Scalars['JSON']>;
}>;


export type TrackingSubscriptionActionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'trackingSubscriptionAction'>
);

export type UpdateUserMutationVariables = Exact<{
  id: Scalars['UUID'];
  patch: UserPatch;
}>;


export type UpdateUserMutation = (
  { __typename?: 'Mutation' }
  & { updateUser?: Maybe<(
    { __typename?: 'UpdateUserPayload' }
    & Pick<UpdateUserPayload, 'clientMutationId'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name' | 'username'>
    )> }
  )> }
);

export type UpdateUserAccountMutationVariables = Exact<{
  firstname?: Maybe<Scalars['String']>;
  lastname?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['Int']>;
  birthday?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Float']>;
  height_unit?: Maybe<Scalars['Int']>;
  weight?: Maybe<Scalars['Float']>;
  weight_unit?: Maybe<Scalars['Int']>;
  avatarUrl?: Maybe<Scalars['String']>;
}>;


export type UpdateUserAccountMutation = (
  { __typename?: 'Mutation' }
  & { gameUpdateUserProfile?: Maybe<(
    { __typename?: 'GameUpdateUserPayload' }
    & Pick<GameUpdateUserPayload, 'code' | 'message'>
  )> }
);

export type UpdateUserCustomMutationVariables = Exact<{
  data?: Maybe<Scalars['JSON']>;
}>;


export type UpdateUserCustomMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateUserCustom'>
);

export type UserChangeEmailMutationVariables = Exact<{
  emailToken: Scalars['String'];
}>;


export type UserChangeEmailMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'userChangeEmail'>
);

export type SendConfirmNewEmailMutationVariables = Exact<{
  input?: Maybe<Scalars['JSON']>;
}>;


export type SendConfirmNewEmailMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'sendConfirmNewEmail'>
);

export type GetGameUserProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type GetGameUserProfileQuery = (
  { __typename?: 'Query' }
  & { gameGetUserProfile?: Maybe<(
    { __typename?: 'GameUser' }
    & Pick<GameUser, 'username' | 'avatarUrl' | 'timezone'>
    & { profile?: Maybe<(
      { __typename?: 'GameProfile' }
      & Pick<GameProfile, 'firstName' | 'lastName' | 'height' | 'weight' | 'heightUnit' | 'weightUnit' | 'dob' | 'gender'>
    )> }
  )> }
);

export type PlansQueryVariables = Exact<{ [key: string]: never; }>;


export type PlansQuery = (
  { __typename?: 'Query' }
  & { plans?: Maybe<(
    { __typename?: 'PlansConnection' }
    & Pick<PlansConnection, 'totalCount'>
    & { nodes: Array<(
      { __typename?: 'Plan' }
      & Pick<Plan, 'id' | 'name' | 'enable' | 'type' | 'customerType' | 'interval' | 'intervalCount' | 'amount' | 'isRecurring' | 'currency' | 'productId' | 'appIds' | 'maxMachines'>
    )> }
  )> }
);

export type RetriveStripeCustomerQueryVariables = Exact<{
  customerId: Scalars['String'];
  params?: Maybe<Scalars['JSON']>;
  currency?: Maybe<Scalars['String']>;
}>;


export type RetriveStripeCustomerQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'retriveStripeCustomer'>
);

export type SetupIntentMutationVariables = Exact<{
  customer?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
}>;


export type SetupIntentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'setupIntent'>
);

export type StripeUpdateCustomerDefaultPaymentMethodMutationVariables = Exact<{
  customerId?: Maybe<Scalars['String']>;
  paymentMethod?: Maybe<Scalars['JSON']>;
  userId?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
}>;


export type StripeUpdateCustomerDefaultPaymentMethodMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'stripeUpdateCustomerDefaultPaymentMethod'>
);

export const SharedLayout_UserFragmentDoc = gql`
    fragment SharedLayout_User on User {
  id
  name
  username
  avatarUrl
  isAdmin
  isVerified
  profile
  firstName
  lastName
  emails: userEmails(condition: {isPrimary: true}) {
    edges {
      node {
        email
        isPrimary
        id
      }
    }
  }
}
    `;
export const SharedLayout_QueryFragmentDoc = gql`
    fragment SharedLayout_Query on Query {
  currentUser {
    id
    ...SharedLayout_User
  }
}
    ${SharedLayout_UserFragmentDoc}`;
export const AvironSubscriptionsDocument = gql`
    query avironSubscriptions($userId: UUID) {
  avironSubscriptions(first: 50, orderBy: CREATED_AT_DESC, filter: {customerId: {equalTo: $userId}}) {
    nodes {
      id
      subscriptionToken
      machineId
      startDay
      currentPeriodStart
      currentPeriodEnd
      isPauseCollection
      state
      remark
      machine {
        machineType
      }
      plan {
        id
        name
        appId
        customerType
        type
        productId
      }
    }
  }
}
    `;

/**
 * __useAvironSubscriptionsQuery__
 *
 * To run a query within a React component, call `useAvironSubscriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvironSubscriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvironSubscriptionsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAvironSubscriptionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AvironSubscriptionsQuery, AvironSubscriptionsQueryVariables>) {
        return ApolloReactHooks.useQuery<AvironSubscriptionsQuery, AvironSubscriptionsQueryVariables>(AvironSubscriptionsDocument, baseOptions);
      }
export function useAvironSubscriptionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AvironSubscriptionsQuery, AvironSubscriptionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AvironSubscriptionsQuery, AvironSubscriptionsQueryVariables>(AvironSubscriptionsDocument, baseOptions);
        }
export type AvironSubscriptionsQueryHookResult = ReturnType<typeof useAvironSubscriptionsQuery>;
export type AvironSubscriptionsLazyQueryHookResult = ReturnType<typeof useAvironSubscriptionsLazyQuery>;
export type AvironSubscriptionsQueryResult = ApolloReactCommon.QueryResult<AvironSubscriptionsQuery, AvironSubscriptionsQueryVariables>;
export const CancelUserSubscriptionDocument = gql`
    mutation CancelUserSubscription($id: String!, $reason: String!, $reasonDetail: String) {
  cancelUserSubscription(input: {id: $id, reason: $reason, reasonDetail: $reasonDetail})
}
    `;
export type CancelUserSubscriptionMutationFn = ApolloReactCommon.MutationFunction<CancelUserSubscriptionMutation, CancelUserSubscriptionMutationVariables>;

/**
 * __useCancelUserSubscriptionMutation__
 *
 * To run a mutation, you first call `useCancelUserSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelUserSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelUserSubscriptionMutation, { data, loading, error }] = useCancelUserSubscriptionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      reason: // value for 'reason'
 *      reasonDetail: // value for 'reasonDetail'
 *   },
 * });
 */
export function useCancelUserSubscriptionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelUserSubscriptionMutation, CancelUserSubscriptionMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelUserSubscriptionMutation, CancelUserSubscriptionMutationVariables>(CancelUserSubscriptionDocument, baseOptions);
      }
export type CancelUserSubscriptionMutationHookResult = ReturnType<typeof useCancelUserSubscriptionMutation>;
export type CancelUserSubscriptionMutationResult = ApolloReactCommon.MutationResult<CancelUserSubscriptionMutation>;
export type CancelUserSubscriptionMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelUserSubscriptionMutation, CancelUserSubscriptionMutationVariables>;
export const ChangePasswordDocument = gql`
    mutation ChangePassword($oldPassword: String!, $newPassword: String!) {
  changePassword(input: {oldPassword: $oldPassword, newPassword: $newPassword}) {
    success
  }
}
    `;
export type ChangePasswordMutationFn = ApolloReactCommon.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      oldPassword: // value for 'oldPassword'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useChangePasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, baseOptions);
      }
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = ApolloReactCommon.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ChangePasswordMutation, ChangePasswordMutationVariables>;
export const ChangeUserSubscriptionPlanDocument = gql`
    mutation ChangeUserSubscriptionPlan($id: String!, $planId: Int!) {
  changeUserSubscriptionPlan(input: {id: $id, planId: $planId})
}
    `;
export type ChangeUserSubscriptionPlanMutationFn = ApolloReactCommon.MutationFunction<ChangeUserSubscriptionPlanMutation, ChangeUserSubscriptionPlanMutationVariables>;

/**
 * __useChangeUserSubscriptionPlanMutation__
 *
 * To run a mutation, you first call `useChangeUserSubscriptionPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeUserSubscriptionPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeUserSubscriptionPlanMutation, { data, loading, error }] = useChangeUserSubscriptionPlanMutation({
 *   variables: {
 *      id: // value for 'id'
 *      planId: // value for 'planId'
 *   },
 * });
 */
export function useChangeUserSubscriptionPlanMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ChangeUserSubscriptionPlanMutation, ChangeUserSubscriptionPlanMutationVariables>) {
        return ApolloReactHooks.useMutation<ChangeUserSubscriptionPlanMutation, ChangeUserSubscriptionPlanMutationVariables>(ChangeUserSubscriptionPlanDocument, baseOptions);
      }
export type ChangeUserSubscriptionPlanMutationHookResult = ReturnType<typeof useChangeUserSubscriptionPlanMutation>;
export type ChangeUserSubscriptionPlanMutationResult = ApolloReactCommon.MutationResult<ChangeUserSubscriptionPlanMutation>;
export type ChangeUserSubscriptionPlanMutationOptions = ApolloReactCommon.BaseMutationOptions<ChangeUserSubscriptionPlanMutation, ChangeUserSubscriptionPlanMutationVariables>;
export const CheckCancelOfferAvaiableSubscriptionDocument = gql`
    query checkCancelOfferAvaiableSubscription {
  checkCancelOfferAvailableSubscription
}
    `;

/**
 * __useCheckCancelOfferAvaiableSubscriptionQuery__
 *
 * To run a query within a React component, call `useCheckCancelOfferAvaiableSubscriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckCancelOfferAvaiableSubscriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckCancelOfferAvaiableSubscriptionQuery({
 *   variables: {
 *   },
 * });
 */
export function useCheckCancelOfferAvaiableSubscriptionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CheckCancelOfferAvaiableSubscriptionQuery, CheckCancelOfferAvaiableSubscriptionQueryVariables>) {
        return ApolloReactHooks.useQuery<CheckCancelOfferAvaiableSubscriptionQuery, CheckCancelOfferAvaiableSubscriptionQueryVariables>(CheckCancelOfferAvaiableSubscriptionDocument, baseOptions);
      }
export function useCheckCancelOfferAvaiableSubscriptionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CheckCancelOfferAvaiableSubscriptionQuery, CheckCancelOfferAvaiableSubscriptionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CheckCancelOfferAvaiableSubscriptionQuery, CheckCancelOfferAvaiableSubscriptionQueryVariables>(CheckCancelOfferAvaiableSubscriptionDocument, baseOptions);
        }
export type CheckCancelOfferAvaiableSubscriptionQueryHookResult = ReturnType<typeof useCheckCancelOfferAvaiableSubscriptionQuery>;
export type CheckCancelOfferAvaiableSubscriptionLazyQueryHookResult = ReturnType<typeof useCheckCancelOfferAvaiableSubscriptionLazyQuery>;
export type CheckCancelOfferAvaiableSubscriptionQueryResult = ApolloReactCommon.QueryResult<CheckCancelOfferAvaiableSubscriptionQuery, CheckCancelOfferAvaiableSubscriptionQueryVariables>;
export const CheckPasswordlessAccountDocument = gql`
    mutation CheckPasswordlessAccount($email: String!) {
  checkPasswordlessAccount(input: {email: $email}) {
    passwordless
  }
}
    `;
export type CheckPasswordlessAccountMutationFn = ApolloReactCommon.MutationFunction<CheckPasswordlessAccountMutation, CheckPasswordlessAccountMutationVariables>;

/**
 * __useCheckPasswordlessAccountMutation__
 *
 * To run a mutation, you first call `useCheckPasswordlessAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckPasswordlessAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkPasswordlessAccountMutation, { data, loading, error }] = useCheckPasswordlessAccountMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useCheckPasswordlessAccountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CheckPasswordlessAccountMutation, CheckPasswordlessAccountMutationVariables>) {
        return ApolloReactHooks.useMutation<CheckPasswordlessAccountMutation, CheckPasswordlessAccountMutationVariables>(CheckPasswordlessAccountDocument, baseOptions);
      }
export type CheckPasswordlessAccountMutationHookResult = ReturnType<typeof useCheckPasswordlessAccountMutation>;
export type CheckPasswordlessAccountMutationResult = ApolloReactCommon.MutationResult<CheckPasswordlessAccountMutation>;
export type CheckPasswordlessAccountMutationOptions = ApolloReactCommon.BaseMutationOptions<CheckPasswordlessAccountMutation, CheckPasswordlessAccountMutationVariables>;
export const CheckSubscriptionFinanceFinishDocument = gql`
    mutation CheckSubscriptionFinanceFinish($id: String!) {
  checkSubscriptionFinanceFinish(input: {id: $id})
}
    `;
export type CheckSubscriptionFinanceFinishMutationFn = ApolloReactCommon.MutationFunction<CheckSubscriptionFinanceFinishMutation, CheckSubscriptionFinanceFinishMutationVariables>;

/**
 * __useCheckSubscriptionFinanceFinishMutation__
 *
 * To run a mutation, you first call `useCheckSubscriptionFinanceFinishMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckSubscriptionFinanceFinishMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkSubscriptionFinanceFinishMutation, { data, loading, error }] = useCheckSubscriptionFinanceFinishMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCheckSubscriptionFinanceFinishMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CheckSubscriptionFinanceFinishMutation, CheckSubscriptionFinanceFinishMutationVariables>) {
        return ApolloReactHooks.useMutation<CheckSubscriptionFinanceFinishMutation, CheckSubscriptionFinanceFinishMutationVariables>(CheckSubscriptionFinanceFinishDocument, baseOptions);
      }
export type CheckSubscriptionFinanceFinishMutationHookResult = ReturnType<typeof useCheckSubscriptionFinanceFinishMutation>;
export type CheckSubscriptionFinanceFinishMutationResult = ApolloReactCommon.MutationResult<CheckSubscriptionFinanceFinishMutation>;
export type CheckSubscriptionFinanceFinishMutationOptions = ApolloReactCommon.BaseMutationOptions<CheckSubscriptionFinanceFinishMutation, CheckSubscriptionFinanceFinishMutationVariables>;
export const CheckUserHaveFinanceSubscriptionDocument = gql`
    mutation checkUserHaveFinanceSubscription($userId: String!) {
  checkUserHaveFinanceSubscription(input: {userId: $userId})
}
    `;
export type CheckUserHaveFinanceSubscriptionMutationFn = ApolloReactCommon.MutationFunction<CheckUserHaveFinanceSubscriptionMutation, CheckUserHaveFinanceSubscriptionMutationVariables>;

/**
 * __useCheckUserHaveFinanceSubscriptionMutation__
 *
 * To run a mutation, you first call `useCheckUserHaveFinanceSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckUserHaveFinanceSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkUserHaveFinanceSubscriptionMutation, { data, loading, error }] = useCheckUserHaveFinanceSubscriptionMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useCheckUserHaveFinanceSubscriptionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CheckUserHaveFinanceSubscriptionMutation, CheckUserHaveFinanceSubscriptionMutationVariables>) {
        return ApolloReactHooks.useMutation<CheckUserHaveFinanceSubscriptionMutation, CheckUserHaveFinanceSubscriptionMutationVariables>(CheckUserHaveFinanceSubscriptionDocument, baseOptions);
      }
export type CheckUserHaveFinanceSubscriptionMutationHookResult = ReturnType<typeof useCheckUserHaveFinanceSubscriptionMutation>;
export type CheckUserHaveFinanceSubscriptionMutationResult = ApolloReactCommon.MutationResult<CheckUserHaveFinanceSubscriptionMutation>;
export type CheckUserHaveFinanceSubscriptionMutationOptions = ApolloReactCommon.BaseMutationOptions<CheckUserHaveFinanceSubscriptionMutation, CheckUserHaveFinanceSubscriptionMutationVariables>;
export const CheckUserJoinedDocument = gql`
    query checkUserJoined($userId: String!) {
  checkUserJoinedBetaProgram(input: {userId: $userId, appId: 1})
}
    `;

/**
 * __useCheckUserJoinedQuery__
 *
 * To run a query within a React component, call `useCheckUserJoinedQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckUserJoinedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckUserJoinedQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useCheckUserJoinedQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CheckUserJoinedQuery, CheckUserJoinedQueryVariables>) {
        return ApolloReactHooks.useQuery<CheckUserJoinedQuery, CheckUserJoinedQueryVariables>(CheckUserJoinedDocument, baseOptions);
      }
export function useCheckUserJoinedLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CheckUserJoinedQuery, CheckUserJoinedQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CheckUserJoinedQuery, CheckUserJoinedQueryVariables>(CheckUserJoinedDocument, baseOptions);
        }
export type CheckUserJoinedQueryHookResult = ReturnType<typeof useCheckUserJoinedQuery>;
export type CheckUserJoinedLazyQueryHookResult = ReturnType<typeof useCheckUserJoinedLazyQuery>;
export type CheckUserJoinedQueryResult = ApolloReactCommon.QueryResult<CheckUserJoinedQuery, CheckUserJoinedQueryVariables>;
export const ClaimCancelOfferSubscriptionDocument = gql`
    mutation claimCancelOfferSubscription($id: String!) {
  cancelOfferSubscription(input: {id: $id}) {
    code
    key
    message
  }
}
    `;
export type ClaimCancelOfferSubscriptionMutationFn = ApolloReactCommon.MutationFunction<ClaimCancelOfferSubscriptionMutation, ClaimCancelOfferSubscriptionMutationVariables>;

/**
 * __useClaimCancelOfferSubscriptionMutation__
 *
 * To run a mutation, you first call `useClaimCancelOfferSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClaimCancelOfferSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [claimCancelOfferSubscriptionMutation, { data, loading, error }] = useClaimCancelOfferSubscriptionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useClaimCancelOfferSubscriptionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ClaimCancelOfferSubscriptionMutation, ClaimCancelOfferSubscriptionMutationVariables>) {
        return ApolloReactHooks.useMutation<ClaimCancelOfferSubscriptionMutation, ClaimCancelOfferSubscriptionMutationVariables>(ClaimCancelOfferSubscriptionDocument, baseOptions);
      }
export type ClaimCancelOfferSubscriptionMutationHookResult = ReturnType<typeof useClaimCancelOfferSubscriptionMutation>;
export type ClaimCancelOfferSubscriptionMutationResult = ApolloReactCommon.MutationResult<ClaimCancelOfferSubscriptionMutation>;
export type ClaimCancelOfferSubscriptionMutationOptions = ApolloReactCommon.BaseMutationOptions<ClaimCancelOfferSubscriptionMutation, ClaimCancelOfferSubscriptionMutationVariables>;
export const CreateBetaRequestDocument = gql`
    mutation createBetaRequest($userId: UUID!, $discord: String!, $machineSerial: String!, $workoutPerWeek: String!) {
  createBetaRequest(input: {betaRequest: {userId: $userId, discord: $discord, machineSerial: $machineSerial, workoutPerWeek: $workoutPerWeek}}) {
    betaRequest {
      machineSerial
      cancelReason
      canceledAt
      createdAt
      discord
      id
      status
      userId
      updatedAt
      workoutPerWeek
    }
  }
}
    `;
export type CreateBetaRequestMutationFn = ApolloReactCommon.MutationFunction<CreateBetaRequestMutation, CreateBetaRequestMutationVariables>;

/**
 * __useCreateBetaRequestMutation__
 *
 * To run a mutation, you first call `useCreateBetaRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBetaRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBetaRequestMutation, { data, loading, error }] = useCreateBetaRequestMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      discord: // value for 'discord'
 *      machineSerial: // value for 'machineSerial'
 *      workoutPerWeek: // value for 'workoutPerWeek'
 *   },
 * });
 */
export function useCreateBetaRequestMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateBetaRequestMutation, CreateBetaRequestMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateBetaRequestMutation, CreateBetaRequestMutationVariables>(CreateBetaRequestDocument, baseOptions);
      }
export type CreateBetaRequestMutationHookResult = ReturnType<typeof useCreateBetaRequestMutation>;
export type CreateBetaRequestMutationResult = ApolloReactCommon.MutationResult<CreateBetaRequestMutation>;
export type CreateBetaRequestMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateBetaRequestMutation, CreateBetaRequestMutationVariables>;
export const CreateUploadUrlDocument = gql`
    mutation CreateUploadUrl($input: CreateUploadUrlInput!) {
  createUploadUrl(input: $input) {
    uploadUrl
    publicUrl
    fields {
      key
      policy
      x_goog_algorithm
      x_goog_credential
      x_goog_date
      x_goog_signature
    }
  }
}
    `;
export type CreateUploadUrlMutationFn = ApolloReactCommon.MutationFunction<CreateUploadUrlMutation, CreateUploadUrlMutationVariables>;

/**
 * __useCreateUploadUrlMutation__
 *
 * To run a mutation, you first call `useCreateUploadUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUploadUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUploadUrlMutation, { data, loading, error }] = useCreateUploadUrlMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUploadUrlMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateUploadUrlMutation, CreateUploadUrlMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateUploadUrlMutation, CreateUploadUrlMutationVariables>(CreateUploadUrlDocument, baseOptions);
      }
export type CreateUploadUrlMutationHookResult = ReturnType<typeof useCreateUploadUrlMutation>;
export type CreateUploadUrlMutationResult = ApolloReactCommon.MutationResult<CreateUploadUrlMutation>;
export type CreateUploadUrlMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateUploadUrlMutation, CreateUploadUrlMutationVariables>;
export const FitplanChooseSubscriptionDocument = gql`
    mutation FitplanChooseSubscription($id: String!, $planId: Int!) {
  fitplanChooseSubscription(input: {id: $id, planId: $planId}) {
    code
    message
    key
  }
}
    `;
export type FitplanChooseSubscriptionMutationFn = ApolloReactCommon.MutationFunction<FitplanChooseSubscriptionMutation, FitplanChooseSubscriptionMutationVariables>;

/**
 * __useFitplanChooseSubscriptionMutation__
 *
 * To run a mutation, you first call `useFitplanChooseSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFitplanChooseSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fitplanChooseSubscriptionMutation, { data, loading, error }] = useFitplanChooseSubscriptionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      planId: // value for 'planId'
 *   },
 * });
 */
export function useFitplanChooseSubscriptionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<FitplanChooseSubscriptionMutation, FitplanChooseSubscriptionMutationVariables>) {
        return ApolloReactHooks.useMutation<FitplanChooseSubscriptionMutation, FitplanChooseSubscriptionMutationVariables>(FitplanChooseSubscriptionDocument, baseOptions);
      }
export type FitplanChooseSubscriptionMutationHookResult = ReturnType<typeof useFitplanChooseSubscriptionMutation>;
export type FitplanChooseSubscriptionMutationResult = ApolloReactCommon.MutationResult<FitplanChooseSubscriptionMutation>;
export type FitplanChooseSubscriptionMutationOptions = ApolloReactCommon.BaseMutationOptions<FitplanChooseSubscriptionMutation, FitplanChooseSubscriptionMutationVariables>;
export const ForgotPasswordDocument = gql`
    mutation ForgotPassword($email: String!) {
  forgotPassword(input: {email: $email}) {
    clientMutationId
  }
}
    `;
export type ForgotPasswordMutationFn = ApolloReactCommon.MutationFunction<ForgotPasswordMutation, ForgotPasswordMutationVariables>;

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useForgotPasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(ForgotPasswordDocument, baseOptions);
      }
export type ForgotPasswordMutationHookResult = ReturnType<typeof useForgotPasswordMutation>;
export type ForgotPasswordMutationResult = ApolloReactCommon.MutationResult<ForgotPasswordMutation>;
export type ForgotPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>;
export const GetAvironSubscriptionDocument = gql`
    query GetAvironSubscription($id: UUID!) {
  avironSubscription(id: $id) {
    id
    subscriptionToken
    state
    startDay
    machineId
    planId
    resumeAt
    currentPeriodEnd
    isPauseCollection
    remark
    customerId
    machine {
      machineType
    }
    plan {
      id
      name
      enable
      type
      customerType
      interval
      amount
      isRecurring
      currency
      productId
      appId
      maxMachines
      appIds
    }
  }
}
    `;

/**
 * __useGetAvironSubscriptionQuery__
 *
 * To run a query within a React component, call `useGetAvironSubscriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAvironSubscriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAvironSubscriptionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAvironSubscriptionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAvironSubscriptionQuery, GetAvironSubscriptionQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAvironSubscriptionQuery, GetAvironSubscriptionQueryVariables>(GetAvironSubscriptionDocument, baseOptions);
      }
export function useGetAvironSubscriptionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAvironSubscriptionQuery, GetAvironSubscriptionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAvironSubscriptionQuery, GetAvironSubscriptionQueryVariables>(GetAvironSubscriptionDocument, baseOptions);
        }
export type GetAvironSubscriptionQueryHookResult = ReturnType<typeof useGetAvironSubscriptionQuery>;
export type GetAvironSubscriptionLazyQueryHookResult = ReturnType<typeof useGetAvironSubscriptionLazyQuery>;
export type GetAvironSubscriptionQueryResult = ApolloReactCommon.QueryResult<GetAvironSubscriptionQuery, GetAvironSubscriptionQueryVariables>;
export const GetAvironSubscriptionReceiptsDocument = gql`
    query GetAvironSubscriptionReceipts($id: UUID!, $first: Int, $offset: Int) {
  subscriptionReceipts(filter: {avironSubscriptionId: {equalTo: $id}}, orderBy: CREATED_AT_DESC, first: $first, offset: $offset) {
    totalCount
    nodes {
      createdAt
      periodStart
      periodEnd
      amount
      status
      avironSubscriptionId
    }
  }
}
    `;

/**
 * __useGetAvironSubscriptionReceiptsQuery__
 *
 * To run a query within a React component, call `useGetAvironSubscriptionReceiptsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAvironSubscriptionReceiptsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAvironSubscriptionReceiptsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      first: // value for 'first'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetAvironSubscriptionReceiptsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAvironSubscriptionReceiptsQuery, GetAvironSubscriptionReceiptsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAvironSubscriptionReceiptsQuery, GetAvironSubscriptionReceiptsQueryVariables>(GetAvironSubscriptionReceiptsDocument, baseOptions);
      }
export function useGetAvironSubscriptionReceiptsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAvironSubscriptionReceiptsQuery, GetAvironSubscriptionReceiptsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAvironSubscriptionReceiptsQuery, GetAvironSubscriptionReceiptsQueryVariables>(GetAvironSubscriptionReceiptsDocument, baseOptions);
        }
export type GetAvironSubscriptionReceiptsQueryHookResult = ReturnType<typeof useGetAvironSubscriptionReceiptsQuery>;
export type GetAvironSubscriptionReceiptsLazyQueryHookResult = ReturnType<typeof useGetAvironSubscriptionReceiptsLazyQuery>;
export type GetAvironSubscriptionReceiptsQueryResult = ApolloReactCommon.QueryResult<GetAvironSubscriptionReceiptsQuery, GetAvironSubscriptionReceiptsQueryVariables>;
export const RetriveRequestsDocument = gql`
    query retriveRequests($first: Int, $offset: Int, $userId: UUID) {
  betaRequests(first: $first, offset: $offset, filter: {userId: {equalTo: $userId}}, orderBy: [CREATED_AT_DESC]) {
    nodes {
      id
      userId
      machineSerial
      discord
      workoutPerWeek
      canceledAt
      cancelReason
      status
      createdAt
      updatedAt
    }
    totalCount
  }
}
    `;

/**
 * __useRetriveRequestsQuery__
 *
 * To run a query within a React component, call `useRetriveRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRetriveRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRetriveRequestsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      offset: // value for 'offset'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useRetriveRequestsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RetriveRequestsQuery, RetriveRequestsQueryVariables>) {
        return ApolloReactHooks.useQuery<RetriveRequestsQuery, RetriveRequestsQueryVariables>(RetriveRequestsDocument, baseOptions);
      }
export function useRetriveRequestsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RetriveRequestsQuery, RetriveRequestsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RetriveRequestsQuery, RetriveRequestsQueryVariables>(RetriveRequestsDocument, baseOptions);
        }
export type RetriveRequestsQueryHookResult = ReturnType<typeof useRetriveRequestsQuery>;
export type RetriveRequestsLazyQueryHookResult = ReturnType<typeof useRetriveRequestsLazyQuery>;
export type RetriveRequestsQueryResult = ApolloReactCommon.QueryResult<RetriveRequestsQuery, RetriveRequestsQueryVariables>;
export const CheckBetaIsLiveDocument = gql`
    query checkBetaIsLive {
  checkBetaIsLive {
    isLive
    startDate
    endDate
  }
}
    `;

/**
 * __useCheckBetaIsLiveQuery__
 *
 * To run a query within a React component, call `useCheckBetaIsLiveQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckBetaIsLiveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckBetaIsLiveQuery({
 *   variables: {
 *   },
 * });
 */
export function useCheckBetaIsLiveQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CheckBetaIsLiveQuery, CheckBetaIsLiveQueryVariables>) {
        return ApolloReactHooks.useQuery<CheckBetaIsLiveQuery, CheckBetaIsLiveQueryVariables>(CheckBetaIsLiveDocument, baseOptions);
      }
export function useCheckBetaIsLiveLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CheckBetaIsLiveQuery, CheckBetaIsLiveQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CheckBetaIsLiveQuery, CheckBetaIsLiveQueryVariables>(CheckBetaIsLiveDocument, baseOptions);
        }
export type CheckBetaIsLiveQueryHookResult = ReturnType<typeof useCheckBetaIsLiveQuery>;
export type CheckBetaIsLiveLazyQueryHookResult = ReturnType<typeof useCheckBetaIsLiveLazyQuery>;
export type CheckBetaIsLiveQueryResult = ApolloReactCommon.QueryResult<CheckBetaIsLiveQuery, CheckBetaIsLiveQueryVariables>;
export const GetCrmSessionDocument = gql`
    query getCrmSession {
  getCrmSession {
    accessToken
    expires
  }
}
    `;

/**
 * __useGetCrmSessionQuery__
 *
 * To run a query within a React component, call `useGetCrmSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCrmSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCrmSessionQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCrmSessionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCrmSessionQuery, GetCrmSessionQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCrmSessionQuery, GetCrmSessionQueryVariables>(GetCrmSessionDocument, baseOptions);
      }
export function useGetCrmSessionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCrmSessionQuery, GetCrmSessionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCrmSessionQuery, GetCrmSessionQueryVariables>(GetCrmSessionDocument, baseOptions);
        }
export type GetCrmSessionQueryHookResult = ReturnType<typeof useGetCrmSessionQuery>;
export type GetCrmSessionLazyQueryHookResult = ReturnType<typeof useGetCrmSessionLazyQuery>;
export type GetCrmSessionQueryResult = ApolloReactCommon.QueryResult<GetCrmSessionQuery, GetCrmSessionQueryVariables>;
export const GetGameSessionDocument = gql`
    query getGameSession {
  getGameSession {
    accessToken
    expires
  }
}
    `;

/**
 * __useGetGameSessionQuery__
 *
 * To run a query within a React component, call `useGetGameSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGameSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGameSessionQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGameSessionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetGameSessionQuery, GetGameSessionQueryVariables>) {
        return ApolloReactHooks.useQuery<GetGameSessionQuery, GetGameSessionQueryVariables>(GetGameSessionDocument, baseOptions);
      }
export function useGetGameSessionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGameSessionQuery, GetGameSessionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetGameSessionQuery, GetGameSessionQueryVariables>(GetGameSessionDocument, baseOptions);
        }
export type GetGameSessionQueryHookResult = ReturnType<typeof useGetGameSessionQuery>;
export type GetGameSessionLazyQueryHookResult = ReturnType<typeof useGetGameSessionLazyQuery>;
export type GetGameSessionQueryResult = ApolloReactCommon.QueryResult<GetGameSessionQuery, GetGameSessionQueryVariables>;
export const GetIdTokenDocument = gql`
    query GetIdToken {
  getIdToken {
    idToken
  }
}
    `;

/**
 * __useGetIdTokenQuery__
 *
 * To run a query within a React component, call `useGetIdTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIdTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIdTokenQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetIdTokenQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetIdTokenQuery, GetIdTokenQueryVariables>) {
        return ApolloReactHooks.useQuery<GetIdTokenQuery, GetIdTokenQueryVariables>(GetIdTokenDocument, baseOptions);
      }
export function useGetIdTokenLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetIdTokenQuery, GetIdTokenQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetIdTokenQuery, GetIdTokenQueryVariables>(GetIdTokenDocument, baseOptions);
        }
export type GetIdTokenQueryHookResult = ReturnType<typeof useGetIdTokenQuery>;
export type GetIdTokenLazyQueryHookResult = ReturnType<typeof useGetIdTokenLazyQuery>;
export type GetIdTokenQueryResult = ApolloReactCommon.QueryResult<GetIdTokenQuery, GetIdTokenQueryVariables>;
export const GetSubscriptionsByUserDocument = gql`
    query GetSubscriptionsByUser($limit: Int!, $offset: Int!) {
  getSubscriptionsByUser(input: {limit: $limit, offset: $offset})
}
    `;

/**
 * __useGetSubscriptionsByUserQuery__
 *
 * To run a query within a React component, call `useGetSubscriptionsByUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubscriptionsByUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubscriptionsByUserQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetSubscriptionsByUserQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubscriptionsByUserQuery, GetSubscriptionsByUserQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubscriptionsByUserQuery, GetSubscriptionsByUserQueryVariables>(GetSubscriptionsByUserDocument, baseOptions);
      }
export function useGetSubscriptionsByUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubscriptionsByUserQuery, GetSubscriptionsByUserQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubscriptionsByUserQuery, GetSubscriptionsByUserQueryVariables>(GetSubscriptionsByUserDocument, baseOptions);
        }
export type GetSubscriptionsByUserQueryHookResult = ReturnType<typeof useGetSubscriptionsByUserQuery>;
export type GetSubscriptionsByUserLazyQueryHookResult = ReturnType<typeof useGetSubscriptionsByUserLazyQuery>;
export type GetSubscriptionsByUserQueryResult = ApolloReactCommon.QueryResult<GetSubscriptionsByUserQuery, GetSubscriptionsByUserQueryVariables>;
export const LeaveBetaProgramDocument = gql`
    mutation leaveBetaProgram($userId: String!, $reason: String!) {
  leaveBetaProgram(input: {userId: $userId, reason: $reason}) {
    status
    code
  }
}
    `;
export type LeaveBetaProgramMutationFn = ApolloReactCommon.MutationFunction<LeaveBetaProgramMutation, LeaveBetaProgramMutationVariables>;

/**
 * __useLeaveBetaProgramMutation__
 *
 * To run a mutation, you first call `useLeaveBetaProgramMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLeaveBetaProgramMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [leaveBetaProgramMutation, { data, loading, error }] = useLeaveBetaProgramMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      reason: // value for 'reason'
 *   },
 * });
 */
export function useLeaveBetaProgramMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LeaveBetaProgramMutation, LeaveBetaProgramMutationVariables>) {
        return ApolloReactHooks.useMutation<LeaveBetaProgramMutation, LeaveBetaProgramMutationVariables>(LeaveBetaProgramDocument, baseOptions);
      }
export type LeaveBetaProgramMutationHookResult = ReturnType<typeof useLeaveBetaProgramMutation>;
export type LeaveBetaProgramMutationResult = ApolloReactCommon.MutationResult<LeaveBetaProgramMutation>;
export type LeaveBetaProgramMutationOptions = ApolloReactCommon.BaseMutationOptions<LeaveBetaProgramMutation, LeaveBetaProgramMutationVariables>;
export const LoginDocument = gql`
    mutation Login($username: String!, $password: String!) {
  login(input: {username: $username, password: $password}) {
    user {
      id
      username
      name
    }
  }
}
    `;
export type LoginMutationFn = ApolloReactCommon.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      username: // value for 'username'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        return ApolloReactHooks.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, baseOptions);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = ApolloReactCommon.MutationResult<LoginMutation>;
export type LoginMutationOptions = ApolloReactCommon.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const LogoutDocument = gql`
    mutation Logout {
  logout {
    success
  }
}
    `;
export type LogoutMutationFn = ApolloReactCommon.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        return ApolloReactHooks.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, baseOptions);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = ApolloReactCommon.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = ApolloReactCommon.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const PasswordlessLoginDocument = gql`
    mutation PasswordlessLogin($email: String!) {
  passwordlessLogin(input: {email: $email}) {
    userEmail {
      id
      userId
      email
    }
  }
}
    `;
export type PasswordlessLoginMutationFn = ApolloReactCommon.MutationFunction<PasswordlessLoginMutation, PasswordlessLoginMutationVariables>;

/**
 * __usePasswordlessLoginMutation__
 *
 * To run a mutation, you first call `usePasswordlessLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePasswordlessLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [passwordlessLoginMutation, { data, loading, error }] = usePasswordlessLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function usePasswordlessLoginMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PasswordlessLoginMutation, PasswordlessLoginMutationVariables>) {
        return ApolloReactHooks.useMutation<PasswordlessLoginMutation, PasswordlessLoginMutationVariables>(PasswordlessLoginDocument, baseOptions);
      }
export type PasswordlessLoginMutationHookResult = ReturnType<typeof usePasswordlessLoginMutation>;
export type PasswordlessLoginMutationResult = ApolloReactCommon.MutationResult<PasswordlessLoginMutation>;
export type PasswordlessLoginMutationOptions = ApolloReactCommon.BaseMutationOptions<PasswordlessLoginMutation, PasswordlessLoginMutationVariables>;
export const PasswordlessAuthenticateDocument = gql`
    mutation PasswordlessAuthenticate($emailId: UUID!, $token: String!) {
  passwordlessAuthenticate(input: {userEmailId: $emailId, token: $token}) {
    user {
      id
      username
      name
    }
  }
}
    `;
export type PasswordlessAuthenticateMutationFn = ApolloReactCommon.MutationFunction<PasswordlessAuthenticateMutation, PasswordlessAuthenticateMutationVariables>;

/**
 * __usePasswordlessAuthenticateMutation__
 *
 * To run a mutation, you first call `usePasswordlessAuthenticateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePasswordlessAuthenticateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [passwordlessAuthenticateMutation, { data, loading, error }] = usePasswordlessAuthenticateMutation({
 *   variables: {
 *      emailId: // value for 'emailId'
 *      token: // value for 'token'
 *   },
 * });
 */
export function usePasswordlessAuthenticateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PasswordlessAuthenticateMutation, PasswordlessAuthenticateMutationVariables>) {
        return ApolloReactHooks.useMutation<PasswordlessAuthenticateMutation, PasswordlessAuthenticateMutationVariables>(PasswordlessAuthenticateDocument, baseOptions);
      }
export type PasswordlessAuthenticateMutationHookResult = ReturnType<typeof usePasswordlessAuthenticateMutation>;
export type PasswordlessAuthenticateMutationResult = ApolloReactCommon.MutationResult<PasswordlessAuthenticateMutation>;
export type PasswordlessAuthenticateMutationOptions = ApolloReactCommon.BaseMutationOptions<PasswordlessAuthenticateMutation, PasswordlessAuthenticateMutationVariables>;
export const PausePaymentSubscriptionDocument = gql`
    mutation PausePaymentSubscription($id: String!, $behavior: String!, $resumesAt: Date, $reason: String, $note: String) {
  pausePaymentSubscription(input: {id: $id, behavior: $behavior, resumesAt: $resumesAt, reason: $reason, note: $note}) {
    code
    key
    message
  }
}
    `;
export type PausePaymentSubscriptionMutationFn = ApolloReactCommon.MutationFunction<PausePaymentSubscriptionMutation, PausePaymentSubscriptionMutationVariables>;

/**
 * __usePausePaymentSubscriptionMutation__
 *
 * To run a mutation, you first call `usePausePaymentSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePausePaymentSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pausePaymentSubscriptionMutation, { data, loading, error }] = usePausePaymentSubscriptionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      behavior: // value for 'behavior'
 *      resumesAt: // value for 'resumesAt'
 *      reason: // value for 'reason'
 *      note: // value for 'note'
 *   },
 * });
 */
export function usePausePaymentSubscriptionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PausePaymentSubscriptionMutation, PausePaymentSubscriptionMutationVariables>) {
        return ApolloReactHooks.useMutation<PausePaymentSubscriptionMutation, PausePaymentSubscriptionMutationVariables>(PausePaymentSubscriptionDocument, baseOptions);
      }
export type PausePaymentSubscriptionMutationHookResult = ReturnType<typeof usePausePaymentSubscriptionMutation>;
export type PausePaymentSubscriptionMutationResult = ApolloReactCommon.MutationResult<PausePaymentSubscriptionMutation>;
export type PausePaymentSubscriptionMutationOptions = ApolloReactCommon.BaseMutationOptions<PausePaymentSubscriptionMutation, PausePaymentSubscriptionMutationVariables>;
export const ReactiveUserSubscriptionDocument = gql`
    mutation reactiveUserSubscription($id: String!, $isHavePromo: Boolean, $source: String) {
  reactiveUserSubscription(input: {id: $id, isHavePromo: $isHavePromo, source: $source})
}
    `;
export type ReactiveUserSubscriptionMutationFn = ApolloReactCommon.MutationFunction<ReactiveUserSubscriptionMutation, ReactiveUserSubscriptionMutationVariables>;

/**
 * __useReactiveUserSubscriptionMutation__
 *
 * To run a mutation, you first call `useReactiveUserSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReactiveUserSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reactiveUserSubscriptionMutation, { data, loading, error }] = useReactiveUserSubscriptionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      isHavePromo: // value for 'isHavePromo'
 *      source: // value for 'source'
 *   },
 * });
 */
export function useReactiveUserSubscriptionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReactiveUserSubscriptionMutation, ReactiveUserSubscriptionMutationVariables>) {
        return ApolloReactHooks.useMutation<ReactiveUserSubscriptionMutation, ReactiveUserSubscriptionMutationVariables>(ReactiveUserSubscriptionDocument, baseOptions);
      }
export type ReactiveUserSubscriptionMutationHookResult = ReturnType<typeof useReactiveUserSubscriptionMutation>;
export type ReactiveUserSubscriptionMutationResult = ApolloReactCommon.MutationResult<ReactiveUserSubscriptionMutation>;
export type ReactiveUserSubscriptionMutationOptions = ApolloReactCommon.BaseMutationOptions<ReactiveUserSubscriptionMutation, ReactiveUserSubscriptionMutationVariables>;
export const RemovePasswordDocument = gql`
    mutation RemovePassword {
  removePassword {
    success
  }
}
    `;
export type RemovePasswordMutationFn = ApolloReactCommon.MutationFunction<RemovePasswordMutation, RemovePasswordMutationVariables>;

/**
 * __useRemovePasswordMutation__
 *
 * To run a mutation, you first call `useRemovePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemovePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removePasswordMutation, { data, loading, error }] = useRemovePasswordMutation({
 *   variables: {
 *   },
 * });
 */
export function useRemovePasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemovePasswordMutation, RemovePasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<RemovePasswordMutation, RemovePasswordMutationVariables>(RemovePasswordDocument, baseOptions);
      }
export type RemovePasswordMutationHookResult = ReturnType<typeof useRemovePasswordMutation>;
export type RemovePasswordMutationResult = ApolloReactCommon.MutationResult<RemovePasswordMutation>;
export type RemovePasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<RemovePasswordMutation, RemovePasswordMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation ResetPassword($userId: UUID!, $token: String!, $password: String!) {
  resetPassword(input: {userId: $userId, resetToken: $token, newPassword: $password}) {
    success
  }
}
    `;
export type ResetPasswordMutationFn = ApolloReactCommon.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      token: // value for 'token'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, baseOptions);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = ApolloReactCommon.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const ResumePaymentSubscriptionDocument = gql`
    mutation ResumePaymentSubscription($id: String!) {
  resumePaymentSubscription(input: {id: $id}) {
    code
    key
    message
  }
}
    `;
export type ResumePaymentSubscriptionMutationFn = ApolloReactCommon.MutationFunction<ResumePaymentSubscriptionMutation, ResumePaymentSubscriptionMutationVariables>;

/**
 * __useResumePaymentSubscriptionMutation__
 *
 * To run a mutation, you first call `useResumePaymentSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResumePaymentSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resumePaymentSubscriptionMutation, { data, loading, error }] = useResumePaymentSubscriptionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useResumePaymentSubscriptionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ResumePaymentSubscriptionMutation, ResumePaymentSubscriptionMutationVariables>) {
        return ApolloReactHooks.useMutation<ResumePaymentSubscriptionMutation, ResumePaymentSubscriptionMutationVariables>(ResumePaymentSubscriptionDocument, baseOptions);
      }
export type ResumePaymentSubscriptionMutationHookResult = ReturnType<typeof useResumePaymentSubscriptionMutation>;
export type ResumePaymentSubscriptionMutationResult = ApolloReactCommon.MutationResult<ResumePaymentSubscriptionMutation>;
export type ResumePaymentSubscriptionMutationOptions = ApolloReactCommon.BaseMutationOptions<ResumePaymentSubscriptionMutation, ResumePaymentSubscriptionMutationVariables>;
export const RetriveCustomerCurrencyDocument = gql`
    query retriveCustomerCurrency {
  retriveCustomerCurrency
}
    `;

/**
 * __useRetriveCustomerCurrencyQuery__
 *
 * To run a query within a React component, call `useRetriveCustomerCurrencyQuery` and pass it any options that fit your needs.
 * When your component renders, `useRetriveCustomerCurrencyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRetriveCustomerCurrencyQuery({
 *   variables: {
 *   },
 * });
 */
export function useRetriveCustomerCurrencyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RetriveCustomerCurrencyQuery, RetriveCustomerCurrencyQueryVariables>) {
        return ApolloReactHooks.useQuery<RetriveCustomerCurrencyQuery, RetriveCustomerCurrencyQueryVariables>(RetriveCustomerCurrencyDocument, baseOptions);
      }
export function useRetriveCustomerCurrencyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RetriveCustomerCurrencyQuery, RetriveCustomerCurrencyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RetriveCustomerCurrencyQuery, RetriveCustomerCurrencyQueryVariables>(RetriveCustomerCurrencyDocument, baseOptions);
        }
export type RetriveCustomerCurrencyQueryHookResult = ReturnType<typeof useRetriveCustomerCurrencyQuery>;
export type RetriveCustomerCurrencyLazyQueryHookResult = ReturnType<typeof useRetriveCustomerCurrencyLazyQuery>;
export type RetriveCustomerCurrencyQueryResult = ApolloReactCommon.QueryResult<RetriveCustomerCurrencyQuery, RetriveCustomerCurrencyQueryVariables>;
export const RetryChargeSubscriptionDocument = gql`
    mutation retryChargeSubscription($token: String!) {
  retryChargeSubscriptionV2(input: {token: $token}) {
    code
    message
  }
}
    `;
export type RetryChargeSubscriptionMutationFn = ApolloReactCommon.MutationFunction<RetryChargeSubscriptionMutation, RetryChargeSubscriptionMutationVariables>;

/**
 * __useRetryChargeSubscriptionMutation__
 *
 * To run a mutation, you first call `useRetryChargeSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRetryChargeSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [retryChargeSubscriptionMutation, { data, loading, error }] = useRetryChargeSubscriptionMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useRetryChargeSubscriptionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RetryChargeSubscriptionMutation, RetryChargeSubscriptionMutationVariables>) {
        return ApolloReactHooks.useMutation<RetryChargeSubscriptionMutation, RetryChargeSubscriptionMutationVariables>(RetryChargeSubscriptionDocument, baseOptions);
      }
export type RetryChargeSubscriptionMutationHookResult = ReturnType<typeof useRetryChargeSubscriptionMutation>;
export type RetryChargeSubscriptionMutationResult = ApolloReactCommon.MutationResult<RetryChargeSubscriptionMutation>;
export type RetryChargeSubscriptionMutationOptions = ApolloReactCommon.BaseMutationOptions<RetryChargeSubscriptionMutation, RetryChargeSubscriptionMutationVariables>;
export const SharedDocument = gql`
    query Shared {
  ...SharedLayout_Query
}
    ${SharedLayout_QueryFragmentDoc}`;

/**
 * __useSharedQuery__
 *
 * To run a query within a React component, call `useSharedQuery` and pass it any options that fit your needs.
 * When your component renders, `useSharedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSharedQuery({
 *   variables: {
 *   },
 * });
 */
export function useSharedQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SharedQuery, SharedQueryVariables>) {
        return ApolloReactHooks.useQuery<SharedQuery, SharedQueryVariables>(SharedDocument, baseOptions);
      }
export function useSharedLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SharedQuery, SharedQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SharedQuery, SharedQueryVariables>(SharedDocument, baseOptions);
        }
export type SharedQueryHookResult = ReturnType<typeof useSharedQuery>;
export type SharedLazyQueryHookResult = ReturnType<typeof useSharedLazyQuery>;
export type SharedQueryResult = ApolloReactCommon.QueryResult<SharedQuery, SharedQueryVariables>;
export const GetMembershipReceiptsDocument = gql`
    query getMembershipReceipts($avironSubscriptionId: UUID!) {
  subscriptionReceipts(filter: {avironSubscriptionId: {equalTo: $avironSubscriptionId}}) {
    nodes {
      amount
      code
      createdAt
      id
      periodEnd
      periodStart
      status
      updatedAt
    }
  }
}
    `;

/**
 * __useGetMembershipReceiptsQuery__
 *
 * To run a query within a React component, call `useGetMembershipReceiptsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMembershipReceiptsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMembershipReceiptsQuery({
 *   variables: {
 *      avironSubscriptionId: // value for 'avironSubscriptionId'
 *   },
 * });
 */
export function useGetMembershipReceiptsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMembershipReceiptsQuery, GetMembershipReceiptsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMembershipReceiptsQuery, GetMembershipReceiptsQueryVariables>(GetMembershipReceiptsDocument, baseOptions);
      }
export function useGetMembershipReceiptsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMembershipReceiptsQuery, GetMembershipReceiptsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMembershipReceiptsQuery, GetMembershipReceiptsQueryVariables>(GetMembershipReceiptsDocument, baseOptions);
        }
export type GetMembershipReceiptsQueryHookResult = ReturnType<typeof useGetMembershipReceiptsQuery>;
export type GetMembershipReceiptsLazyQueryHookResult = ReturnType<typeof useGetMembershipReceiptsLazyQuery>;
export type GetMembershipReceiptsQueryResult = ApolloReactCommon.QueryResult<GetMembershipReceiptsQuery, GetMembershipReceiptsQueryVariables>;
export const TrackingSubscriptionActionDocument = gql`
    mutation trackingSubscriptionAction($id: String!, $action: String!, $payload: JSON) {
  trackingSubscriptionAction(input: {id: $id, action: $action, payload: $payload})
}
    `;
export type TrackingSubscriptionActionMutationFn = ApolloReactCommon.MutationFunction<TrackingSubscriptionActionMutation, TrackingSubscriptionActionMutationVariables>;

/**
 * __useTrackingSubscriptionActionMutation__
 *
 * To run a mutation, you first call `useTrackingSubscriptionActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTrackingSubscriptionActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [trackingSubscriptionActionMutation, { data, loading, error }] = useTrackingSubscriptionActionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      action: // value for 'action'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useTrackingSubscriptionActionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TrackingSubscriptionActionMutation, TrackingSubscriptionActionMutationVariables>) {
        return ApolloReactHooks.useMutation<TrackingSubscriptionActionMutation, TrackingSubscriptionActionMutationVariables>(TrackingSubscriptionActionDocument, baseOptions);
      }
export type TrackingSubscriptionActionMutationHookResult = ReturnType<typeof useTrackingSubscriptionActionMutation>;
export type TrackingSubscriptionActionMutationResult = ApolloReactCommon.MutationResult<TrackingSubscriptionActionMutation>;
export type TrackingSubscriptionActionMutationOptions = ApolloReactCommon.BaseMutationOptions<TrackingSubscriptionActionMutation, TrackingSubscriptionActionMutationVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($id: UUID!, $patch: UserPatch!) {
  updateUser(input: {id: $id, patch: $patch}) {
    clientMutationId
    user {
      id
      name
      username
    }
  }
}
    `;
export type UpdateUserMutationFn = ApolloReactCommon.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      patch: // value for 'patch'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, baseOptions);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = ApolloReactCommon.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const UpdateUserAccountDocument = gql`
    mutation updateUserAccount($firstname: String, $lastname: String, $username: String, $gender: Int, $birthday: String, $height: Float, $height_unit: Int, $weight: Float, $weight_unit: Int, $avatarUrl: String) {
  gameUpdateUserProfile(input: {avatarUrl: $avatarUrl, firstname: $firstname, lastname: $lastname, username: $username, gender: $gender, birthday: $birthday, height: $height, height_unit: $height_unit, weight: $weight, weight_unit: $weight_unit}) {
    code
    message
  }
}
    `;
export type UpdateUserAccountMutationFn = ApolloReactCommon.MutationFunction<UpdateUserAccountMutation, UpdateUserAccountMutationVariables>;

/**
 * __useUpdateUserAccountMutation__
 *
 * To run a mutation, you first call `useUpdateUserAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserAccountMutation, { data, loading, error }] = useUpdateUserAccountMutation({
 *   variables: {
 *      firstname: // value for 'firstname'
 *      lastname: // value for 'lastname'
 *      username: // value for 'username'
 *      gender: // value for 'gender'
 *      birthday: // value for 'birthday'
 *      height: // value for 'height'
 *      height_unit: // value for 'height_unit'
 *      weight: // value for 'weight'
 *      weight_unit: // value for 'weight_unit'
 *      avatarUrl: // value for 'avatarUrl'
 *   },
 * });
 */
export function useUpdateUserAccountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserAccountMutation, UpdateUserAccountMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateUserAccountMutation, UpdateUserAccountMutationVariables>(UpdateUserAccountDocument, baseOptions);
      }
export type UpdateUserAccountMutationHookResult = ReturnType<typeof useUpdateUserAccountMutation>;
export type UpdateUserAccountMutationResult = ApolloReactCommon.MutationResult<UpdateUserAccountMutation>;
export type UpdateUserAccountMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateUserAccountMutation, UpdateUserAccountMutationVariables>;
export const UpdateUserCustomDocument = gql`
    mutation UpdateUserCustom($data: JSON) {
  updateUserCustom(input: {data: $data})
}
    `;
export type UpdateUserCustomMutationFn = ApolloReactCommon.MutationFunction<UpdateUserCustomMutation, UpdateUserCustomMutationVariables>;

/**
 * __useUpdateUserCustomMutation__
 *
 * To run a mutation, you first call `useUpdateUserCustomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserCustomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserCustomMutation, { data, loading, error }] = useUpdateUserCustomMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateUserCustomMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserCustomMutation, UpdateUserCustomMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateUserCustomMutation, UpdateUserCustomMutationVariables>(UpdateUserCustomDocument, baseOptions);
      }
export type UpdateUserCustomMutationHookResult = ReturnType<typeof useUpdateUserCustomMutation>;
export type UpdateUserCustomMutationResult = ApolloReactCommon.MutationResult<UpdateUserCustomMutation>;
export type UpdateUserCustomMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateUserCustomMutation, UpdateUserCustomMutationVariables>;
export const UserChangeEmailDocument = gql`
    mutation UserChangeEmail($emailToken: String!) {
  userChangeEmail(input: {emailToken: $emailToken})
}
    `;
export type UserChangeEmailMutationFn = ApolloReactCommon.MutationFunction<UserChangeEmailMutation, UserChangeEmailMutationVariables>;

/**
 * __useUserChangeEmailMutation__
 *
 * To run a mutation, you first call `useUserChangeEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserChangeEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userChangeEmailMutation, { data, loading, error }] = useUserChangeEmailMutation({
 *   variables: {
 *      emailToken: // value for 'emailToken'
 *   },
 * });
 */
export function useUserChangeEmailMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UserChangeEmailMutation, UserChangeEmailMutationVariables>) {
        return ApolloReactHooks.useMutation<UserChangeEmailMutation, UserChangeEmailMutationVariables>(UserChangeEmailDocument, baseOptions);
      }
export type UserChangeEmailMutationHookResult = ReturnType<typeof useUserChangeEmailMutation>;
export type UserChangeEmailMutationResult = ApolloReactCommon.MutationResult<UserChangeEmailMutation>;
export type UserChangeEmailMutationOptions = ApolloReactCommon.BaseMutationOptions<UserChangeEmailMutation, UserChangeEmailMutationVariables>;
export const SendConfirmNewEmailDocument = gql`
    mutation SendConfirmNewEmail($input: JSON) {
  sendConfirmNewEmail(input: $input)
}
    `;
export type SendConfirmNewEmailMutationFn = ApolloReactCommon.MutationFunction<SendConfirmNewEmailMutation, SendConfirmNewEmailMutationVariables>;

/**
 * __useSendConfirmNewEmailMutation__
 *
 * To run a mutation, you first call `useSendConfirmNewEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendConfirmNewEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendConfirmNewEmailMutation, { data, loading, error }] = useSendConfirmNewEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendConfirmNewEmailMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SendConfirmNewEmailMutation, SendConfirmNewEmailMutationVariables>) {
        return ApolloReactHooks.useMutation<SendConfirmNewEmailMutation, SendConfirmNewEmailMutationVariables>(SendConfirmNewEmailDocument, baseOptions);
      }
export type SendConfirmNewEmailMutationHookResult = ReturnType<typeof useSendConfirmNewEmailMutation>;
export type SendConfirmNewEmailMutationResult = ApolloReactCommon.MutationResult<SendConfirmNewEmailMutation>;
export type SendConfirmNewEmailMutationOptions = ApolloReactCommon.BaseMutationOptions<SendConfirmNewEmailMutation, SendConfirmNewEmailMutationVariables>;
export const GetGameUserProfileDocument = gql`
    query getGameUserProfile {
  gameGetUserProfile {
    username
    avatarUrl
    timezone
    profile {
      firstName
      lastName
      height
      weight
      heightUnit
      weightUnit
      dob
      gender
    }
  }
}
    `;

/**
 * __useGetGameUserProfileQuery__
 *
 * To run a query within a React component, call `useGetGameUserProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGameUserProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGameUserProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGameUserProfileQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetGameUserProfileQuery, GetGameUserProfileQueryVariables>) {
        return ApolloReactHooks.useQuery<GetGameUserProfileQuery, GetGameUserProfileQueryVariables>(GetGameUserProfileDocument, baseOptions);
      }
export function useGetGameUserProfileLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGameUserProfileQuery, GetGameUserProfileQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetGameUserProfileQuery, GetGameUserProfileQueryVariables>(GetGameUserProfileDocument, baseOptions);
        }
export type GetGameUserProfileQueryHookResult = ReturnType<typeof useGetGameUserProfileQuery>;
export type GetGameUserProfileLazyQueryHookResult = ReturnType<typeof useGetGameUserProfileLazyQuery>;
export type GetGameUserProfileQueryResult = ApolloReactCommon.QueryResult<GetGameUserProfileQuery, GetGameUserProfileQueryVariables>;
export const PlansDocument = gql`
    query plans {
  plans {
    nodes {
      id
      name
      enable
      type
      customerType
      interval
      intervalCount
      amount
      isRecurring
      currency
      productId
      appIds
      maxMachines
    }
    totalCount
  }
}
    `;

/**
 * __usePlansQuery__
 *
 * To run a query within a React component, call `usePlansQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlansQuery({
 *   variables: {
 *   },
 * });
 */
export function usePlansQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PlansQuery, PlansQueryVariables>) {
        return ApolloReactHooks.useQuery<PlansQuery, PlansQueryVariables>(PlansDocument, baseOptions);
      }
export function usePlansLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PlansQuery, PlansQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PlansQuery, PlansQueryVariables>(PlansDocument, baseOptions);
        }
export type PlansQueryHookResult = ReturnType<typeof usePlansQuery>;
export type PlansLazyQueryHookResult = ReturnType<typeof usePlansLazyQuery>;
export type PlansQueryResult = ApolloReactCommon.QueryResult<PlansQuery, PlansQueryVariables>;
export const RetriveStripeCustomerDocument = gql`
    query RetriveStripeCustomer($customerId: String!, $params: JSON, $currency: String) {
  retriveStripeCustomer(input: {customerId: $customerId, currency: $currency}, params: $params)
}
    `;

/**
 * __useRetriveStripeCustomerQuery__
 *
 * To run a query within a React component, call `useRetriveStripeCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useRetriveStripeCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRetriveStripeCustomerQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      params: // value for 'params'
 *      currency: // value for 'currency'
 *   },
 * });
 */
export function useRetriveStripeCustomerQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RetriveStripeCustomerQuery, RetriveStripeCustomerQueryVariables>) {
        return ApolloReactHooks.useQuery<RetriveStripeCustomerQuery, RetriveStripeCustomerQueryVariables>(RetriveStripeCustomerDocument, baseOptions);
      }
export function useRetriveStripeCustomerLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RetriveStripeCustomerQuery, RetriveStripeCustomerQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RetriveStripeCustomerQuery, RetriveStripeCustomerQueryVariables>(RetriveStripeCustomerDocument, baseOptions);
        }
export type RetriveStripeCustomerQueryHookResult = ReturnType<typeof useRetriveStripeCustomerQuery>;
export type RetriveStripeCustomerLazyQueryHookResult = ReturnType<typeof useRetriveStripeCustomerLazyQuery>;
export type RetriveStripeCustomerQueryResult = ApolloReactCommon.QueryResult<RetriveStripeCustomerQuery, RetriveStripeCustomerQueryVariables>;
export const SetupIntentDocument = gql`
    mutation SetupIntent($customer: String, $currency: String) {
  setupIntent(input: {customer: $customer, currency: $currency})
}
    `;
export type SetupIntentMutationFn = ApolloReactCommon.MutationFunction<SetupIntentMutation, SetupIntentMutationVariables>;

/**
 * __useSetupIntentMutation__
 *
 * To run a mutation, you first call `useSetupIntentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetupIntentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setupIntentMutation, { data, loading, error }] = useSetupIntentMutation({
 *   variables: {
 *      customer: // value for 'customer'
 *      currency: // value for 'currency'
 *   },
 * });
 */
export function useSetupIntentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetupIntentMutation, SetupIntentMutationVariables>) {
        return ApolloReactHooks.useMutation<SetupIntentMutation, SetupIntentMutationVariables>(SetupIntentDocument, baseOptions);
      }
export type SetupIntentMutationHookResult = ReturnType<typeof useSetupIntentMutation>;
export type SetupIntentMutationResult = ApolloReactCommon.MutationResult<SetupIntentMutation>;
export type SetupIntentMutationOptions = ApolloReactCommon.BaseMutationOptions<SetupIntentMutation, SetupIntentMutationVariables>;
export const StripeUpdateCustomerDefaultPaymentMethodDocument = gql`
    mutation StripeUpdateCustomerDefaultPaymentMethod($customerId: String, $paymentMethod: JSON, $userId: String, $currency: String) {
  stripeUpdateCustomerDefaultPaymentMethod(input: {customerId: $customerId, paymentMethod: $paymentMethod, userId: $userId, currency: $currency})
}
    `;
export type StripeUpdateCustomerDefaultPaymentMethodMutationFn = ApolloReactCommon.MutationFunction<StripeUpdateCustomerDefaultPaymentMethodMutation, StripeUpdateCustomerDefaultPaymentMethodMutationVariables>;

/**
 * __useStripeUpdateCustomerDefaultPaymentMethodMutation__
 *
 * To run a mutation, you first call `useStripeUpdateCustomerDefaultPaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStripeUpdateCustomerDefaultPaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stripeUpdateCustomerDefaultPaymentMethodMutation, { data, loading, error }] = useStripeUpdateCustomerDefaultPaymentMethodMutation({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      paymentMethod: // value for 'paymentMethod'
 *      userId: // value for 'userId'
 *      currency: // value for 'currency'
 *   },
 * });
 */
export function useStripeUpdateCustomerDefaultPaymentMethodMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<StripeUpdateCustomerDefaultPaymentMethodMutation, StripeUpdateCustomerDefaultPaymentMethodMutationVariables>) {
        return ApolloReactHooks.useMutation<StripeUpdateCustomerDefaultPaymentMethodMutation, StripeUpdateCustomerDefaultPaymentMethodMutationVariables>(StripeUpdateCustomerDefaultPaymentMethodDocument, baseOptions);
      }
export type StripeUpdateCustomerDefaultPaymentMethodMutationHookResult = ReturnType<typeof useStripeUpdateCustomerDefaultPaymentMethodMutation>;
export type StripeUpdateCustomerDefaultPaymentMethodMutationResult = ApolloReactCommon.MutationResult<StripeUpdateCustomerDefaultPaymentMethodMutation>;
export type StripeUpdateCustomerDefaultPaymentMethodMutationOptions = ApolloReactCommon.BaseMutationOptions<StripeUpdateCustomerDefaultPaymentMethodMutation, StripeUpdateCustomerDefaultPaymentMethodMutationVariables>;