import React, { useContext, useEffect, useRef, useState } from "react";
import style from "./MembershipInviteConfirm.module.scss";
import { ReactComponent as TickSuccess } from "./Icons/Success.svg";
import { Button } from "@mui/material";
import { useHistory } from "react-router-dom";
import { CONSTANTS } from "consts";
import { UserAppContext } from "contexts";
import { getUrlVars } from "utils/url";
import { ReactComponent as TickError } from "./Icons/Expire.svg";
import { AvironLoading } from "Components/Icons/AvironLoading";
import { gameServerApi } from "services/gameServerV2";
import { useLogoutMutation } from "services/webServer";

const PERMISION_ERROR_CODE = 1016;
const LINK_EXPIRE_CODE = 1019;
const LINK_NOT_FOUND_CODE = 1018;
const MEMBER_EXITS_CODE = 1017;
const UNKNOW_CODE = 0;
const MembershipInviteConfirm = () => {
    const history = useHistory();
    const params = getUrlVars();
    const [stateUpdate, setStateUpdate] = useState<
        "init" | "success" | "error"
    >("init");
    const [errorMsg, setErrorMsg] = useState<{
        title: string;
        desc: string;
        code: number;
    }>({
        title: "",
        desc: "",
        code: UNKNOW_CODE,
    });
    const [resultConfirm, setResultConfirm] = useState<any>("");
    const { auth } = useContext(UserAppContext);
    const appId = localStorage.getItem("appId");
    const [logout] = useLogoutMutation();

    const handleClickView = async () => {
        document.body.style.overflow = "";
        history.push(CONSTANTS.NAVIGATION_URL.MEMBERSHIPS);
    };
    // console.log("bfbfbfbfbfbfbf", window.location);
    const handleLogout = async () => {
        await logout();
        document.body.style.overflow = "";
        localStorage.clear();
        sessionStorage.clear();
        localStorage.setItem("appId", appId || "");
        window.location.href = `${window.location.origin}/login?redirect=${window.location.origin}${CONSTANTS.NAVIGATION_URL.MEMBERSHIP_INVITE_CONFIRM}?orgId=${params?.orgId}&&userId=${params?.userId}`;
    };

    function usePreviousValue(value: any) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const prevAuth: any = usePreviousValue(auth);

    useEffect(() => {
        if (auth?.email) {
            if (params?.orgId) {
                gameServerApi.membership
                    .confirmInviteMembership({
                        orgId: params?.orgId,
                        userId: params?.userId,
                    })
                    .then((res) => {
                        setResultConfirm(res.data);
                        setStateUpdate("success");
                    })
                    .catch((err) => {
                        setStateUpdate("error");
                        if (
                            err?.response?.status === 401 &&
                            err?.response?.data?.error?.code ===
                                PERMISION_ERROR_CODE
                        ) {
                            // setErrorMsg({
                            //     title: "Invalid Invitation",
                            //     desc: `This invitation is linked to a different
                            //     account. Please log in to the correct account or
                            //     contact the family plan owner.`,
                            //     code: PERMISION_ERROR_CODE
                            // });
                            window.location.href = `${window.location.origin}/login?redirect=${window.location.origin}${CONSTANTS.NAVIGATION_URL.MEMBERSHIP_INVITE_CONFIRM}?orgId=${params?.orgId}&&userId=${params?.userId}`;
                        } else if (
                            err?.response?.data?.error?.code ===
                            LINK_EXPIRE_CODE
                        ) {
                            setErrorMsg({
                                title: "Invalid Invitation",
                                desc: `The invitation is invalid or expired. Please contact the family plan owner.`,
                                code: LINK_EXPIRE_CODE,
                            });
                        } else if (
                            err?.response?.status === 404 &&
                            err?.response?.data?.error?.code ===
                                LINK_NOT_FOUND_CODE
                        ) {
                            setErrorMsg({
                                title: "Invalid Invitation",
                                desc: `The invitation is invalid or expired. Please contact the family plan owner.`,
                                code: LINK_NOT_FOUND_CODE,
                            });
                        } else if (
                            err?.response?.status === 400 &&
                            err?.response?.data?.error?.code === MEMBER_EXITS_CODE
                        ) {
                            setErrorMsg({
                                title: "Can’t join family plan. ",
                                desc: `You have already joined a family plan`,
                                code: MEMBER_EXITS_CODE,
                            });
                        } else {
                            setErrorMsg({
                                title: "",
                                desc: ``,
                                code: UNKNOW_CODE,
                            });
                        }
                    });
            }
        }
    }, [auth]);

    useEffect(() => {
        if (stateUpdate === "success" || stateUpdate === "error") {
            document.body.style.overflow = "hidden";
        }
    }, [stateUpdate]);

    return (
        <div className={style["ChangeEmailConfirmPage"]}>
            {stateUpdate === "success" && (
                <div className={style["ChangeEmailSuccessPage"]}>
                    <div>
                        <TickSuccess />
                        <div className={style["title"]}>
                            Welcome to the family.
                        </div>
                        <div className={style["desc"]}>
                            You have joined a family plan
                        </div>
                        <div className={style["buttonGroup"]}>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={handleClickView}
                            >
                                VIEW
                            </Button>
                        </div>
                    </div>
                </div>
            )}

            {stateUpdate === "init" && (
                <div className={style["ChangeEmailLoadingPage"]}>
                    <AvironLoading />
                </div>
            )}

            {stateUpdate === "error" && (
                <>
                    {errorMsg.code !== UNKNOW_CODE && (
                        <div className={style["ChangeEmailSuccessPage"]}>
                            {errorMsg.code === PERMISION_ERROR_CODE ? (
                                <div>
                                    {/* <TickError />
                                <div className={style["title"]}>
                                    Invalid Invitation
                                </div>
                                <div className={style["desc"]}>
                                    This invitation is linked to a different
                                    account. Please log in to the correct account or
                                    contact the family plan owner.
                                </div>
                                <div className={style["buttonGroup"]}>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        onClick={handleLogout}
                                    >
                                        LOGOUT
                                    </Button>
                                </div> */}
                                </div>
                            ) : (
                                <div>
                                    <TickError />
                                    <div className={style["title"]}>
                                        {errorMsg.title}
                                    </div>
                                    <div className={style["desc"]}>
                                        {errorMsg.desc}
                                    </div>
                                    <div className={style["buttonGroup"]}>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={() => {
                                                history.push(
                                                    CONSTANTS.NAVIGATION_URL
                                                        .MEMBERSHIPS
                                                );
                                                document.body.style.overflow =
                                                    "";
                                            }}
                                        >
                                            OK
                                        </Button>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default MembershipInviteConfirm;
