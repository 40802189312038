import React, { useEffect } from "react";
import style from "./MachineManage.module.scss";
import { Box, Grid, Paper, Stack } from "@mui/material";
import { Helmet } from "react-helmet";
import GoBack from "Components/MembershipPage/GoBack";
import { useLocation } from "react-router-dom";
import { useMachineHook } from "./machine.hook";
import { useWindowSize } from "scripts/useWindowSize";
import { AvironLoading } from "Components/Icons/AvironLoading";
import { CONSTANTS } from "consts";
import MachineCard from "Components/MachineManage/MachineCard";
import AddMachineCard from "Components/MachineManage/AddMachineCard";
import { IMachine } from "Screens/Memberships/MachineManage/formatData";
import SwitchMachineModal from "Components/MachineManage/SwitchMachineModal";
import AddMachineModal from "Components/MachineManage/AddMachineModal";
import { MEMBERSHIP_STATUS } from "consts/statusMembership";

const MachineManage = () => {
    const location = useLocation();
    const {
        membershipId,
        loading,
        machines,
        handleSwitchMachine,
        openSwitchMachineModal,
        setOpenSwitchMachineModal,
        refetchMembershipMachineList,
        machine,
        machineAdded,
        openAddMachineModal,
        setOpenAddMachineModal,
        handleOpenAddMachine,
        handleDeleteMachine,
        currentMembershipDetail,
    } = useMachineHook();

    const windowSize = useWindowSize();

    return (
        <div className={style["MembershipInviteList"]}>
            <Box
                padding={{
                    xs: "16px 32px",
                    md: 0,
                }}
                className={style["MembershipWrapper"]}
            >
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Manage Machine | Aviron</title>
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                <GoBack
                    url={`${CONSTANTS.NAVIGATION_URL.MEMBERSHIPS}/${membershipId}`}
                />
                <Stack direction={{ xs: "column-reverse", md: "column" }}>
                    <Box mt="24px" className={style["titleWrapper"]}>
                        <div className={style["title"]}>Machine ID</div>
                        <div className={style["subTitle"]}>
                            {`${machineAdded?.total}` +
                                (machineAdded?.maxMachine
                                    ? `/${machineAdded?.maxMachine}`
                                    : "")}{" "}
                            Machines Added
                        </div>
                    </Box>
                </Stack>
                <div className={style["machineCardWrapper"]}>
                    <>
                        {machines &&
                            machines.map((item: IMachine, key: number) => {
                                return (
                                    <MachineCard
                                        key={key}
                                        data={item}
                                        onSwitchMachine={() =>
                                            handleSwitchMachine(item)
                                        }
                                        handleDeleteMachine={() => {
                                            handleDeleteMachine(item);
                                        }}
                                        isHideDelete={
                                            machines?.length === 1 ||
                                            currentMembershipDetail?.status !==
                                                MEMBERSHIP_STATUS.activated
                                        }
                                        isHideSwitch={
                                            currentMembershipDetail?.status !==
                                            MEMBERSHIP_STATUS.activated
                                        }
                                    />
                                );
                            })}
                    </>
                    <>
                        {machines?.length < machineAdded?.maxMachine &&
                            currentMembershipDetail?.status ===
                                MEMBERSHIP_STATUS.activated && (
                                <AddMachineCard
                                    onClick={handleOpenAddMachine}
                                />
                            )}
                    </>
                    <>
                        {machines?.length === 0 && (
                            <i className={style['emptyMachine']}>
                                Activate membership to sync your machine with
                                your account
                            </i>
                        )}
                    </>
                </div>
            </Box>
            {machine && (
                <SwitchMachineModal
                    open={openSwitchMachineModal}
                    onClose={() => {
                        setOpenSwitchMachineModal(false);
                    }}
                    refetchData={refetchMembershipMachineList}
                    machine={machine}
                />
            )}
            <AddMachineModal
                open={openAddMachineModal}
                onClose={() => {
                    setOpenAddMachineModal(false);
                }}
                refetchData={refetchMembershipMachineList}
            />
            {loading && <AvironLoading />}
        </div>
    );
};

export default MachineManage;
