import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import { makeStyles } from "@mui/styles";
import Field from "@mui/material/TextField";
import { useFormik } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import Button from "Components/base/Button/Button";
import style from "./style.module.scss";
import { formSchema } from "./formSchema";
import { gameServerApi } from "services/gameServerV2";
import { useParams } from "react-router-dom";
import { AvironLoading } from "Components/Icons/AvironLoading";
import { CONSTANTS } from "consts";
import Swal from "sweetalert2";
import ErrorIcon from "Components/Icons/ErrorIcon.svg";
import SuccessInvite from "Components/Icons/SuccessInvite.svg";
import InputOutline from "Components/base/InputOutline";
import SelectField from "Components/FormElements/Select";
import InputLabel from "Components/base/InputLabel";
import { IMachine } from "Screens/Memberships/MachineManage/formatData";
import WarnignIcon from "Components/Icons/WarningIcon.svg";

interface InviteMemberProps {
    open: boolean;
    onClose: any;
    refetchData?: any;
    machine: IMachine
}

const useStyles: any = makeStyles({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
});

const SwitchMachineModal = (props: InviteMemberProps) => {
    const { open, onClose, refetchData, machine } = props;
    const classes = useStyles();
    const params = useParams();
    const [showAvironLoading, setShowAvironLoading] = useState(false);
    const machineTypeOption = [
      {
        name: "Bike",
        value: CONSTANTS.APP.AVIRON_BIKE,
      },
      {
          name: "Rower",
          value: CONSTANTS.APP.AVIRON_APK,
      },
    ];

    const initialValues = {
        appId: machine?.appId,
        machineId: "",
    };

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: formSchema({oldMachineId: machine.machineId}),
        onSubmit: async (values) => {
          await handleSubmitForm(values);
        },
    });

    const handleSubmitForm = async (values: { appId: number, machineId: string }) => {
        try {
            const result = await Swal.fire({
                title: '<div style="font-size: 24px;">CONFIRM SWITCH MACHINE<div>',
                // icon: "warning",
                imageUrl: WarnignIcon,
                html: `<div>Switching machines will deactivate your current machine. <br />Do you want to proceed?</div>`,
                showCancelButton: true,
                confirmButtonText: "CONFIRM",
                cancelButtonText: "Cancel",
                showClass: {
                    popup: `
                      confirm-membership-popup
                    `,
                },
            });
            if (!result.isConfirmed) {
                return;
            }
            setShowAvironLoading(true);
            const {appId, machineId} = values
            await gameServerApi.machine.switchMembershipMachine({
                //@ts-ignore
                orgId: params?.id,
                newMachineId: machineId,
                oldMachineId: machine.machineId,
                appId: appId,
            });
            onClose();
            setShowAvironLoading(false);
            await refetchData();
            setTimeout(() => {
                Swal.fire({
                    title: "Success",
                    html: "<div>Machine Switch Successful </div>",
                    imageUrl: SuccessInvite,
                    showClass: {
                        popup: `error-invite-member-popup`,
                    },
                });
            }, 600);
        } catch (error) {
            onClose();
            setTimeout(() => {
                if (error.response.status === 404) {
                    Swal.fire({
                        title: "SORRY, MACHINE CAN’T BE SWICHED",
                        html: "<div>Machine not found</div>",
                        imageUrl: ErrorIcon,
                        showClass: {
                            popup: `
                                  error-invite-member-popup
                                `,
                        },
                    });
                } else {
                    Swal.fire({
                        title: "Switch Machine Error",
                        html: `<div>${
                            error?.response?.data?.error?.message || ""
                        }</div>`,
                        imageUrl: ErrorIcon,
                        showClass: {
                            popup: `
                                  error-invite-member-popup
                                `,
                        },
                    });
                }
            }, 600);
            setShowAvironLoading(false);
        }
    };

    React.useEffect(() => {
        if(!open) {
            formik.resetForm();
        }
    }, [open]);

    return (
        <>
            <Modal
                open={open}
                onClose={onClose}
                className={classes.modal + " " + style["modalCustom"]}
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 800,
                }}
            >
                <Fade in={open}>
                    <div className={style["change-password-modal"]}>
                        <div className={style["close-btn"]} onClick={onClose}>
                            <img
                                src="/images/icons/x-icon.svg"
                                width={18}
                                height={18}
                                alt="icon"
                            />
                        </div>

                        <div className={style["titleModal"]}>
                            switch MACHINE
                        </div>
                        <div className={style["desc"]}>
                            Switch your current machine to use this membership
                            on another machine
                        </div>
                        <Box>
                            <form onSubmit={formik.handleSubmit}>
                                <div className={style["text-field-group"]}>
                                    <InputLabel htmlFor="outlined-machine-type">
                                        Please choose type of the new machine
                                    </InputLabel>
                                    <SelectField
                                        options={machineTypeOption}
                                        value={formik.values.appId}
                                        onChange={formik.handleChange}
                                        name={"appId"}
                                    />
                                </div>
                                <div className={style["text-field-group"]}>
                                    <InputOutline
                                        label="Please fill the new machine ID"
                                        name="machineId"
                                        value={formik.values.machineId}
                                        onChange={formik.handleChange}
                                        error={formik.errors.machineId}
                                    />
                                </div>
                                <div className={style["buttons-group"]}>
                                    <Button
                                        type="submit"
                                        variant="outlined"
                                        fullWidth
                                        sx={{
                                            height: "52px",
                                        }}
                                        className={style["cancleButton"]}
                                        onClick={onClose}
                                    >
                                        Exit
                                    </Button>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="secondary"
                                        fullWidth
                                        sx={{
                                            height: "52px",
                                        }}
                                    >
                                        Switch
                                    </Button>
                                </div>
                            </form>
                        </Box>
                    </div>
                </Fade>
            </Modal>
            {showAvironLoading && <AvironLoading />}
        </>
    );
};

export default SwitchMachineModal;
