import React, { Fragment } from 'react'
import { ApolloProvider } from "@apollo/react-hooks";
import { ThemeProvider } from "@mui/material/styles";
import { UserAppContextProvider } from "contexts";
import "react-toastify/dist/ReactToastify.css";
import { createApolloClient } from "scripts/apollo";
import "styles/scss/style.scss";
import theme from "styles/theme";
import useRouteElement from 'routes/useRouteElement'

const apolloClient = createApolloClient();

const UserApp = () => {
    const routeElement = useRouteElement()
  
    return (
        <Fragment>
            {apolloClient && (
                <ApolloProvider client={apolloClient}>
                    <UserAppContextProvider apolloClient={apolloClient}>
                        <ThemeProvider theme={theme}>
                            {routeElement}
                        </ThemeProvider>
                    </UserAppContextProvider>
                </ApolloProvider>
            )}
        </Fragment>
    );
};

export default UserApp;
