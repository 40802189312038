import React from 'react';
import style from './style.module.scss';

interface IInputOutline {
  value?: string,
  label?: string,
  name?: string,
  id?: string,
  onChange?: any
  error?: string
}

const InputOutline = ({label, name, id, onChange, value, error}: IInputOutline) => {
  return (
    <div className={style['InputOutline']}>
      {label && <div className={style['lable']}>{label}</div>}
      <input name={name} id={id} onChange={onChange} value={value} />
      {error && <div className={style['error']}>{error}</div>}
    </div>
  )
}

export default InputOutline;