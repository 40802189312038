import React, {
    Ref,
    forwardRef,
    useEffect,
    useImperativeHandle,
    useMemo,
    useState,
} from "react";
import { Backdrop, Button, Checkbox, Modal } from "@mui/material";
import { makeStyles } from "@mui/styles";
import styles from "./ModalChangeDevice.module.scss";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { UserAppContext } from "contexts";
import { AvironLoading } from "Components/Icons/AvironLoading";
import { initialTypeMarchine } from "contexts/machines/machine.reducer";
import { gameServerApi } from "services/gameServerV2";
import { useGetIdTokenQuery } from "services/webServer";
import { handleFortmatGroupMachine } from "utils/formatGroupMachine";
import { handleRenderTitleLoading } from "utils/handleRenderTitle";
import {
    useHistory,
    useLocation,
    useParams,
    useRouteMatch,
} from "react-router-dom";
import { saveTokenToLocalStorage } from "services/gameServerV2/base";

export interface RefModalChangeDevice {
    isVisible: boolean;
    setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
}
const useStyles: any = makeStyles({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: "none",
    },
});

const ModalChangeDevice = (props: any, ref: Ref<RefModalChangeDevice>) => {
    const match = useRouteMatch({
        path: `/membership/:id`,
        strict: true,
        sensitive: true,
    });
    const history = useHistory();

    const [isVisible, setIsVisible] = React.useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { machineList } = React.useContext(UserAppContext);
    const { data, loading } = machineList;

    const appIdLocal = localStorage.getItem("appId");

    const appId = useMemo(() => {
        return Number(appIdLocal) || +data[0]?.id;
    }, [appIdLocal, data]);

    const [active, setActive] = React.useState<number>(appId);

    const { data: dataTokenId } = useGetIdTokenQuery();

    const idToken = dataTokenId?.getIdToken?.idToken || "";

    const classes = useStyles();

    useImperativeHandle(
        ref,
        () => ({
            isVisible,
            setIsVisible,
        }),
        [isVisible]
    );

    const onClose = () => {
        setIsVisible(false);
    };

    useEffect(() => {
        setActive(appId);
    }, [appId]);

    const handleConfirm = async () => {
        setIsLoading(true);
        try {
            const defaultDevice: initialTypeMarchine =
                await machineList.data.find(
                    (item: initialTypeMarchine) => item.id === Number(active)
                );
            localStorage.setItem("groupDevice", defaultDevice.group);
            gameServerApi.app.getDeviceToken(
                idToken,
                String(active)
            ).then(res => {
                saveTokenToLocalStorage(res as string)
                setIsLoading(false)
                onClose();
                window.location.href = window.location.pathname;
            }).catch(() => {
                setIsLoading(false)
            });
        } catch (error) {
            console.log("error", error);
        }
    };

    if (loading) {
        return <AvironLoading />;
    }
    return (
        <Modal
            open={isVisible}
            onClose={() => setIsVisible(false)}
            className={classes.modal}
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <div className={styles.modal}>
                <div className={styles.modalChangeMachine}>
                    <div className={styles["modal-header"]}>
                        <h3 className={styles["modal-header__title"]}>
                            CHANGE MACHINE TYPE
                        </h3>
                        <p className={styles["modal-header__content"]}>
                            Change the machine type to see stats for exercises
                            on that particular equipment
                        </p>
                    </div>
                    <div className={styles["modal-content"]}>
                        {data.map((item: initialTypeMarchine) => {
                            const { id, group = "", image = "", name = "" } = item;
                            const groupFormatted = handleFortmatGroupMachine(group);
                            console.log('vuoooo', {active, id});
                            console.log('check 1234', [1, 16].includes(active) ? [1, 16].includes(id) :active === id)
                            return (
                                <div
                                    key={item.id}
                                    className={
                                        styles["modal-content__item"] +
                                        ` ${[1, 16].includes(active) ? [1, 16].includes(id) :active === id
                                            ? styles[
                                            "modal-content__item-active"
                                            ]
                                            : ""
                                        }`
                                    }
                                    onClick={() => setActive(id)}
                                >
                                    <div style={{ display: "flex" }}>
                                        <img
                                            src={image}
                                            alt="img"
                                            style={{
                                                width: 80,
                                                height: 80,
                                                borderRadius: "50%",
                                                marginRight: 25,
                                            }}
                                        />
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "flex-start",
                                                justifyContent: "center",
                                            }}
                                        >
                                            {groupFormatted && (
                                                <p
                                                    className={
                                                        styles[
                                                        "modal-content__type"
                                                        ]
                                                    }
                                                >
                                                    {groupFormatted}
                                                </p>
                                            )}
                                            <p
                                                className={
                                                    styles[
                                                    "modal-content__content"
                                                    ]
                                                }
                                            >
                                                {name}
                                            </p>
                                        </div>
                                    </div>
                                    <Checkbox
                                        checked={[1, 16].includes(active) ? [1, 16].includes(id) :active === id}
                                        checkedIcon={
                                            <CheckCircleIcon
                                                style={{
                                                    color: "#ff4106",
                                                    width: "27px",
                                                    height: "27px",
                                                }}
                                            />
                                        }
                                        icon={
                                            <RadioButtonUncheckedIcon
                                                style={{
                                                    width: "27px",
                                                    height: "27px",
                                                }}
                                            />
                                        }
                                    />
                                </div>
                            );
                        })}
                    </div>
                    <div className={styles["modal-footer"]}>
                        <div className={styles["modal-footer__content"]}>
                            <Button
                                type="button"
                                // variant="contained"
                                onClick={onClose}
                                sx={{
                                    // height: "64px",
                                    background: "#FFF",
                                    border: "1px solid #161616",
                                    borderRadius: "12px",
                                    padding: "8px 16px",
                                    color: "#161616",
                                }}
                            >
                                CANCEL
                            </Button>
                            <Button
                                type="button"
                                variant="contained"
                                disabled={isLoading}
                                className={
                                    isLoading ? `${styles.isLoading}` : " "
                                }
                                onClick={handleConfirm}
                                sx={{
                                    background: "#F5490F",
                                    borderRadius: "12px",
                                    color: "#fff",
                                }}
                            >
                                {handleRenderTitleLoading("CONFIRM", isLoading)}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default forwardRef(ModalChangeDevice);
