import { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { gameServerApi } from "services/gameServerV2/";
import WarnignIcon from "Components/Icons/WarningIcon.svg";
import SuccessInvite from "Components/Icons/SuccessInvite.svg";

import {
    formatMachineData,
    IMachine
} from "./formatData";
import Swal from "sweetalert2";


export const useMachineHook = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [emptyPage, setEmptyPage] = useState<boolean>(true);
    const { id: membershipId } = useParams() as any;

    const history = useHistory();
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);
    const searchParam = searchParams.get("search") || "";
    const [openSwitchMachineModal, setOpenSwitchMachineModal] = useState(false);
    const [openAddMachineModal, setOpenAddMachineModal] = useState(false);
    const [queryParameter, setQueryParameter] = useState<{ search: string }>({
        search: searchParam || "",
    });
    const [machines, setMachines] = useState<any>(null);
    const [machine, setMachine] = useState<any>(null);
    const [currentMembershipDetail, setCurrentMembershipDetail] = useState<any>(null);

    const getMembershipMachineList = async (
        orgId: string = membershipId,
        search: string = ""
    ) => {
        setLoading(true);
        // Get membership familly data
        gameServerApi.machine
            .getListMachineMembership({
                orgId,
            })
            .then((res: any) => {
                setLoading(false);
                setMachines(formatMachineData(res?.data?.machines));
            })
            .catch((err) => {
                setLoading(false);
                console.log("errrmlmlmml", err);
            });
    };
    const getMembershipDetail = async (
        orgId: string = membershipId,
        search: string = ""
    ) => {
        setLoading(true);
        // Get membership familly data
        gameServerApi.membership
            .getMembershipDetailForC2({
                orgId,
            })
            .then((res: any) => {
                setLoading(false);
                setCurrentMembershipDetail(res?.data?.currentMembership);
            })
            .catch((err) => {
                setLoading(false);
                console.log("errrmlmlmml", err);
            });
    };

    const handleSearch = (newValue: any) => {
        setQueryParameter({ ...queryParameter, search: newValue });
    };

    const handleSwitchMachine = (item: IMachine) => {
        setMachine(item)
        setOpenSwitchMachineModal(true)
    }

    const handleOpenAddMachine = () => {
        setOpenAddMachineModal(true);
    }

    const handleDeleteMachine = async (item: IMachine) => {
        const result = await Swal.fire({
            title: `<div style="font-size: 24px;">${'Delete machine'.toLocaleUpperCase()}<div>`,
            // icon: "warning",
            imageUrl: WarnignIcon,
            html: `<div style="color: #161616;font-size: 16px; margin-bottom: 30px;">Do you want to delete this machine?</div>`,
            showCancelButton: true,
            confirmButtonText: "Confirm",
            showClass: {
                popup: `
                  confirm-membership-popup
                `,
            },
        });
        if (!result.isConfirmed) {
            return;
        }
        setLoading(true)
        try {
            await gameServerApi.machine.deleteMembershipMachine({
                orgId: membershipId,
                deleteValue: [item.machineId]
            })
            setLoading(false)
            await refetchMembershipMachineList();
            setTimeout(() => {
                Swal.fire({
                    title: "Success",
                    html: "<div>Machine Delete Successful </div>",
                    imageUrl: SuccessInvite,
                    showClass: {
                        popup: `error-invite-member-popup`,
                    },
                });
            }, 600);
        } catch (error) {
            setLoading(false);
            setTimeout(() => {
                Swal.fire("Error", `Delete Machine Error! `, "error");
            }, 600);
        }
    }

    const refetchMembershipMachineList = async () => {
        await getMembershipMachineList();
        await getMembershipDetail();
    }

    useEffect(()=>{
        getMembershipMachineList();
        getMembershipDetail();
    }, [])

    return {
        loading,
        handleSearch,
        refetchMembershipMachineList: refetchMembershipMachineList,
        machines,
        emptyPage,
        membershipId,
        handleSwitchMachine,
        openSwitchMachineModal,
        setOpenSwitchMachineModal,
        machine,
        machineAdded: {
            maxMachine: currentMembershipDetail?.constrainTypes?.max_machine || 0,
            total: currentMembershipDetail?.totalMachines || 0,
        },
        openAddMachineModal,
        currentMembershipDetail,
        setOpenAddMachineModal,
        handleOpenAddMachine,
        handleDeleteMachine,
    };
};
